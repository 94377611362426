/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Container, Paper, List, makeStyles, CircularProgress, Box } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroller';
import PropTypes from 'prop-types';
import { fetchYoutubeAllStart } from 'actions/searchActions';
import ListItemWithSecondary from '../listItems/ListItemWithSecondary';

const useStyles = makeStyles({
  paper: {
    backgroundColor: 'transparent',
  },
});

const PaperListSearch = ({ items, primaryAction, secondaryAction, type }) => {
  const styles = useStyles();
  const [hasMore, setHasMore] = useState(true);

  const page = useSelector((state) => state.search.page, shallowEqual);

  const dispatch = useDispatch();
  const fetchYoutube = (payload) => dispatch(fetchYoutubeAllStart(payload));

  return (
    <Paper elevation={0} square className={styles.paper}>
      <Container maxWidth={false} disableGutters>
        <List disablePadding>
          <InfiniteScroll
            pageStart={1}
            initialLoad={false}
            loadMore={() => {
              if (page >= 5) setHasMore(false);
              fetchYoutube({
                type,
              });
            }}
            loader={
              <Box key={0} display="flex" alignItems="center" justifyContent="center" p="2">
                <CircularProgress color="primary" size={56} />
              </Box>
            }
            hasMore={hasMore}
            threshold={10}
          >
            {items.map((item, index) => {
              return (
                <ListItemWithSecondary
                  key={`${
                    type === 'tracks' ? item.youtube_code : item.youtube_playlist_code
                  }${index}`}
                  item={item}
                  primaryAction={() =>
                    primaryAction(
                      type === 'tracks' ? item.youtube_code : item.youtube_playlist_code,
                    )
                  }
                  secondaryAction={() =>
                    secondaryAction(
                      type === 'tracks'
                        ? { name: item.name, youtube_code: item.youtube_code }
                        : {
                            title: item.title,
                            youtube_playlist_code: item.youtube_playlist_code,
                            count: item.count,
                            existed: item.existed,
                          },
                    )
                  }
                  secondaryIcon="addToList"
                />
              );
            }) ?? []}
          </InfiniteScroll>
        </List>
      </Container>
    </Paper>
  );
};

PaperListSearch.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
  primaryAction: PropTypes.func,
  secondaryAction: PropTypes.func,
  type: PropTypes.string.isRequired,
};

PaperListSearch.defaultProps = {
  secondaryAction: null,
  primaryAction: null,
};

export default PaperListSearch;
