import React from 'react';
import { Container, Paper, List, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import theme from '../../AppTheme';
import ListItemGenre from '../listItems/ListItemGenre';

const useStyles = makeStyles({
  paper: {
    backgroundColor: 'transparent',
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(2),
    borderRadius: 0,
  },
  listItemAvatar: {
    minWidth: theme.spacing(5),
  },
});

const PaperListGenres = ({ searchValue, genres }) => {
  const styles = useStyles();
  return (
    <Paper elevation={0} square className={styles.paper}>
      <Container maxWidth={false} disableGutters>
        <List disablePadding>
          {genres?.filter((g) => {
              if (searchValue) return g.name.toLowerCase().includes(searchValue.toLowerCase());
              return g;
            }).map((genre) => {
            return <ListItemGenre key={genre.id} genre={genre} />;
          })}
        </List>
      </Container>
    </Paper>
  );
};

PaperListGenres.propTypes = {
  genres: PropTypes.instanceOf(Array).isRequired,
  searchValue: PropTypes.string.isRequired,
};

export default PaperListGenres;
