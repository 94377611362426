// @flow
import React, { useRef, useState, useEffect, forwardRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  Container,
  AppBar,
  Box,
  CardMedia,
  Card,
  Typography,
  IconButton,
  Slider,
  Grid,
  SvgIcon,
  makeStyles,
  Collapse,
  CircularProgress,
  Toolbar,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { setPlaylistShuffle, setPlaylistRepeat, setPlaylistPlay } from 'actions/playerActions';
import { addFavoriteTrack } from 'actions/favoriteTracksActions';
import { addTrackToUserPlaylist } from 'actions/favoritePlaylistsActions';
import DialogDrawer from 'components/dialogs/DialogDrawer';
import PaperSearch from 'components/papers/PaperSearch';
import PaperListPlaylists from 'components/papers/PaperListPlaylists';
import PaperListSongs from 'components/papers/PaperListSongs';
import TracksService from 'services/TrackService';
import song from '../assets/images/song.png';
import playlist from '../assets/images/playlist.png';
import DialogDrawerFull from '../components/dialogs/DialogDrawerFull';
import { ReactComponent as down } from '../assets/icons/ic_arrow_down.svg';
import { ReactComponent as pause } from '../assets/icons/pause.svg';
import { ReactComponent as play } from '../assets/icons/play.svg';
import { ReactComponent as prev } from '../assets/icons/previous.svg';
import { ReactComponent as next } from '../assets/icons/next.svg';
import { ReactComponent as close } from '../assets/icons/ic_close.svg';
import { ReactComponent as format } from '../assets/icons/ic_format.svg';
import theme from '../AppTheme';
import AppTabs from '../components/partials/AppTabs';
import PaperPlaylistControl from '../components/papers/PaperPlaylistControl';

import DialogPlaylistCreate from './DialogPlaylistCreate';
import DialogPlaylistNew from './DialogPlaylistNew';
import { ReactComponent as favorite } from '../assets/icons/ic_favorite.svg';
import { ReactComponent as favoriteBorder } from '../assets/icons/ic_favorite_border.svg';
import { addFavoritePlaylist, removeFavoritePlaylist } from '../actions/userActions';

const useStyles = makeStyles({
  card: {
    position: 'relative',
    paddingTop: '56%',
  },
  cardMedia: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
  },
  cardAction: {
    position: 'absolute',
    left: 0,
    top: 0,
  },
  cardActions: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    justifyContent: 'center',
    alignItems: 'center',
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(3),
    },
  },
});

const tabs = [
  {
    label: 'UP NEXT',
  },
  {
    label: 'LYRICS',
  },
];

const NewlineText = ({ text }) => {
  const newText = text.split('\n').map((str) => <p>{str}</p>);
  return newText;
};

const DialogPlayer = ({
  open,
  handleDialogOnClose,
  setOpen,
  children,
  videos,
  songTitle,
  isPlaying,
  onShuffle,
  onSongClick,
  handleDialogOnPrev,
  handleDialogOnNext,
}) => {
  const styles = useStyles();
  const scrollRef = useRef();
  const [activeTab, setActiveTab] = useState(0);
  const [lyrics, setLyrics] = useState(null);
  const [valueSearchPlaylistSongs, setValueSearchPlaylistSongs] = useState('');
  const [fontSize, setFontSize] = useState(16);
  const [showAddToMyTracks, setShowAddToMyTracks] = useState(false);
  const [isLoadingLyrics, setIsLoadingLyrics] = useState(false);

  const [dialogPlaylistAdd, setDialogPlaylistAdd] = useState(false);
  const [dialogPlaylistCreate, setDialogPlaylistCreate] = useState(false);
  const [dialogPlaylistNew, setDialogPlaylistNew] = useState(false);
  const [valueSearchPlaylists, setValueSearchPlaylists] = useState('');
  const [playlistName, setPlaylistName] = useState('');
  const [selectedSong, setSelectedSong] = useState(null);

  const isShuffle = useSelector((state) => state.player.shuffle, shallowEqual);
  const isRepeat = useSelector((state) => state.player.repeat, shallowEqual);
  const userPlaylists = useSelector((state) => state.userPlaylists.list, shallowEqual);
  const userTracks = useSelector((state) => state.tracks.list, shallowEqual);
  const playlistType = useSelector((state) => state.player.playlist.playlistType, shallowEqual);
  


  const trackExist =
    selectedSong && !userTracks.find((t) => t.youtube_code === selectedSong.youtube_code);

  const handleSearchValue = debounce((value) => {
    setValueSearchPlaylistSongs(value);
  }, 1000);

  const handleOpenDialogCreate = (value) => {
    setDialogPlaylistCreate(value);
  };

  const handleOpenDialogNew = (value) => {
    setDialogPlaylistNew(value);
  };

  useEffect(() => {
    async function fetchMyAPI() {
      const title = songTitle.split("(").shift().split("[").shift();
      const data = await TracksService.fetchTrackLyrics({ track: title });
      setLyrics(data.lyrics || null);
      setIsLoadingLyrics(false);
    }
    if (songTitle) { setLyrics(null); setIsLoadingLyrics(true); fetchMyAPI(); }
  }, [songTitle]);

  const dispatch = useDispatch();
  const setRepeat = () => dispatch(setPlaylistRepeat());
  const addFavoriteSong = (payload) => dispatch(addFavoriteTrack(payload));
  const addSongToUserPlaylist = (payload) => dispatch(addTrackToUserPlaylist(payload));
  const addPlaylist = (payload) => dispatch(addFavoritePlaylist(payload));
  const removePlaylist = (payload) => dispatch(removeFavoritePlaylist(payload));
  const favoritePlaylists = useSelector((state) => state.user.playlists, shallowEqual);
  const currentPlaylist = useSelector((state) => state.playlists.current, shallowEqual);
  const isFavorite = !!favoritePlaylists.find((p) => p.id === currentPlaylist.id);
  return (
    <DialogDrawerFull
      setOpen={setOpen}
      open={open}
      appBarTop={
        <>
          <AppBar elevation={0} color="primary" position="static">
            <Grid container justify="space-between">
              <Grid item xs="auto">
                <IconButton onClick={handleDialogOnClose}>
                  <SvgIcon component={down} viewBox="0 0 24 24" />
                </IconButton>
              </Grid>
              {playlistType && (<Grid item xs="auto">
                <IconButton
                  onClick={() =>
                    isFavorite
                      ? removePlaylist(currentPlaylist.id)
                      : addPlaylist({
                        categoryId: currentPlaylist.categoryId,
                        id: currentPlaylist.id,
                      })
                  }
                >
                  {isFavorite ? (
                    <SvgIcon component={favorite} viewBox="0 0 24 24" />
                  ) : (
                    <SvgIcon component={favoriteBorder} viewBox="0 0 24 24" />
                  )}
                </IconButton>
              </Grid>)}
            </Grid>
          </AppBar>
          <Card elevation={0} square className={styles.card}>
            <CardMedia className={styles.cardMedia}>{children}</CardMedia>
          </Card>
        </>
      }
    >
      <Container ref={scrollRef}>
        <Box py={2}>
          <Typography variant="body1" align="center">
            {songTitle}
          </Typography>
        </Box>
        <PaperPlaylistControl
          handleDialogOnPrev={handleDialogOnPrev}
          handleDialogOnNext={handleDialogOnNext}
          searchText={valueSearchPlaylistSongs}
          onSearchChange={handleSearchValue}
          playAction={() => { }}
          pauseAction={() => { }}
          repeatAction={() => {
            setRepeat();
          }}
          shuffleAction={() => {
            onShuffle(isShuffle);
          }}
          isPlay={isPlaying}
          isShuffle={isShuffle}
          isRepeat={isRepeat}
          slim
        />
      </Container>
      <AppTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} withAppBar />
      <Collapse in={activeTab === 0} unmountOnExit addEndListener={() => { }}>
        {/* <PaperListSongsSwiper
        songs={videos}
        onSongClick={onSongClick}
        searchValue={valueSearchPlaylistSongs}
        addPlaylistAction={(e) => {
          console.log('add', e);
        }}
        deletePlaylistAction={(e) => {
          console.log('delete', e);
        }}
      /> */}
        <PaperListSongs
          onSongClick={onSongClick}
          secondaryAction={async (item) => {
            const data = await TracksService.checkIfTrackExists({ track: item.youtube_code });
            setShowAddToMyTracks(!data.isExistInUserTracks);
            setDialogPlaylistAdd(true);
            setSelectedSong(item);
          }}
          songs={videos}
        />
        <DialogDrawer setOpen={setDialogPlaylistAdd} open={dialogPlaylistAdd} title="Playlists">
          <AppBar position="sticky" color="transparent">
            <Box pb={2}>
              <Toolbar>
                <PaperSearch value={valueSearchPlaylists} onValueChange={setValueSearchPlaylists} />
              </Toolbar>
            </Box>
          </AppBar>
          <PaperListPlaylists
            hasInfiniteScroll
            playlists={userPlaylists.filter(({ createdByUser: isCustom }) => isCustom)}
            selectedSong={selectedSong}
            searchValue={valueSearchPlaylists}
            primaryAction={() => { }}
            showAddToMyTracks={trackExist}
            createAction={() => {
              // setDialogPlaylistAdd(false);
              setDialogPlaylistCreate(true);
            }}
            addToMyTracksAction={
              showAddToMyTracks
                ? () => {
                  // setDialogPlaylistAdd(false);
                  addFavoriteSong(selectedSong);
                }
                : null
            }
            secondaryAction={(currPlaylist) => {
              if (currPlaylist.songs.every((s) => s.youtube_code !== selectedSong?.youtube_code)) {
                addSongToUserPlaylist({
                  ...currPlaylist,
                  count: currPlaylist.count + 1,
                  songs: [...currPlaylist.songs, selectedSong],
                });
                // setDialogPlaylistAdd(false);
              }
            }}
          />
        </DialogDrawer>
        {dialogPlaylistCreate && (
          <DialogPlaylistCreate
            open={dialogPlaylistCreate}
            setOpen={handleOpenDialogCreate}
            playlistName={playlistName}
            setPlaylistName={setPlaylistName}
            createPlaylistAction={(name) => {
              setPlaylistName(name);
              setDialogPlaylistCreate(false);
              setDialogPlaylistNew(true);
            }}
          />
        )}
        {dialogPlaylistNew && (
          <DialogPlaylistNew
            open={dialogPlaylistNew}
            setOpen={handleOpenDialogNew}
            playlistName={playlistName}
            selectedSong={selectedSong}
          />
        )}
      </Collapse>
      <Collapse in={activeTab === 1} unmountOnExit addEndListener={() => { }}>
        <Container ref={scrollRef}>
          {isLoadingLyrics ? <Box key={0} display="flex" alignItems="center" justifyContent="center" py={6} p="2">
            <CircularProgress color="primary" size={100} />
          </Box> : (
            <Box py={3}>
              {lyrics && <Grid container spacing={3} alignItems="center">
                <Grid item>
                  <SvgIcon component={format} viewBox="0 0 24 24" />
                </Grid>
                <Grid item xs>
                  <Slider
                    color="primary"
                    value={fontSize}
                    min={12}
                    max={24}
                    onChange={(e, n) => setFontSize(n)}
                    aria-labelledby="continuous-slider"
                  />
                </Grid>
                <Grid item>
                  <Typography component="span">{fontSize}</Typography>
                </Grid>
              </Grid>}
              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ fontSize }}>

                    {lyrics ? <NewlineText text={lyrics} /> : "No lyrics available for this track at the moment"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
        </Container>
      </Collapse>
    </DialogDrawerFull>
  );
};

DialogPlayer.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleDialogOnClose: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  videos: PropTypes.array.isRequired,
  songTitle: PropTypes.string.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  onShuffle: PropTypes.func.isRequired,
  onSongClick: PropTypes.func.isRequired,
  handleDialogOnPrev: PropTypes.func.isRequired,
  handleDialogOnNext: PropTypes.func.isRequired,
};

export default DialogPlayer;
