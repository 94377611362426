/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { debounce } from 'lodash';
import { setPlayerSong, setPlaylistPlay, setPlayerPlaylist } from 'actions/playerActions';
import { addFavoriteTrack } from 'actions/favoriteTracksActions';
import { addTrackToUserPlaylist, addFavoritePlaylistStart } from 'actions/favoritePlaylistsActions';
import PaperListPlaylists from 'components/papers/PaperListPlaylists';
import PaperListSongs from 'components/papers/PaperListSongs';
import TracksService from 'services/TrackService';
import PlaylistService from 'services/PlaylistService';
import { fetchYoutubeAllStart, clearYoutubeAll } from 'actions/searchActions';
import DialogDrawer from 'components/dialogs/DialogDrawer';
import PaperSearch from 'components/papers/PaperSearch';
import DialogPlaylistCreate from 'dialogs/DialogPlaylistCreate';
import DialogPlaylistNew from 'dialogs/DialogPlaylistNew';

const SearchResults = () => {
  const history = useHistory();

  const [dialogPlaylistAdd, setDialogPlaylistAdd] = useState(false);
  const [dialogPlaylistCreate, setDialogPlaylistCreate] = useState(false);
  const [dialogPlaylistNew, setDialogPlaylistNew] = useState(false);
  const [playlistName, setPlaylistName] = useState('');
  const [selectedSong, setSelectedSong] = useState(null);
  const [valueSearchPlaylists, setValueSearchPlaylists] = useState('');
  const [showAddToMyTracks, setShowAddToMyTracks] = useState(false);

  const playlists = useSelector((state) => state.search.playlists, shallowEqual);
  const videos = useSelector((state) => state.search.videos, shallowEqual);
  const isPlay = useSelector((state) => state.player.play, shallowEqual);
  const userPlaylists = useSelector((state) => state.userPlaylists.list, shallowEqual);
  const userTracks = useSelector((state) => state.tracks.list, shallowEqual);

  const dispatch = useDispatch();
  const addSongToPlayer = (payload) => dispatch(setPlayerSong(payload));
  const setYoutubePlayerPlaylist = (payload) => dispatch(setPlayerPlaylist(payload));
  const fetchYoutube = (payload) => dispatch(fetchYoutubeAllStart(payload));
  const openPlayer = () => dispatch(setPlaylistPlay());
  const clearYoutube = () => dispatch(clearYoutubeAll());
  const addFavoriteSong = (payload) => dispatch(addFavoriteTrack(payload));
  const addSongToUserPlaylist = (payload) => dispatch(addTrackToUserPlaylist(payload));
  const addFavoritePlist = (payload) => dispatch(addFavoritePlaylistStart(payload));

  const trackExist =
    selectedSong && !userTracks.find((t) => t.youtube_code === selectedSong.youtube_code);

  const handleOpenDialogCreate = (value) => {
    setDialogPlaylistCreate(value);
  };

  const handleOpenDialogNew = (value) => {
    setDialogPlaylistNew(value);
  };

  const handleSearchValue = debounce((value) => {
    setValueSearchPlaylists(value);
  }, 1000);

  return (
    <>
      <Box>
        <PaperListSongs
          title="Tracks"
          allAction={() => {
            clearYoutube();
            fetchYoutube({
              type: 'tracks',
            });
            history.push('/search/tracks');
          }}
          primaryAction={(code) => {
            addSongToPlayer({ code });
            if (!isPlay) openPlayer();
          }}
          secondaryAction={async (item) => {
            const data = await TracksService.checkIfTrackExists({ track: item.youtube_code });
            setShowAddToMyTracks(!data.isExistInUserTracks);
            setDialogPlaylistAdd(true);
            setSelectedSong(item);
          }}
          songs={videos}
        />
        <DialogDrawer setOpen={setDialogPlaylistAdd} open={dialogPlaylistAdd} title="Playlists">
          <PaperSearch value={valueSearchPlaylists} onValueChange={handleSearchValue} />
          <PaperListPlaylists
            hasInfiniteScroll
            playlists={userPlaylists.filter(({ createdByUser: isCustom }) => isCustom)}
            selectedSong={selectedSong}
            primaryAction={() => {}}
            searchValue={valueSearchPlaylists}
            showAddToMyTracks={trackExist}
            createAction={() => {
              // setDialogPlaylistAdd(false);
              setDialogPlaylistCreate(true);
            }}
            addToMyTracksAction={
              showAddToMyTracks
                ? () => {
                    // setDialogPlaylistAdd(false);
                    addFavoriteSong(selectedSong);
                  }
                : null
            }
            secondaryAction={(currPlaylist) => {
              if (currPlaylist.songs.every((s) => s.youtube_code !== selectedSong?.youtube_code)) {
                addSongToUserPlaylist({
                  ...currPlaylist,
                  count: currPlaylist.count + 1,
                  songs: [...currPlaylist.songs, selectedSong],
                });
                // setDialogPlaylistAdd(false);
              }
            }}
          />
        </DialogDrawer>
        {dialogPlaylistCreate && (
          <DialogPlaylistCreate
            open={dialogPlaylistCreate}
            setOpen={handleOpenDialogCreate}
            playlistName={playlistName}
            setPlaylistName={setPlaylistName}
            createPlaylistAction={(name) => {
              setPlaylistName(name);
              setDialogPlaylistCreate(false);
              setDialogPlaylistNew(true);
            }}
          />
        )}
        {dialogPlaylistNew && (
          <DialogPlaylistNew
            open={dialogPlaylistNew}
            setOpen={handleOpenDialogNew}
            playlistName={playlistName}
            selectedSong={selectedSong}
          />
        )}
      </Box>
      {!!playlists.length && (
        <Box py={3}>
          <PaperListPlaylists
            hasInfiniteScroll={false}
            title="Playlists"
            allAction={() => {
              clearYoutube();
              fetchYoutube({
                type: 'playlists',
              });
              history.push('/search/playlists');
            }}
            primaryAction={async (listId) => {
              const { songs } = await PlaylistService.fetchPlaylistById({
                listId,
              });
              setYoutubePlayerPlaylist({
                code: listId,
                videos: songs.map(({ name, youtube_code, index }) => ({
                  name,
                  youtube_code,
                  index,
                })),
              });
              if (!isPlay) openPlayer();
              else {
                openPlayer();
                openPlayer();
              }
            }}
            secondaryAction={({ title, count, youtube_playlist_code, existed }) => {
              addFavoritePlist({
                existed,
                title,
                youtube_playlist_code,
                count,
              });
            }}
            playlists={playlists}
          />
        </Box>
      )}
    </>
  );
};

export default SearchResults;
