import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  makeStyles,
  Paper,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  SvgIcon,
  Fab,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { addFavoriteRadio, removeFavoriteRadio } from 'actions/userActions';
import theme from '../../AppTheme';
import { ReactComponent as down } from '../../assets/icons/ic_arrow_down.svg';
import { ReactComponent as favorite } from '../../assets/icons/ic_favorite.svg';
import { ReactComponent as favoriteBorder } from '../../assets/icons/ic_favorite_border.svg';
import { ReactComponent as play } from '../../assets/icons/play.svg';
import { ReactComponent as pause } from '../../assets/icons/ic_track_pause.svg';
import radioImage from '../../assets/images/playlist.png';

const useStyles = makeStyles({
  paper: {
    // paddingTop: 'calc(56% + 56px)',
    marginTop: theme.spacing(7),
    paddingTop: '56%',
    position: 'relative',
    overflow: 'hidden',
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  boxImage: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.standard,
    }),
  },
  box: {
    backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%)',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const RadioHeading = ({ radio, backAction, favoriteAction }) => {
  const styles = useStyles();
  const paperRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(1);

  const favoriteRadios = useSelector((state) => state.user.radios, shallowEqual);
  const dispatch = useDispatch();
  const addRadio = (payload) => dispatch(addFavoriteRadio(payload));
  const removeRadio = (payload) => dispatch(removeFavoriteRadio(payload));

  const isFavorite = !!favoriteRadios.find((r) => r === radio.id);

  const handleScroll = () => {
    const position = window.pageYOffset;
    const paperWidth = paperRef.current.offsetWidth;
    setScrollPosition(position / paperWidth + 1);
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, theme.transitions.duration.enteringScreen);
  }, [loading]);
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <AppBar color="primary" elevation={0} position="absolute">
        <Toolbar className={styles.toolbar}>
          <IconButton onClick={() => backAction()}>
            <SvgIcon component={down} viewBox="0 0 24 24" />
          </IconButton>
          <IconButton onClick={() => (isFavorite ? removeRadio(radio.id) : addRadio(radio))}>
            {isFavorite ? (
              <SvgIcon component={favorite} viewBox="0 0 24 24" />
            ) : (
              <SvgIcon component={favoriteBorder} viewBox="0 0 24 24" />
            )}
          </IconButton>
        </Toolbar>
      </AppBar>
    <Paper ref={paperRef} className={styles.paper} square>
      <Box
        className={styles.boxImage}
        style={{
          backgroundImage: `url(${radio.image640 || radioImage})`,
          transform: `scale(${scrollPosition})`,
        }}
      />
      {/* <Box className={styles.box}>
        <Typography style={{ fontWeight: 'bold' }} align="center" variant="h4">
          {radio.title}
        </Typography>
        {radio.subTitle && (
          <Typography variant="h5" color="textSecondary">
            {radio.subTitle}
          </Typography>
        )}
        <Box pt={4}>
          <Typography variant="h6" color="secondary">
            ON AIR
          </Typography>
        </Box>
      </Box> */}
    </Paper>
    </>
  );
};

RadioHeading.propTypes = {
  radio: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    image640: PropTypes.string,
    isFavorite: PropTypes.bool,
  }).isRequired,
  favoriteAction: PropTypes.func.isRequired,
  backAction: PropTypes.func.isRequired,
};

export default RadioHeading;
