import { PlayerActionTypes } from 'actions/playerActions';
import { RadioActionTypes } from 'actions/radioActions';

const INITIAL_STATE = {
  playlist: {},
  radio: {},
  song: {},
  shuffle: false,
  repeat: false,
  play: false,
  playShuffleList: false,
  currentVideoId: null,
  shuffleList: [],
};

const playlistActionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PlayerActionTypes.SET_PLAYER_PLAYLIST:
      return {
        ...state,
        playlist: { ...action.payload },
        radio: {},
        song: {},
      };
    case PlayerActionTypes.CLEAR_PLAYER_PLAYLIST:
      return {
        ...state,
        playlist: {},
        shuffleList: [],
        currentVideoId: null,
      };
    case PlayerActionTypes.SET_PLAYER_RADIO:
    case RadioActionTypes.FETCH_RADIO_WITH_RELATED_SUCCESS:
      return {
        ...state,
        radio: { ...action.payload },
        playlist: {},
        song: {},
        currentVideoId: null,
        play: true,
      };
    case PlayerActionTypes.CLEAR_PLAYER_RADIO:
      return {
        ...state,
        radio: {},
      };
    case PlayerActionTypes.SET_PLAYER_SONG:
      return {
        ...state,
        song: { ...action.payload },
        playlist: {},
        radio: {},
        currentVideoId: null,
      };
    case PlayerActionTypes.CLEAR_PLAYER_SONG:
      return {
        ...state,
        song: {},
        currentVideoId: null,
      };
    case PlayerActionTypes.SET_PLAYLIST_IS_REPEAT:
      return {
        ...state,
        repeat: !state.repeat,
      };
    case PlayerActionTypes.SET_PLAYLIST_IS_SHUFFLE:
      return {
        ...state,
        shuffle: !state.shuffle,
      };
    case PlayerActionTypes.SET_PLAYLIST_IS_PLAY:
      return {
        ...state,
        play: !state.play,
      };
    case PlayerActionTypes.UPDATE_SONG_INDEX:
      return {
        ...state,
        playlist: { ...state.playlist, songIndex: { ...state.playlist.songIndex, played: true } },
      };
    case PlayerActionTypes.SET_CURRENT_VIDEO:
      return {
        ...state,
        currentVideoId: action.payload,
      };
    case PlayerActionTypes.CREATE_SHUFFLE_LIST:
      return {
        ...state,
        shuffleList: [...action.payload],
      };
    case PlayerActionTypes.SET_PLAY_SHUFFLE_LIST:
      return {
        ...state,
        playShuffleList: action.payload,
      };
    case PlayerActionTypes.CLEAR_SHUFFLE_LIST:
      return {
        ...state,
        shuffleList: [],
      };
    default:
      return state;
  }
};

export default playlistActionsReducer;
