/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Button,
  Box,
  Container,
  Typography,
  Paper,
  List,
  Grid,
  SvgIcon,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import PropTypes from 'prop-types';
import { fetchFavoriteTracksStart } from 'actions/favoriteTracksActions';
import { ReactComponent as chevronRight } from '../../assets/icons/ic_chevron_right.svg';
import ListItemSong from '../listItems/ListItemSong';
import ListItemWithSecondary from '../listItems/ListItemWithSecondary';

const useStyles = makeStyles({
  paper: {
    backgroundColor: 'transparent',
  },
});

const PaperListSongs = ({
  searchValue,
  title,
  allAction,
  primaryAction,
  onSongClick,
  secondaryAction,
  hasInfiniteScroll,
  songs,
}) => {
  const styles = useStyles();
  const hasMore = useSelector((state) => state.tracks.hasMore, shallowEqual);

  const dispatch = useDispatch();
  const fetchTracks = () => dispatch(fetchFavoriteTracksStart());

  return (
    <Paper elevation={0} square className={styles.paper}>
      {title && allAction && (
        <Container maxWidth={false}>
          <Box pb={2}>
            <Grid container spacing={2} alignItems="center" justify="space-between">
              <Grid item>
                <Typography variant="h5" color="primary">
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  endIcon={<SvgIcon component={chevronRight} viewBox="0 0 24 24" />}
                  onClick={() => allAction()}
                >
                  View all
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      )}
      <Container maxWidth={false} disableGutters>
        <List
          disablePadding
          component="div"
          style={hasInfiniteScroll ? { height: '500px', overflow: 'auto' } : undefined}
        >
          {hasInfiniteScroll ? (
            <InfiniteScroll
              pageStart={0}
              initialLoad={false}
              loadMore={() => {
                fetchTracks();
              }}
              hasMore={hasMore}
              threshold={10}
              loader={
                <Box key={0} display="flex" alignItems="center" justifyContent="center" p="2">
                  <CircularProgress color="primary" size={56} />
                </Box>
              }
              useWindow={false}
            >
              {songs
                .filter((v) => {
                  if (searchValue) return v.name.toLowerCase().includes(searchValue.toLowerCase());
                  return v;
                })
                .map((song, index) => {
                  return (
                    <ListItemWithSecondary
                      key={`${song.youtube_code}${index}`}
                      item={song}
                      primaryAction={
                        onSongClick
                          ? () => {
                              onSongClick(index);
                            }
                          : () => primaryAction && primaryAction(song.youtube_code)
                      }
                      secondaryAction={() =>
                        secondaryAction &&
                        secondaryAction({
                          name: song.name,
                          youtube_code: song.youtube_code,
                        })
                      }
                      secondaryIcon="addToList"
                    />
                  );
                })}
            </InfiniteScroll>
          ) : (
            songs
              .filter((v) => {
                if (searchValue) return v.name.toLowerCase().includes(searchValue.toLowerCase());
                return v;
              })
              .map((song, index) => {
                return (
                  <ListItemWithSecondary
                    key={`${song.youtube_code}${index}`}
                    item={song}
                    primaryAction={
                      onSongClick
                        ? () => {
                            onSongClick(index);
                          }
                        : () => primaryAction && primaryAction(song.youtube_code)
                    }
                    secondaryAction={() =>
                      secondaryAction &&
                      secondaryAction({
                        name: song.name,
                        youtube_code: song.youtube_code,
                      })
                    }
                    secondaryIcon="addToList"
                  />
                );
              })
          )}
        </List>
      </Container>
    </Paper>
  );
};

PaperListSongs.propTypes = {
  title: PropTypes.string,
  songs: PropTypes.instanceOf(Array).isRequired,
  allAction: PropTypes.func,
  primaryAction: PropTypes.func,
  secondaryAction: PropTypes.func.isRequired,
  onSongClick: PropTypes.func,
  searchValue: PropTypes.string,
  hasInfiniteScroll: PropTypes.bool,
};

PaperListSongs.defaultProps = {
  title: null,
  allAction: null,
  primaryAction: null,
  onSongClick: null,
  searchValue: '',
  hasInfiniteScroll: false,
};

export default PaperListSongs;
