/* eslint-disable no-return-assign */
// @flow
import React, { useRef, useEffect, useLayoutEffect, useState } from 'react';
import { AppBar, Box, Button, Grid, Toolbar, Typography, makeStyles, RootRef } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import AvatarEditor from 'react-avatar-editor'
import PropTypes from "prop-types";
import { addUserAvatar } from "actions/userActions"
import DialogDrawerFull from '../components/dialogs/DialogDrawerFull';
import theme from '../AppTheme';
// import photo from "../assets/images/photo.png";

const useStyles = makeStyles({
  appBarTop: {
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(3),
  },
  appBarBottom: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  box: {
    width: "100%",
    paddingTop: "56%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
  },
  round: {
    boxShadow: "0 0 0 1000px rgba(0,0,0,0.6)",
    borderRadius: "100%",
    width: theme.spacing(25),
    height: theme.spacing(25),
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
});

const DialogCamera = ({ open, profileAvatar, setOpen }) => {
  const styles = useStyles();
  const setEditorRef = useRef();

  const dispatch = useDispatch();
  const addAvatar = (payload) => dispatch(addUserAvatar(payload));

  const onClickSave = () => {
    if (setEditorRef.current) {
      // If you want the image resized to the canvas size (also a HTMLCanvasElement)
      const base64 = setEditorRef.current.getImageScaledToCanvas().toDataURL();
      addAvatar(base64);
      setOpen(false);

      // fetch(base64)
      //   .then(res => res.blob())
      //   .then(blob => {
      //     const imageURL = window.URL.createObjectURL(blob); 
      //     addAvatar(imageURL);
      //     setOpen(false);
      //   });

    }
  }
  return (
    <DialogDrawerFull
      setOpen={setOpen}
      open={open}
      appBarTop={
        <AppBar elevation={0} color="transparent" position="static" className={styles.appBarTop}>
          <Toolbar>
            <Typography component="strong" variant="h4">Account photo</Typography>
          </Toolbar>
        </AppBar>
      }
      appBarBottom={
        <AppBar elevation={0} color="transparent" position="static" className={styles.appBarBottom}>
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <Button color="primary" size="large" variant="outlined" fullWidth onClick={() => setOpen(false)}>CANCEL</Button>
              </Grid>
              <Grid item xs={6}>
                <Button color="primary" size="large" variant="contained" fullWidth onClick={onClickSave}>SAVE PHOTO</Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      }
    >
      {/* <Box height="100%" display="flex" alignItems="center">
        <Box className={styles.box} style={{backgroundImage: `url(${photo})`}}>
          <Box className={styles.round} />
        </Box>
      </Box> */}
      <Box height="100%" width="100%" display="flex" >
        <AvatarEditor
          ref={setEditorRef}
          image={profileAvatar}
          height={260}
          width={260}
          border={50}
          borderRadius={450}
          color={[12, 12, 12, 1]} // RGBA
          scale={1.2}
          rotate={0}
          style={{
            width: "100%",
            height: "auto"
          }}
        />
      </Box>
    </DialogDrawerFull >
  );
};

DialogCamera.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  profileAvatar: PropTypes.object,
};

DialogCamera.defaultProps = {
  profileAvatar: null,
};


export default DialogCamera;
