import { takeLatest, call, put, all } from 'redux-saga/effects';

import { firestore, convertCountriesGernesSnapshotToMap } from 'firebase/firebase.utils';

import CountryService from 'services/CountryService';

import {
  fetchRadioCountriesSuccess,
  fetchRadioCountriesFailure,
  CountriesActionTypes,
} from 'actions/countriesActions';

export function* fetchRadioCountriesAsync() {
  try {
    const collectionRef = firestore.collection('radio_countries');
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(convertCountriesGernesSnapshotToMap, snapshot);
    const countryNames = yield call(CountryService.fetchCountries, { country: 'en' });
    yield put(
      fetchRadioCountriesSuccess(
        collectionsMap
          .map(({ code, ...rest }) => ({ code, title: countryNames[code], ...rest }))
          .sort((a, b) => {
            // eslint-disable-next-line no-nested-ternary
            return a.title > b.title ? 1 : b.title > a.title ? -1 : 0;
          }),
      ),
    );
  } catch (error) {
    yield put(fetchRadioCountriesFailure(error.message));
  }
}

export function* fetchRadioCountriesStart() {
  yield takeLatest(CountriesActionTypes.FETCH_RADIO_COUNTRIES_START, fetchRadioCountriesAsync);
}

export function* countriesSagas() {
  yield all([call(fetchRadioCountriesStart)]);
}
