import React, { useState } from 'react';
import {
  Paper,
  Fab,
  Box,
  Collapse,
  IconButton,
  SvgIcon,
  makeStyles,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as search } from '../../assets/icons/ic_search.svg';
import { ReactComponent as prev } from '../../assets/icons/ic_track_prev.svg';
import { ReactComponent as next } from '../../assets/icons/ic_track_next.svg';
import { ReactComponent as repeat } from '../../assets/icons/ic_loop.svg';
import { ReactComponent as shuffle } from '../../assets/icons/ic_shuffle.svg';
import { ReactComponent as play } from '../../assets/icons/play.svg';
import { ReactComponent as pause } from '../../assets/icons/ic_track_pause.svg';
import theme from '../../AppTheme';
import PaperSearch from './PaperSearch';

const useStyles = makeStyles({
  paper: {
    position: 'relative',
    backgroundColor: 'transparent',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  paperSlim: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(2),
  },
  fab: {
    position: 'absolute',
    left: '50%',
    top: 0,
    transform: 'translate(-50%,-50%)',
  },

  box: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const PaperPlaylistControl = ({
  searchText,
  showSearch,
  onSearchChange,
  playAction,
  pauseAction,
  shuffleAction,
  repeatAction,
  handleDialogOnPrev,
  handleDialogOnNext,
  gender,
  isPlay,
  isRepeat,
  isShuffle,
  slim,
  title,
  videoCount,
}) => {
  const styles = useStyles();
  const [isSearch, setIsSearch] = useState(false);
  return (
    <Paper
      elevation={0}
      square
      className={classNames({ [styles.paper]: true, [styles.paperSlim]: slim })}
    >
      {!slim && (
        <Fab
          className={styles.fab}
          color="primary"
          onClick={() => {
            if (isPlay) {
              pauseAction();
            } else {
              playAction();
            }
          }}
        >
          <SvgIcon component={isPlay ? pause : play} viewBox="0 0 24 24" />
        </Fab>
      )}
      <Box position="relative">
        <Box className={styles.box}>
          <Typography variant="h4">{title}</Typography>
          {videoCount && (
            <Typography variant="body2" color="textSecondary">
              {`${videoCount} Tracks`}
            </Typography>
          )}
          {!!gender?.length && (
            <Typography variant="body2" align="center" color="textSecondary">
              {gender.join(', ')}
            </Typography>
          )}
        </Box>
        <Box position="relative" display="flex" alignItems="center" justifyContent="center">
          {/* todo mastora */}
          {handleDialogOnPrev && (
            <IconButton color="default" onClick={handleDialogOnPrev}>
              <SvgIcon component={prev} viewBox="0 0 24 24" />
            </IconButton>
          )}
          {showSearch && (
            <IconButton onClick={() => setIsSearch(true)}>
              <SvgIcon component={search} viewBox="0 0 24 24" />
            </IconButton>
          )}
          {repeatAction && (
            <IconButton color={isRepeat ? 'primary' : 'default'} onClick={() => repeatAction()}>
              <SvgIcon component={repeat} viewBox="0 0 24 24" />
            </IconButton>
          )}
          {shuffleAction && (
            <IconButton color={isShuffle ? 'primary' : 'default'} onClick={() => shuffleAction()}>
              <SvgIcon component={shuffle} viewBox="0 0 24 24" />
            </IconButton>
          )}
          {/* todo mastora */}
          {handleDialogOnNext && (
            <IconButton color="default" onClick={handleDialogOnNext}>
              <SvgIcon component={next} viewBox="0 0 24 24" />
            </IconButton>
          )}
        </Box>
        <Collapse in={isSearch} addEndListener={() => {}}>
          <Box zIndex={1} px={2} pt={2}>
            <PaperSearch
              onValueChange={onSearchChange}
              value={searchText}
              actionClose={() => setIsSearch(false)}
            />
          </Box>
        </Collapse>
      </Box>
    </Paper>
  );
};

PaperPlaylistControl.propTypes = {
  searchText: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  playAction: PropTypes.func.isRequired,
  pauseAction: PropTypes.func.isRequired,
  shuffleAction: PropTypes.func,
  repeatAction: PropTypes.func,
  isPlay: PropTypes.bool.isRequired,
  isRepeat: PropTypes.bool.isRequired,
  isShuffle: PropTypes.bool.isRequired,
  slim: PropTypes.bool,
  showSearch: PropTypes.bool,
  handleDialogOnPrev: PropTypes.func,
  handleDialogOnNext: PropTypes.func,
  title: PropTypes.string.isRequired,
  gender: PropTypes.arrayOf(String),
  videoCount: PropTypes.number.isRequired,
};

PaperPlaylistControl.defaultProps = {
  slim: false,
  showSearch: false,
  handleDialogOnPrev: null,
  handleDialogOnNext: null,
  shuffleAction: null,
  repeatAction: null,
  gender: null,
};

export default PaperPlaylistControl;
