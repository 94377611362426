export const UserActionTypes = {
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  GOOGLE_SIGN_IN_START: 'GOOGLE_SIGN_IN_START',
  EMAIL_SIGN_IN_START: 'EMAIL_SIGN_IN_START',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
  CHECK_USER_SESSION: 'CHECK_USER_SESSION',
  CHECK_USER_SESSION_END: 'CHECK_USER_SESSION_END',
  SIGN_OUT_START: 'SIGN_OUT_START',
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
  SIGN_OUT_FAILURE: 'SIGN_OUT_FAILURE',
  SIGN_UP_START: 'SIGN_UP_START',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  SIGN_UP_FAILURE: 'SIGN_UP_FAILURE',
  ADD_FAVORITE_RADIO: 'ADD_FAVORITE_RADIO',
  REMOVE_FAVORITE_RADIO: 'REMOVE_FAVORITE_RADIO',
  ADD_FAVORITE_PLAYLIST_FROM_CATEGORY: 'ADD_FAVORITE_PLAYLIST_FROM_CATEGORY',
  REMOVE_FAVORITE_PLAYLIST_FROM_CATEGORY: 'REMOVE_FAVORITE_PLAYLIST_FROM_CATEGORY',
  UPDATE_USER_IN_FIREBASE: 'UPDATE_USER_IN_FIREBASE',
  ADD_FAVORITE_RADIO_SUCCESS: 'ADD_FAVORITE_RADIO_SUCCESS',
  ADD_FAVORITE_RADIO_SUCCESS_LIST: 'ADD_FAVORITE_RADIO_SUCCESS_LIST',
  ADD_USER_AVATAR: 'ADD_USER_AVATAR'
};

export const checkSessionEnd = () => ({
  type: UserActionTypes.CHECK_USER_SESSION_END,
});

export const googleSignInStart = () => ({
  type: UserActionTypes.GOOGLE_SIGN_IN_START,
});

export const signInSuccess = (user) => ({
  type: UserActionTypes.SIGN_IN_SUCCESS,
  payload: user,
});

export const signInFailure = (error) => ({
  type: UserActionTypes.SIGN_IN_FAILURE,
  payload: error,
});

export const emailSignInStart = (emailAndPassword) => ({
  type: UserActionTypes.EMAIL_SIGN_IN_START,
  payload: emailAndPassword,
});

export const checkUserSession = () => ({
  type: UserActionTypes.CHECK_USER_SESSION,
});

export const signOutStart = () => ({
  type: UserActionTypes.SIGN_OUT_START,
});

export const signOutSuccess = () => ({
  type: UserActionTypes.SIGN_OUT_SUCCESS,
});

export const signOutFailure = (error) => ({
  type: UserActionTypes.SIGN_OUT_FAILURE,
  payload: error,
});

export const signUpStart = (userCredentials) => ({
  type: UserActionTypes.SIGN_UP_START,
  payload: userCredentials,
});

export const signUpSuccess = ({ user, additionalData }) => ({
  type: UserActionTypes.SIGN_UP_SUCCESS,
  payload: { user, additionalData },
});

export const signUpFailure = (error) => ({
  type: UserActionTypes.SIGN_UP_FAILURE,
  payload: error,
});

export const addFavoriteRadio = (paylod) => ({
  type: UserActionTypes.ADD_FAVORITE_RADIO,
  payload: paylod,
});

export const addFavoriteRadioSuccess = (paylod) => ({
  type: UserActionTypes.ADD_FAVORITE_RADIO_SUCCESS,
  payload: paylod,
});

export const addFavoriteRadioSuccessList = (paylod) => ({
  type: UserActionTypes.ADD_FAVORITE_RADIO_SUCCESS_LIST,
  payload: paylod,
});

export const removeFavoriteRadio = (paylod) => ({
  type: UserActionTypes.REMOVE_FAVORITE_RADIO,
  payload: paylod,
});

export const updateUserInFirebase = () => ({
  type: UserActionTypes.UPDATE_USER_IN_FIREBASE,
});

export const addFavoritePlaylist = (paylod) => ({
  type: UserActionTypes.ADD_FAVORITE_PLAYLIST_FROM_CATEGORY,
  payload: paylod,
});

export const removeFavoritePlaylist = (paylod) => ({
  type: UserActionTypes.REMOVE_FAVORITE_PLAYLIST_FROM_CATEGORY,
  payload: paylod,
});

export const addUserAvatar = (paylod) => ({
  type: UserActionTypes.ADD_USER_AVATAR,
  payload: paylod,
});
