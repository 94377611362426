import React from 'react';
import {
  Avatar,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  IconButton,
  SvgIcon,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import theme from '../../AppTheme';
import { ReactComponent as playlistAdd } from '../../assets/icons/ic_playlist_add.svg';

const useStyles = makeStyles({
  paper: {
    backgroundColor: 'transparent',
  },
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    borderRadius: 6,
  },
});

const ListItemSong = ({ song, clickAction, addAction, selected }) => {
  const styles = useStyles();
  return (
    <ListItem button divider key={song.id} onClick={() => clickAction(song)} selected={selected}>
      <ListItemAvatar>
        <Avatar src={song.image} className={styles.avatar} />
      </ListItemAvatar>
      <ListItemText
        primary={song.title}
        secondary={song.band}
        secondaryTypographyProps={{ variant: 'caption' }}
      />
      <ListItemSecondaryAction>
        <IconButton
          color="primary"
          edge="end"
          aria-label="delete"
          onClick={() => addAction(song)}
        >
          <SvgIcon component={playlistAdd} viewBox="0 0 24 24" />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

ListItemSong.propTypes = {
  song: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    band: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
  clickAction: PropTypes.func.isRequired,
  addAction: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

ListItemSong.defaultProps = {
  selected: false,
}

export default ListItemSong;
