import { all, call, takeLatest, select, put } from 'redux-saga/effects';

import {
  FavoriteTracksActionTypes,
  fetchFavoriteTracksSuccess,
  fetchFavoriteTracksFailure,
  addFavoriteTrackSuccess,
  addFavoriteTrackFailure,
  setLastVisible,
} from 'actions/favoriteTracksActions';
import { firestore, convertTracksSnapshotToMap } from 'firebase/firebase.utils';

export function* updateFavoriteTracksInFirebase({ payload }) {
  const currentUser = yield select((state) => state.user.currentUser);
  if (currentUser) {
    try {
      const favoriteTrackRef = firestore.collection('user_tracks');
      const docRef = yield favoriteTrackRef.add({
        ...payload,
        user: currentUser.id,
        created_at: new Date(),
      });
      yield put(
        addFavoriteTrackSuccess({
          ...payload,
          id: docRef.id,
        }),
      );
    } catch (error) {
      yield put(addFavoriteTrackFailure(error.message));
    }
  }
}

export function* onFavoriteTracksChange() {
  yield takeLatest(FavoriteTracksActionTypes.ADD_FAVORITE_TRACK, updateFavoriteTracksInFirebase);
}

export function* fetchTracksAsync() {
  try {
    const currentUser = yield select((state) => state.user.currentUser);
    const lastVisible = yield select((state) => state.tracks.lastVisible);
    const order = yield select((state) => state.tracks.order);
    const filter = yield select((state) => state.tracks.filter);
    let lastVisibleRef = null;
    if (lastVisible)
      lastVisibleRef = yield firestore.collection('user_tracks').doc(lastVisible.id).get();
    let collectionRef = firestore
      .collection('user_tracks')
      .where('user', '==', currentUser.id)
      .orderBy(filter, order);
    if (lastVisible || order === 'asc') {
      collectionRef = collectionRef.startAfter(lastVisibleRef || 0);
    }
    collectionRef = collectionRef.limit(10);
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(convertTracksSnapshotToMap, snapshot);

    yield put(fetchFavoriteTracksSuccess(collectionsMap));
    if (collectionsMap.length) yield put(setLastVisible(collectionsMap[collectionsMap.length - 1]));
  } catch (error) {
    yield put(fetchFavoriteTracksFailure(error.message));
  }
}

export function* fetchTracksStart() {
  yield takeLatest(
    [
      FavoriteTracksActionTypes.FETCH_FAVORITE_TRACKS_START,
      FavoriteTracksActionTypes.SET_FILTER_TRACKS,
      FavoriteTracksActionTypes.SET_ORDER_TRACKS,
    ],
    fetchTracksAsync,
  );
}

export function* onDeleteTrackInFirebase({ payload }) {
  try {
    const favoritePlaylistRef = firestore.collection('user_tracks').doc(payload);
    yield favoritePlaylistRef.delete();
  } catch (error) {
    console.log(error);
  }
}

export function* onDeleteTrack() {
  yield takeLatest(FavoriteTracksActionTypes.REMOVE_FAVORITE_TRACK, onDeleteTrackInFirebase);
}

export function* favoriteTracksSagas() {
  yield all([call(onFavoriteTracksChange), call(fetchTracksStart), call(onDeleteTrack)]);
}
