import { CountriesActionTypes } from 'actions/countriesActions';

const INITIAL_STATE = {
  list: [],
  selected: null,
  isFetching: false,
  errorMessage: undefined,
};

const radioReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CountriesActionTypes.FETCH_RADIO_COUNTRIES_START:
      return {
        ...state,
        isFetching: true,
      };
    case CountriesActionTypes.FETCH_RADIO_COUNTRIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.payload,
      };
    case CountriesActionTypes.FETCH_RADIO_COUNTRIES_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case CountriesActionTypes.SET_RADIO_FILTER_COUNTRY:
      return {
        ...state,
        selected: action.payload !== state.selected ? action.payload : null,
      };
    case CountriesActionTypes.CLEAR_COUNTRIES:
      return {
        ...state,
        list: [],
        selected: null,
        isFetching: false,
        errorMessage: undefined,
      };
    default:
      return state;
  }
};

export default radioReducer;
