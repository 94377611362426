import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import expireReducer from 'redux-persist-expire';

import userReducer from './userReducer';
import radioReducer from './radioReducer';
import categoriesReducer from './categoriesReducer';
import playlistsReducer from './playlistsReducer';
import countriesReducer from './countriesReducer';
import genresReducer from './genresReducer';
import categoryAllReducer from './categoryAllReducer';
import playerReducer from './playerReducer';
import favoriteTracksReducer from './favoriteTracksReducer';
import favoritePlaylistsReducer from './favoritePlaylistsReducer';
import audioRecognitionReducer from './audioRecognitionReducer';
import searchReducer from './searchReducer';
import headerReducer from './headerReducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'categories',
    'playlists',
    'genres',
    'countries',
    'search',
    // 'player',
    'radios',
    'tracks',
    'userPlaylists',
    // 'audioRecognition',
  ],
  transforms: [
    expireReducer('categories', {
      expireSeconds: 3600,
      expiredState: {
        list: null,
        isFetching: false,
        errorMessage: undefined,
      },
      autoExpire: true,
    }),
    expireReducer('tracks', {
      expireSeconds: 3600,
      expiredState: {
        list: [],
        filter: 'name',
        order: 'asc',
        lastVisible: null,
        limit: 10,
        hasMore: false,
        isFetching: false,
        errorMessage: undefined,
      },
      autoExpire: true,
    }),
    expireReducer('radios', {
      expireSeconds: 3600,
      expiredState: {
        list: null,
        listByTitle: [],
        favoriteRadios: [],
        limit: 100,
        hasMore: false,
        isFetching: false,
        isFetchingBytitle: false,
        errorMessage: undefined,
        lastVisible: null,
        activeTab: 0,
      },
      autoExpire: true,
    }),
    expireReducer('userPlaylists', {
      expireSeconds: 3600,
      expiredState: {
        list: [],
        playguinList: [],
        itemToBeAdded: null,
        filter: 'title',
        order: 'asc',
        isFetching: false,
        errorMessage: undefined,
        lastVisible: null,
        limit: 10,
        hasMore: false,
        activeTab: 0,
      },
      autoExpire: true,
    }),
    expireReducer('genres', {
      expireSeconds: 3600,
      expiredState: {
        list: [],
        filters: [],
        isFetching: false,
        errorMessage: undefined,
      },
      autoExpire: true,
    }),
    expireReducer('countries', {
      expireSeconds: 3600,
      expiredState: {
        list: [],
        selected: null,
        isFetching: false,
        errorMessage: undefined,
      },
      autoExpire: true,
    }),
  ],
};

const rootReducer = combineReducers({
  user: userReducer,
  categories: categoriesReducer,
  categoryAll: categoryAllReducer,
  playlists: playlistsReducer,
  search: searchReducer,
  player: playerReducer,
  radios: radioReducer,
  genres: genresReducer,
  countries: countriesReducer,
  tracks: favoriteTracksReducer,
  userPlaylists: favoritePlaylistsReducer,
  audioRecognition: audioRecognitionReducer,
  header: headerReducer,
});

export default persistReducer(persistConfig, rootReducer);
