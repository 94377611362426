// @flow
import React from 'react';
import { AppBar, Toolbar, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { animateScroll } from 'react-scroll';
import theme from 'AppTheme';

const useStyles = makeStyles({
  appBar: {
    backgroundColor: '#142F42',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: '#374F5F',
    height: theme.spacing(6),
    top: 0,
  },
  toolBar: {
    minHeight: theme.spacing(6),
  },
  button: {
    flexGrow: 1,
    height: theme.spacing(7),
    '&.active': {
      backgroundColor: 'rgba(67,204,255,0.2)',
      '& .twoTone': {
        fill: '#FFCE00',
      },
    },
  },
});

const TabBar = ({ scrollRef, tabs, activeTab, setActiveTab}) => {
  return (
    <Tabs
      indicatorColor="primary"
      textColor="primary"
      variant="fullWidth"
      scrollButtons="off"
      value={activeTab}
      onChange={(event, newValue) => {
        setActiveTab(newValue);
        if (scrollRef) {
          animateScroll.scrollTo(scrollRef.current.offsetTop + theme.spacing(3), 'smooth');
        }
      }}
    >
      {tabs.map((tab, index) => {
        return <Tab key={tab.label} label={tab.label} />;
      })}
    </Tabs>
  );
};

const AppTabs = ({ tabs, activeTab, setActiveTab, scrollRef, withAppBar }) => {
  const styles = useStyles();
  if (withAppBar) {
    return (
    <AppBar position="sticky" elevation={0} className={styles.appBar}>
      <Toolbar disableGutters className={styles.toolBar}>
        <TabBar tabs={tabs} setActiveTab={setActiveTab} activeTab={activeTab} scrollRef={scrollRef} />
      </Toolbar>
    </AppBar>
    )
  }
  return (
    <TabBar tabs={tabs} setActiveTab={setActiveTab} activeTab={activeTab} scrollRef={scrollRef} />
  );
};
AppTabs.propTypes = {
  tabs: PropTypes.instanceOf(Array).isRequired,
  activeTab: PropTypes.number.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  scrollRef: PropTypes.object,
  withAppBar: PropTypes.bool,
};
TabBar.propTypes = {
  tabs: PropTypes.instanceOf(Array).isRequired,
  activeTab: PropTypes.number.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  scrollRef: PropTypes.object,
};
TabBar.defaultProps = {
  scrollRef: null
};
AppTabs.defaultProps = {
  scrollRef: null,
  withAppBar: false,
};

export default AppTabs;
