export const GenresActionTypes = {
  FETCH_RADIO_GENDERS_START: 'FETCH_RADIO_GENDERS_START',
  FETCH_RADIO_GENDERS_SUCCESS: 'FETCH_RADIO_GENDERS_SUCCESS',
  FETCH_RADIO_GENDERS_FAILURE: 'FETCH_RADIO_GENDERS_FAILURE',
  SET_RADIO_FILTER_GENRE: 'SET_RADIO_FILTER_GENRE',
  CLEAR_GENRES: 'CLEAR_GENRES'
};

export const clearGenres = () => ({
  type: GenresActionTypes.CLEAR_GENRES,
});

export const setGenreActive = (payload) => ({
  type: GenresActionTypes.SET_RADIO_FILTER_GENRE,
  payload,
});

export const fetchRadioGendersStart = () => ({
  type: GenresActionTypes.FETCH_RADIO_GENDERS_START,
});

export const fetchRadioGendersSuccess = (payload) => ({
  type: GenresActionTypes.FETCH_RADIO_GENDERS_SUCCESS,
  payload,
});

export const fetchRadioGendersFailure = (errorMessage) => ({
  type: GenresActionTypes.FETCH_RADIO_GENDERS_FAILURE,
  payload: errorMessage,
});
