import React, { useState } from 'react';
import {
  Avatar,
  Box,
  ClickAwayListener,
  Collapse,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  IconButton,
  SvgIcon,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { retrieveVideoImage } from 'utils/images';
import { RWebShare } from "react-web-share";
import theme from '../../AppTheme';
import { ReactComponent as playlistAdd } from '../../assets/icons/ic_playlist_add.svg';
import { ReactComponent as playlistDelete } from '../../assets/icons/ic_delete.svg';
import { ReactComponent as playlistDrag } from '../../assets/icons/ic_drag_handle.svg';
import { ReactComponent as share } from '../../assets/icons/share.svg';

const useStyles = makeStyles({
  paper: {
    backgroundColor: 'transparent',
  },
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    borderRadius: 6,
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
    backgroundColor: '#142F42',
    transition: theme.transitions.create(['left'], {
      duration: theme.transitions.duration.standard,
    }),
  },
  listItemPrimary: {
    backgroundColor: '#142F42',
    zIndex: 1,
  },
  listItemSecondary: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    justifyContent: 'flex-end',
    padding: 0,
    paddingRight: theme.spacing(0.5),
  },
  transition: {
    transition: theme.transitions.create(['left'], {
      duration: theme.transitions.duration.standard,
    }),
  },
  button: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
  },
});

const ListItemSongSwiper = ({
  song,
  clickAction,
  favoriteAction,
  addPlaylistAction,
  deletePlaylistAction,
  selected,
}) => {
  const styles = useStyles();
  const [touchX, setTouchX] = useState(null);
  const [moveX, setMoveX] = useState(0);
  const [transition, setTransition] = useState(false);
  const [deleteCollapse, setDeleteCollapse] = useState(true);
  const moveArea = theme.spacing(18.5);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setTransition(true);
        setMoveX(0);
      }}
    >
      <Collapse in={deleteCollapse}>
        <Box position="relative" overflow="hidden">
          <ListItem
            button
            divider
            selected={selected}
            key={song.id}
            onClick={() => {
              setTransition(true);
              if (moveX > 0) {
                setMoveX(0);
              } else {
                clickAction(song);
              }
            }}
            onTouchStart={(e) => {
              setTouchX(e.changedTouches[0].clientX);
              setMoveX(0);
            }}
            onTouchMove={(e) => {
              const touchMoveX = e.changedTouches[0].clientX;
              setTransition(false);
              if (touchX - touchMoveX > moveArea) {
                setMoveX(moveArea);
              } else if (touchX - touchMoveX < 0) {
                setMoveX(0);
              } else {
                setMoveX(touchX - touchMoveX);
              }
            }}
            onTouchEnd={(e) => {
              const touchStopX = e.changedTouches[0].clientX;
              setTransition(true);
              if (touchX - touchStopX > 100) {
                setMoveX(moveArea);
              } else {
                setMoveX(0);
              }
            }}
            classes={{ container: styles.listItemPrimary }}
            ContainerProps={{
              className: classNames({ [styles.transition]: transition }),
              style: { left: -moveX },
            }}
          >
            <ListItemAvatar>
              <Avatar src={retrieveVideoImage(song.youtube_code)} className={styles.avatar} />
            </ListItemAvatar>
            <ListItemText
              primary={song.name}
              secondary={song.author}
              secondaryTypographyProps={{ variant: 'caption' }}
            />
            <ListItemSecondaryAction>
              <IconButton color="primary" aria-label="move" onClick={addPlaylistAction}>
                <SvgIcon component={playlistAdd} viewBox="0 0 24 24" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button={false} className={styles.listItemSecondary}>
            <Box
              position="relative"
              pt={1.5}
              pb={1.5}
              pl={1.5}
              pr={1.5}
              bgcolor="background.default"
            >
              <RWebShare
                data={{
                  text: `Hi! You may like this music I found on Playguin\n${song.name}.\nCheck out more on ${new URL("https://app.playguin.com")}.\n`,
              url: `https://www.youtube.com/watch?v=${song.youtube_code}`,
              title: "Playguin",
                }}
              onClick={() => console.log("shared successfully!")}
              >
              <IconButton aria-label="share">
                <SvgIcon component={share} viewBox="0 0 24 24" />
              </IconButton>
              </RWebShare>
            </Box>
          <Box position="relative" pt={1.5} pb={1.5} pl={1.5} pr={1.5} bgcolor="error.main">
            <IconButton
              aria-label="delete"
              onClick={() => {
                setDeleteCollapse(false);
                setTimeout(() => deletePlaylistAction(), theme.transitions.duration.standard);
              }}
            >
              <SvgIcon component={playlistDelete} viewBox="0 0 24 24" />
            </IconButton>
          </Box>
          </ListItem>
        </Box>
      </Collapse>
    </ClickAwayListener >
  );
};

ListItemSongSwiper.propTypes = {
  song: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    author: PropTypes.string,
    youtube_code: PropTypes.string,
  }).isRequired,
  clickAction: PropTypes.func.isRequired,
  favoriteAction: PropTypes.func.isRequired,
  addPlaylistAction: PropTypes.func,
  deletePlaylistAction: PropTypes.func,
  selected: PropTypes.bool,
};

ListItemSongSwiper.defaultProps = {
  addPlaylistAction: null,
  deletePlaylistAction: null,
  selected: false,
};

export default ListItemSongSwiper;
