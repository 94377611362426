// @flow
import React from 'react';
import { AppBar } from '@material-ui/core';
import PropTypes from 'prop-types';
import song from '../assets/images/song.png';
import DialogDrawerFull from '../components/dialogs/DialogDrawerFull';
import RadioHeading from '../components/partials/RadioHeading';
import PaperGridRadios from '../components/papers/PaperGridRadios';
import PaperRadioControl from '../components/papers/PaperRadioControl';


const DialogRadio = ({ open, setOpen, radio, isPlaying, handleDialogRadioOnplay }) => {
  return (
    <DialogDrawerFull
      setOpen={setOpen}
      open={open}
    >
      <RadioHeading
        radio={radio}
        backAction={() => setOpen(false)}
        favoriteAction={(e) => console.log('favorite', e)}
      />
      <PaperRadioControl
        radio={radio}
        isPlaying={isPlaying}
        playAction={() => handleDialogRadioOnplay()}
        pauseAction={() => handleDialogRadioOnplay()}
      />

      {!!radio?.related?.length && (
        <PaperGridRadios title="SIMILAR STATIONS" radios={radio.related} />
      )}
    </DialogDrawerFull>
  );
};

DialogRadio.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  handleDialogRadioOnplay: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  radio: PropTypes.object.isRequired,
};

export default DialogRadio;
