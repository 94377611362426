import React from 'react';
import { Paper, Container, Typography, Box, Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import CardRadio from '../cards/CardRadio';
import theme from '../../AppTheme';

const useStyles = makeStyles({
  paper: {
    backgroundColor: 'transparent',
  },
  container: {
    paddingRight: 0,
  },
  slider: {
    "& .slick-track": {
      display: "flex",
    },
    '& .slick-slide': {
      paddingRight: theme.spacing(2),
      height: "auto",
      "& > * ": {
        height: "100%",
      },
    },
  },
});

const settings = {
  arrows: false,
  dots: false,
  infinite: false,
  speed: theme.transitions.duration.complex,
  slidesToShow: 2.2,
  slidesToScroll: 1,
  swipeToSlide: true,
};

const PaperGridRadios = ({ radios, title }) => {
  const styles = useStyles();
  return (
    <Paper elevation={0} square>
      <Container>
        {title && (
          <Box pb={2}>
            <Typography variant="h5">{title}</Typography>
          </Box>
        )}
      </Container>
      <Container className={styles.container}>
        <Slider {...settings} className={styles.slider}>
          {radios.slice(0, 8).map((radio) => {
            return (
              <CardRadio radio={radio} cardAction={() => {}} />
            );
          })}
        </Slider>
      </Container>
    </Paper>
  );
};

PaperGridRadios.propTypes = {
  title: PropTypes.string,
  radios: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.string,
      isFavorite: PropTypes.bool,
    }),
  ).isRequired,
};

PaperGridRadios.defaultProps = {
  title: null,
};

export default PaperGridRadios;
