// @flow
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  AppBar,
  Box,
  Button,
  Grid,
  Toolbar,
  Typography,
  makeStyles,
  Container,
  FormControlLabel,
  Checkbox,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { signUpStart } from 'actions/userActions';
import DialogDrawerFull from '../components/dialogs/DialogDrawerFull';
import theme from '../AppTheme';

const useStyles = makeStyles({
  appBarTop: {
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(3),
  },
  appBarBottom: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  box: {
    width: '100%',
    paddingTop: '56%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
  },
  round: {
    boxShadow: '0 0 0 1000px rgba(0,0,0,0.6)',
    borderRadius: '100%',
    width: theme.spacing(25),
    height: theme.spacing(25),
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
});

const DialogSignUp = ({ open, setOpen }) => {
  const styles = useStyles();
  const [tos, setTos] = useState(false);

  const [userCredentials, setUserCredentials] = useState({
    displayName: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const { displayName, email, password, confirmPassword } = userCredentials;

  const dispatch = useDispatch();
  const signUp = (payload) => dispatch(signUpStart(payload));

  const handleSubmit = async (event) => {
    if (!tos) return;
    if (password !== confirmPassword) {
      alert("passwords don't match");
      return;
    }
    signUp({ displayName, email, password });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setUserCredentials({ ...userCredentials, [name]: value });
  };

  return (
    <DialogDrawerFull
      setOpen={setOpen}
      open={open}
      appBarTop={
        <AppBar elevation={0} color="transparent" position="static" className={styles.appBarTop}>
          <Toolbar>
            <Typography component="strong" variant="h4">
              Sign up
            </Typography>
          </Toolbar>
        </AppBar>
      }
      appBarBottom={
        <AppBar elevation={0} color="transparent" position="static" className={styles.appBarBottom}>
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <Button
                  color="primary"
                  size="large"
                  variant="outlined"
                  fullWidth
                  onClick={() => setOpen(false)}
                >
                  CANCEL
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  fullWidth
                  onClick={handleSubmit}
                >
                  SIGN UP
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      }
    >
      <Box height="100%">
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                variant="filled"
                color="primary"
                type="text"
                name="displayName"
                value={displayName}
                onChange={handleChange}
                label="Name"
                fullWidth
                autoComplete="off"
                error={false}
                inputProps={{
                  autoComplete: 'off',
                  form: {
                    autoComplete: 'off',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="filled"
                color="primary"
                type="email"
                label="Email"
                name="email"
                value={email}
                onChange={handleChange}
                fullWidth
                autoComplete="off"
                inputProps={{
                  autoComplete: 'new-password',
                  form: {
                    autoComplete: 'off',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="filled"
                color="primary"
                type="password"
                label="Password"
                name="password"
                value={password}
                onChange={handleChange}
                fullWidth
                autoComplete="off"
                inputProps={{
                  autoComplete: 'new-password',
                  form: {
                    autoComplete: 'off',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="filled"
                color="primary"
                type="password"
                label="Confirm password"
                name="confirmPassword"
                value={confirmPassword}
                onChange={handleChange}
                fullWidth
                autoComplete="off"
                inputProps={{
                  autoComplete: 'new-password',
                  form: {
                    autoComplete: 'off',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tos}
                    onChange={(e) => setTos(e.target.checked)}
                    color="primary"
                  />
                }
                label="Terms of service"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </DialogDrawerFull>
  );
};

DialogSignUp.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default DialogSignUp;
