// @flow
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ReactComponent as featured } from '../../assets/icons/featured.svg';
import { ReactComponent as radio } from '../../assets/icons/radio.svg';
import { ReactComponent as library } from '../../assets/icons/library.svg';
import { ReactComponent as playguin } from '../../assets/icons/playguin.svg';
import { ReactComponent as recognition } from '../../assets/icons/recognition.svg';
import { ReactComponent as libraryAdd } from '../../assets/icons/ic_library_add.svg';
import { ReactComponent as playlistAdd } from '../../assets/icons/ic_playlist_add.svg';

const icons = {
  featured: {
    component: featured,
    viewBox: '0 0 24 24',
  },
  radio: {
    component: radio,
    viewBox: '0 0 24 24',
  },
  library: {
    component: library,
    viewBox: '0 0 24 24',
  },
  playguin: {
    component: playguin,
    viewBox: '0 0 24 24',
  },
  recognition: {
    component: recognition,
    viewBox: '0 0 24 24',
  },
  addToLibrary: {
    component: libraryAdd,
    viewBox: '0 0 24 24',
  },
  addToList: {
    component: playlistAdd,
    viewBox: '0 0 24 24',
  },
};

const Icon = ({ icon, size }) => {
  return  <SvgIcon component={icons[icon].component} viewBox={icons[icon].viewBox} fontSize={size} />;
};

Icon.propTypes = {
  icon: PropTypes.oneOf(["featured","radio","library","playguin","recognition","addToLibrary","addToList"]).isRequired,
  size: PropTypes.oneOf(["small","default","large"]),
};
Icon.defaultProps = {
  size: "default",
};

export default Icon;
