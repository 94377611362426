/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback, useEffect } from 'react';
import { Container, Paper, List, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import ListItemSongSwiper from '../listItems/ListItemSongSwiper';

const useStyles = makeStyles({
  paper: {
    backgroundColor: 'transparent',
  },
});

const PaperListSongsSwiper = ({
  // searchValue,
  addPlaylistAction,
  playlistType,
  onSongClick,
  deletePlaylistAction,
  songs,
}) => {
  const styles = useStyles();
  const [playlist, setPlaylist] = useState(songs);
  const moveSong = useCallback(
    (dragIndex, hoverIndex) => {
      const dragSong = playlist[dragIndex];
      setPlaylist(
        update(playlist, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragSong],
          ],
        }),
      );
    },
    [playlist],
  );

  useEffect(() => {
    if (songs.length) setPlaylist(songs);
  }, [songs]);

  return (
    <Paper elevation={0} square className={styles.paper}>
      <Container maxWidth={false} disableGutters>
        <List component="div" disablePadding>
          {playlist
            // ?.filter((v) => {
            //   if (searchValue) return v.name.toLowerCase().includes(searchValue.toLowerCase());
            //   return v;
            // })
            .map((song, index) => {
              return (
                <ListItemSongSwiper
                  key={`${song.name.trim()}${index}`}
                  song={song}
                  id={song.youtube_code}
                  index={index}
                  clickAction={(e) => onSongClick && onSongClick(index)}
                  favoriteAction={(e) => console.log(e)}
                  addPlaylistAction={() =>
                    addPlaylistAction({
                      name: song.name,
                      youtube_code: song.youtube_code,
                    })
                  }
                  deletePlaylistAction={() => deletePlaylistAction(song.youtube_code)}
                  moveAction={moveSong}
                />
              );
            })}
        </List>
      </Container>
    </Paper>
  );
};

PaperListSongsSwiper.propTypes = {
  songs: PropTypes.instanceOf(Array).isRequired,
  addPlaylistAction: PropTypes.func,
  deletePlaylistAction: PropTypes.func,
  // searchValue: PropTypes.string.isRequired,
  playlistType: PropTypes.string,
  onSongClick: PropTypes.func,
};

PaperListSongsSwiper.defaultProps = {
  addPlaylistAction: null,
  deletePlaylistAction: null,
  onSongClick: null,
  playlistType: '',
};

export default PaperListSongsSwiper;
