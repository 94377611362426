import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import {
  firestore,
  convertCategoriesSnapshotWithRefToMap,
  convertCategorySnapshotWithRefToMap,
} from 'firebase/firebase.utils';

import {
  fetchCategoriesSuccess,
  fetchCategoriesFailure,
  CategoriesActionTypes,
  fetchCategoryByIdSuccess,
  fetchCategoryByIdFailure,
  setLastVisible,
} from 'actions/categoriesActions';

export function* fetchCategoriesAsync() {
  try {
    const collectionRef = firestore
      .collection('categories')
      .where('active', '==', true)
      .orderBy('order', 'asc');
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(convertCategoriesSnapshotWithRefToMap, snapshot);
    yield put(fetchCategoriesSuccess(collectionsMap));
  } catch (error) {
    yield put(fetchCategoriesFailure(error.message));
  }
}

export function* fetchCategoriesStart() {
  yield takeLatest(CategoriesActionTypes.FETCH_CATEGORIES_START, fetchCategoriesAsync);
}

export function* fetchCategoryByIdAsync({ payload }) {
  try {
    const lastVisible = yield select((state) => state.categoryAll.lastVisible);
    let lastVisibleRef = null;
    if (lastVisible)
      lastVisibleRef = yield firestore
        .collection(`categories/${payload}/playlists`)
        .doc(lastVisible.id)
        .get();
    const collectionRef = firestore
      .collection(`categories/${payload}/playlists`)
      .where('active', '==', 1)
      .orderBy('order', 'asc')
      .startAfter(lastVisibleRef || 0)
      .limit(20);
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(convertCategorySnapshotWithRefToMap, snapshot);
    yield put(fetchCategoryByIdSuccess({ id: payload, playlists: collectionsMap }));
    if (collectionsMap.length) yield put(setLastVisible(collectionsMap[collectionsMap.length - 1]));
  } catch (error) {
    yield put(fetchCategoryByIdFailure(error.message));
  }
}

export function* fetchCategoryByIdStart() {
  yield takeLatest(CategoriesActionTypes.FETCH_CATEGORY_BY_ID_START, fetchCategoryByIdAsync);
}

export function* categoriesSagas() {
  yield all([call(fetchCategoriesStart), call(fetchCategoryByIdStart)]);
}
