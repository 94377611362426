import { AudioRecognitionActionTypes } from 'actions/audioRecognitionActions';

const INITIAL_STATE = {
  track: null,
  open: false,
  isFetching: false,
  errorMessage: undefined,
};

const audioRecognitionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AudioRecognitionActionTypes.FETCH_AUDIO_RECOGNITION_START:
      return {
        ...state,
        isFetching: true,
      };
    case AudioRecognitionActionTypes.FETCH_AUDIO_RECOGNITION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        track: action.payload.track,
        open: !action.payload.track,
      };
    case AudioRecognitionActionTypes.FETCH_AUDIO_RECOGNITION_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case AudioRecognitionActionTypes.SHOW_SNACKBAR_AUDIO_RECOGNITION:
      return {
        ...state,
        open: action.payload,
      };
    case AudioRecognitionActionTypes.CLEAR_RECOGNIZED_TRACK:
      return {
        ...state,
        track: null,
      };
    default:
      return state;
  }
};

export default audioRecognitionReducer;
