import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Flag from 'react-world-flags';
import { fetchRadioWithRelatedStart } from 'actions/radioActions';
import theme from '../../AppTheme';

const useStyles = makeStyles({
  card: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 10,
    height: '100%',
  },
  cardContent: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    height: "100%",
  },
  cardMedia: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    width: theme.spacing(3),
    height: theme.spacing(2),
    borderRadius: 0,
    "& img": {
      width: "100%",
    },
  },
  cardActionArea: {
    height: "100%",
  },
});

const CardRadio = ({ radio, cardAction }) => {
  const styles = useStyles();

  const dispatch = useDispatch();
  const fetchRadioWithRelated = (payload) => dispatch(fetchRadioWithRelatedStart(payload));

  return (
    <Card elevation={0} className={styles.card}>
      <CardActionArea onClick={() => fetchRadioWithRelated(radio.id)}  className={styles.cardActionArea}>
        <CardMedia className={styles.cardMedia}>
          <Flag code={radio.country} />
        </CardMedia>
        <CardContent className={styles.cardContent}>
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            {`${radio.title} ${radio.frequency !== 'e-radio' ? radio.frequency : ''}`}
          </Typography>
          <Typography variant="body2">{radio.gender.join(', ')}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

CardRadio.propTypes = {
  radio: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    country: PropTypes.string,
    frequency: PropTypes.string,
    image: PropTypes.string,
    gender: PropTypes.arrayOf(String),
    isFavorite: PropTypes.bool,
  }).isRequired,
  cardAction: PropTypes.func.isRequired,
};

export default CardRadio;
