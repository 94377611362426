// eslint-disable-next-line import/prefer-default-export
export const handleErrorResponse = (responsePayload, defaultMessage) => {
  if (
    responsePayload &&
    responsePayload.response &&
    responsePayload.response.data &&
    responsePayload.response.data.error
  ) {
    throw responsePayload.response.data.error;
  }

  const returnError = {
    message: defaultMessage,
  };
  throw returnError;
};
