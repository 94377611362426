/* eslint-disable array-callback-return */
/* eslint-disable no-debugger */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import Lottie from 'react-lottie';
import { clearCategories } from 'actions/categoriesActions';
import { clearSearchResults } from 'actions/searchActions';
import { clearGenres } from 'actions/genresActions';
import { clearCountries } from 'actions/countriesActions';
import { clearPlaylist } from 'actions/playlistsActions';
import { clearTrackList } from 'actions/favoriteTracksActions';
import { clearPlaylistList } from 'actions/favoritePlaylistsActions';
import { clearRadio } from 'actions/radioActions';
import updateAnimationData from 'assets/animations/update.json';

// import storage from 'redux-persist/lib/storage';
// import { persistor } from 'store/configureStore';
import packageJson from '../package.json';


const buildDateGreaterThan = (latestDate, currentDate) => {
  const momLatestDateTime = moment(latestDate);
  const momCurrentDateTime = moment(currentDate);

  if (momLatestDateTime.isAfter(momCurrentDateTime)) {
    return true;
  }
  return false;
};

function withClearCache(Component) {
  function ClearCacheComponent(props) {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(true);

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: updateAnimationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    const dispatch = useDispatch();
    const clearAllCategories = () => dispatch(clearCategories());
    const clearSearch = () => dispatch(clearSearchResults());
    const clearCurrPlaylist = () => dispatch(clearPlaylist());
    const clearGenre = () => dispatch(clearGenres());
    const clearCountry = () => dispatch(clearCountries());
    const clearTracks = () => dispatch(clearTrackList());
    const clearRadioList = () => dispatch(clearRadio());

    const emptyCacheStorage = async () => {
      if ('caches' in window) {
        // Service worker cache should be cleared with caches.delete()
        const cacheKeys = await window.caches.keys();
        await Promise.all(
          cacheKeys.map((key) => {
            window.caches.delete(key);
          }),
        );
      }

      // clear browser cache and reload page
      await clearAllCategories();
      await clearSearch();
      await clearGenre();
      await clearCountry();
      await clearCurrPlaylist();
      await clearTracks();
      await clearPlaylistList();
      await clearRadioList();
      // setTimeout(() => {
      //   window.location.replace(window.location.href);
      // }, 1000);
    };

    useEffect(() => {
      const myHeaders = new Headers();
      myHeaders.append('pragma', 'no-cache');
      myHeaders.append('cache-control', 'no-cache');

      const myInit = {
        method: 'GET',
        headers: myHeaders,
      };
      fetch('/meta.json', myInit)
        .then((response) => response.json())
        .then((meta) => {
          const latestVersionDate = meta.buildDate;
          const currentVersionDate = packageJson.buildDate;

          console.log(latestVersionDate);
          console.log(currentVersionDate);
          const shouldForceRefresh = buildDateGreaterThan(latestVersionDate, currentVersionDate);
          console.log(shouldForceRefresh);
          if (shouldForceRefresh) {
            setIsLatestBuildDate(false);
            emptyCacheStorage();
          }
        });
    }, []);

    return <>{isLatestBuildDate ? <Component {...props} /> : <Box py={6}><Lottie options={defaultOptions} />
      <Typography variant="h5" align="center">New update. Please refresh the page.</Typography></Box>}</>;
  }

  return ClearCacheComponent;
}

export default withClearCache;
