import React, { useState, useEffect } from 'react';
import {
  Paper,
  Card,
  Container,
  InputBase,
  IconButton,
  SvgIcon,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as close } from 'assets/icons/ic_close.svg';
import BackspaceOutlinedIcon from '@material-ui/icons/BackspaceOutlined';
import theme from 'AppTheme';

const useStyles = makeStyles({
  paper: {
    backgroundColor: 'transparent',
    flexGrow: 1,
  },
  paperWithAppBarTabs: {},
  card: {
    borderRadius: 30,
    borderColor: '#707070',
    display: 'flex',
    flexWrap: 'nowrap',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
  },
  input: {
    color: theme.palette.common.black,
    flexGrow: 1,
    height: theme.spacing(6),
  },
  button: {
    fill: theme.palette.common.black,
  },
});

const PaperSearch = ({ value, onValueChange, showBackspaceIcon, actionClose, keyPressEnabled }) => {
  const [localValue, setLocalValue] = useState(value || '');
  const styles = useStyles();

  useEffect(() => {
    if (!localValue && actionClose) actionClose();
  }, [localValue]);

  return (
    <Paper elevation={0} square className={classNames({ [styles.paper]: true })}>
        <Card elevation={0} variant="outlined" className={styles.card}>
          <InputBase
            className={styles.input}
            placeholder="Search..."
            value={localValue}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && keyPressEnabled) {
                onValueChange(e.target.value);
                document.activeElement.blur();
              }
            }}
            onChange={(e) => {
              setLocalValue(e.target.value);
              if (e.target.value === '' || !keyPressEnabled) onValueChange(e.target.value);
            }}
          />
          {localValue !== '' && (
            <IconButton
              className={styles.button}
              color="primary"
              edge="end"
              onClick={() => {
                onValueChange('');
                setLocalValue('');
                if (actionClose) actionClose();
              }}
            >
              <SvgIcon component={showBackspaceIcon ? BackspaceOutlinedIcon : close} viewBox="0 0 24 24" />
            </IconButton>
          )}
        </Card>
    </Paper>
  );
};

PaperSearch.propTypes = {
  value: PropTypes.string,
  onValueChange: PropTypes.func.isRequired,
  actionClose: PropTypes.func,
  keyPressEnabled: PropTypes.bool,
  showBackspaceIcon: PropTypes.bool,
};
PaperSearch.defaultProps = {
  value: '',
  actionClose: null,
  showBackspaceIcon: false,
  keyPressEnabled: true,
};

export default PaperSearch;
