/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
import { Box, makeStyles, Toolbar, SvgIcon, IconButton, Typography, Grid, Button, Container } from '@material-ui/core';
import PlaylistService from 'services/PlaylistService';
import { setPlayerSong, setPlaylistPlay, setPlayerPlaylist } from 'actions/playerActions';
import PaperListSearch from 'components/papers/PaperListSearch';
import { addFavoriteTrack } from 'actions/favoriteTracksActions';
import { addTrackToUserPlaylist, addFavoritePlaylistStart } from 'actions/favoritePlaylistsActions';
import theme from 'AppTheme';
import TracksService from 'services/TrackService';
import DialogDrawer from '../../components/dialogs/DialogDrawer';
import Content from '../../components/partials/Content';
import Preloader from '../../components/partials/Preloader';
import PaperSearch from '../../components/papers/PaperSearch';
import PaperListPlaylists from '../../components/papers/PaperListPlaylists';
import DialogPlaylistCreate from '../../dialogs/DialogPlaylistCreate';
import DialogPlaylistNew from '../../dialogs/DialogPlaylistNew';
import { ReactComponent as back } from '../../assets/icons/ic_arrow_back.svg';
import { ReactComponent as chevronRight } from '../../assets/icons/ic_chevron_right.svg';

const PaperListSearchWithPreloader = Preloader(PaperListSearch);

const useStyles = makeStyles({
  toolbar: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    justifyContent: 'space-between',
  },
});

function SearchResults() {
  const { type } = useParams();
  const history = useHistory();
  const styles = useStyles();

  const [dialogPlaylistAdd, setDialogPlaylistAdd] = useState(false);
  const [dialogPlaylistCreate, setDialogPlaylistCreate] = useState(false);
  const [dialogPlaylistNew, setDialogPlaylistNew] = useState(false);
  const [playlistName, setPlaylistName] = useState('');
  const [selectedSong, setSelectedSong] = useState(null);
  const [valueSearchPlaylists, setValueSearchPlaylists] = useState('');
  const [showAddToMyTracks, setShowAddToMyTracks] = useState(false);

  const isLoading = useSelector((state) => state.search.isFetching, shallowEqual);
  const items = useSelector((state) => state.search.all, shallowEqual);
  const isPlay = useSelector((state) => state.player.play, shallowEqual);
  const userPlaylists = useSelector((state) => state.userPlaylists.list, shallowEqual);
  const userTracks = useSelector((state) => state.tracks.list, shallowEqual);

  const dispatch = useDispatch();
  const addSongToPlayer = (payload) => dispatch(setPlayerSong(payload));
  const setYoutubePlayerPlaylist = (payload) => dispatch(setPlayerPlaylist(payload));
  const openPlayer = () => dispatch(setPlaylistPlay());
  const addFavoriteSong = (payload) => dispatch(addFavoriteTrack(payload));
  const addSongToUserPlaylist = (payload) => dispatch(addTrackToUserPlaylist(payload));
  const addFavoritePlist = (payload) => dispatch(addFavoritePlaylistStart(payload));

  const trackExist =
    selectedSong && !userTracks.find((t) => t.youtube_code === selectedSong.youtube_code);

  const playSong = (code) => {
    addSongToPlayer({ code });
    if (!isPlay) openPlayer();
  };

  const playPlaylist = async (listId) => {
    const { songs } = await PlaylistService.fetchPlaylistById({
      listId,
    });
    setYoutubePlayerPlaylist({
      code: listId,
      videos: songs.map(({ name, youtube_code, index }) => ({
        name,
        youtube_code,
        index,
      })),
    });
    if (!isPlay) openPlayer();
    else {
      openPlayer();
      openPlayer();
    }
  };

  const handleOpenDialogCreate = (value) => {
    setDialogPlaylistCreate(value);
  };

  const handleOpenDialogNew = (value) => {
    setDialogPlaylistNew(value);
  };

  const handleSearchValue = debounce((value) => {
    setValueSearchPlaylists(value);
  }, 1000);

  return (
    <>
      {/* <Toolbar className={styles.toolbar}>
        <IconButton onClick={() => history.push('/fetured')}>
          <SvgIcon component={back} viewBox="0 0 24 24" />
        </IconButton>
      </Toolbar> */}

        <Box pt={4} pb={6}>
          <Container maxWidth={false}>
            <Box pb={2}>
              <Grid container spacing={2} alignItems="center" justify="space-between">
                <Grid item>
                  <Typography autoCapitalize={type} variant="h5" color="primary">
                    All searched {type}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Container>
          <PaperListSearchWithPreloader
            items={items}
            type={type}
            primaryAction={type === 'tracks' ? playSong : playPlaylist}
            secondaryAction={
              type === 'tracks'
                ? async (item) => {
                    const data = await TracksService.checkIfTrackExists({
                      track: item.youtube_code,
                    });
                    setShowAddToMyTracks(!data.isExistInUserTracks);
                    setDialogPlaylistAdd(true);
                    setSelectedSong(item);
                  }
                : ({ title, count, youtube_playlist_code, existed }) => {
                    addFavoritePlist({
                      existed,
                      title,
                      youtube_playlist_code,
                      count,
                    });
                  }
            }
            isLoading={isLoading}
          />
          <DialogDrawer setOpen={setDialogPlaylistAdd} open={dialogPlaylistAdd} title="Playlists">
            <PaperSearch value={valueSearchPlaylists} onValueChange={handleSearchValue} />
            <PaperListPlaylists
              playlists={userPlaylists.filter(({ createdByUser: isCustom }) => isCustom)}
              selectedSong={selectedSong}
              searchValue={valueSearchPlaylists}
              primaryAction={() => {}}
              showAddToMyTracks={trackExist}
              createAction={() => {
                // setDialogPlaylistAdd(false);
                setDialogPlaylistCreate(true);
              }}
              addToMyTracksAction={
                showAddToMyTracks
                  ? () => {
                      // setDialogPlaylistAdd(false);
                      addFavoriteSong(selectedSong);
                    }
                  : null
              }
              secondaryAction={(currPlaylist) => {
                if (
                  currPlaylist.songs.every((s) => s.youtube_code !== selectedSong?.youtube_code)
                ) {
                  addSongToUserPlaylist({
                    ...currPlaylist,
                    count: currPlaylist.count + 1,
                    songs: [...currPlaylist.songs, selectedSong],
                  });
                  // setDialogPlaylistAdd(false);
                }
              }}
            />
          </DialogDrawer>
          {dialogPlaylistCreate && (
            <DialogPlaylistCreate
              open={dialogPlaylistCreate}
              setOpen={handleOpenDialogCreate}
              playlistName={playlistName}
              setPlaylistName={setPlaylistName}
              createPlaylistAction={(name) => {
                setPlaylistName(name);
                setDialogPlaylistCreate(false);
                setDialogPlaylistNew(true);
              }}
            />
          )}
          {dialogPlaylistNew && (
            <DialogPlaylistNew
              open={dialogPlaylistNew}
              setOpen={handleOpenDialogNew}
              playlistName={playlistName}
              selectedSong={selectedSong}
            />
          )}
        </Box>
    
    </>
  );
}

export default SearchResults;
