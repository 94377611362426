import React from 'react';
import { CircularProgress, Box } from '@material-ui/core';

// eslint-disable-next-line react/prop-types
const Preloader = (WrappedComponent) => ({ isLoading, ...otherProps }) => {
  return isLoading ? (
    <Box display="flex" alignItems="center" justifyContent="center" height="50vh">
      <CircularProgress color="primary" size={56} />
    </Box>
  ) : (
    <WrappedComponent {...otherProps} />
  );
};

export default Preloader;
