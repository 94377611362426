// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

export default function PrivateRoute({ component: Component, ...rest }) {
  const currentUser = useSelector((state) => state.user.currentUser, shallowEqual);
  return (
    <Route
      {...rest}
      component={() => {
        return currentUser?.id ? <Component {...rest} /> : <Redirect to="/" />;
      }}
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  isLoading: PropTypes.bool,
};
PrivateRoute.defaultProps = {
  isLoading: false,
};
