import { PlaylistsActionTypes } from 'actions/playlistsActions';
import { FavoritePlaylistsActionTypes } from 'actions/favoritePlaylistsActions';

const CLEAR_ERROR = 'CLEAR_ERRORS';

const INITIAL_STATE = {
  current: {},
  errorMessage: undefined,
};

const playlistsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PlaylistsActionTypes.SET_SELECTED_PLAYLIST:
      return {
        ...state,
        current: { ...action.payload },
      };
    case FavoritePlaylistsActionTypes.ADD_TRACK_TO_USER_PLAYLIST:
      return {
        ...state,
        current: { ...state.current, videos: state.current.id === action.payload.id ? action.payload.songs : state.current.videos },
      };
    case FavoritePlaylistsActionTypes.RENAME_USER_PLAYLIST_SUCCESS:
      return {
        ...state,
        current: { ...state.current, title: action.payload.name },
      };
    case PlaylistsActionTypes.CLEAR_SELECTED_PLAYLIST:
      return {
        ...state,
        current: {},
      };
    case CLEAR_ERROR:
      return {
        ...state,
        errorMessage: null,
      };
    default:
      return state;
  }
};

export default playlistsReducer;
