import React from 'react';
import {
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  Typography,
  SvgIcon,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import theme from '../../AppTheme';
import { ReactComponent as add } from '../../assets/icons/ic_add.svg';

const useStyles = makeStyles({
  card: {
    backgroundColor: 'transparent',
  },
  cardContent: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: 0,
    paddingRight: 0,
  },
  cardMedia: {
    paddingTop: '100%',
    borderRadius: 6,
    borderStyle: 'dashed',
    borderWidth: 2,
    borderColor: theme.palette.primary.main,
    position: 'relative',
  },
  svgIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
});

const CardPlaylistAdd = ({ action }) => {
  const styles = useStyles();
  return (
    <Card elevation={0} square className={styles.card}>
      <CardActionArea onClick={() => action()}>
        <CardMedia className={styles.cardMedia}>
          <SvgIcon className={styles.svgIcon} component={add} viewBox={'0 0 24 24'} />
        </CardMedia>
        <CardContent className={styles.cardContent}>
          <Typography color="textPrimary">New playlist</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

CardPlaylistAdd.propTypes = {
  action: PropTypes.func.isRequired,
};

export default CardPlaylistAdd;
