export const CountriesActionTypes = {
  FETCH_RADIO_COUNTRIES_START: 'FETCH_RADIO_COUNTRIES_START',
  FETCH_RADIO_COUNTRIES_SUCCESS: 'FETCH_RADIO_COUNTRIES_SUCCESS',
  FETCH_RADIO_COUNTRIES_FAILURE: 'FETCH_RADIO_COUNTRIES_FAILURE',
  SET_RADIO_FILTER_COUNTRY: 'SET_RADIO_FILTER_COUNTRY',
  CLEAR_COUNTRIES: 'CLEAR_COUNTRIES',
};

export const clearCountries = () => ({
  type: CountriesActionTypes.CLEAR_COUNTRIES,
});

export const fetchRadioCountriesStart = () => ({
  type: CountriesActionTypes.FETCH_RADIO_COUNTRIES_START,
});

export const fetchRadioCountriesSuccess = (payload) => ({
  type: CountriesActionTypes.FETCH_RADIO_COUNTRIES_SUCCESS,
  payload,
});

export const fetchRadioCountriesFailure = (errorMessage) => ({
  type: CountriesActionTypes.FETCH_RADIO_COUNTRIES_FAILURE,
  payload: errorMessage,
});

export const setCountryActive = (payload) => ({
  type: CountriesActionTypes.SET_RADIO_FILTER_COUNTRY,
  payload,
});
