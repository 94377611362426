export const HeaderActionTypes = {
  SET_DRAWER_ACCOUNT: 'SET_DRAWER_ACCOUNT',
  SET_SHOW_SEARCH_RADIO: 'SET_SHOW_SEARCH_RADIO',
  SET_SHOW_SEARCH_LIBRARY: 'SET_SHOW_SEARCH_LIBRARY',
  SET_SHOW_RENAME: 'SET_SHOW_RENAME',
  SET_SHOW_ADD_TRACKS: 'SET_SHOW_ADD_TRACKS'
};

export const setDrawerAccount = (payload) => ({
  type: HeaderActionTypes.SET_DRAWER_ACCOUNT,
  payload,
});

export const setShowSearchRadio = (payload) => ({
  type: HeaderActionTypes.SET_SHOW_SEARCH_RADIO,
  payload,
});

export const setShowSearchLibrary = (payload) => ({
  type: HeaderActionTypes.SET_SHOW_SEARCH_LIBRARY,
  payload,
});

export const setShowRename = (payload) => ({
  type: HeaderActionTypes.SET_SHOW_RENAME,
  payload,
});

export const setShowAddTracks = (payload) => ({
  type: HeaderActionTypes.SET_SHOW_ADD_TRACKS,
  payload,
});
