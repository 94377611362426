import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  Paper,
  Button,
  Container,
  Grid,
  Menu,
  MenuItem,
  SvgIcon,
  CircularProgress,
  Box,
  ButtonGroup,
  makeStyles,
} from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroller';
import PropTypes from 'prop-types';
import { retrieveVideoImage } from 'utils/images';
import { setSelectedPlaylist } from 'actions/playlistsActions';
import { fetchFavoritePlaylistsStart, setFilterPlaylist, setOrderPlaylist } from 'actions/favoritePlaylistsActions';
import { fetchCategoryByIdStart } from 'actions/categoriesActions';
import CardPlaylist from '../cards/CardPlaylist';
import playlistImage from '../../assets/images/playlist.png';
import CardPlaylistAdd from '../cards/CardPlaylistAdd';
import { ReactComponent as sort } from '../../assets/icons/ic_sort.svg';
import { ReactComponent as sortReverse } from '../../assets/icons/sort_reverse.svg';

const useStyles = makeStyles({
  paper: {
    backgroundColor: 'transparent',
  },
  menu: {},
});

const PaperGridPlaylist = ({
  playlists,
  addPlaylistAction,
  hideFilters,
  playlistAction,
  searchValue,
}) => {
  const styles = useStyles();
  const [anchorMenu, setAnchorMenu] = useState(null);
  const history = useHistory();
  const { catId } = useParams();

  const filter = useSelector((state) => state.userPlaylists.filter, shallowEqual);
  const order = useSelector((state) => state.userPlaylists.order, shallowEqual);
  const hasMore = useSelector(
    (state) => (catId ? state.categoryAll.hasMore : state.userPlaylists.hasMore),
    shallowEqual,
  );

  const dispatch = useDispatch();
  const setPlaylistInfo = (payload) => dispatch(setSelectedPlaylist(payload));
  const fetchUserPlaylists = () => dispatch(fetchFavoritePlaylistsStart());
  const fetchCategoryById = (payload) => dispatch(fetchCategoryByIdStart(payload));
  const setFilter = (payload) => dispatch(setFilterPlaylist(payload));
  const setOrder = (payload) => dispatch(setOrderPlaylist(payload));

  const filters = { title: 'ALPHABETICAL', count: 'SONG COUNT', created_at: 'DATE ADDED' };

  return (
    <Paper elevation={0} square classes={{ root: styles.paper }}>
      <Container>
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          loadMore={() => {
            if (catId) fetchCategoryById(catId);
            else fetchUserPlaylists();
          }}
          hasMore={hasMore}
          loader={
            <Box key={0} display="flex" alignItems="center" justifyContent="center" p="2">
              <CircularProgress color="primary" size={56} />
            </Box>
          }
          threshold={20}
        >
          <Grid container spacing={2}>
            {!hideFilters && (
              <Grid item xs={12}>
                <Grid container justify="flex-end">
                  <Grid item>
                    <ButtonGroup disableElevation variant="text" color="primary">
                      <Button
                        variant="text"
                        color="primary"
                        onClick={(e) => setAnchorMenu(e.currentTarget)}
                      >
                        {filters[filter]}
                      </Button>
                      <Button
                        color="primary"
                        endIcon={
                          <SvgIcon
                            component={order === 'asc' ? sortReverse : sort}
                            viewBox="0 0 24 24"
                          />
                        }
                        onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
                      />
                    </ButtonGroup>
                    <Menu
                      elevation={0}
                      className={styles.menu}
                      anchorEl={anchorMenu}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      getContentAnchorEl={null}
                      keepMounted
                      open={Boolean(anchorMenu)}
                      onClose={() => {
                        setAnchorMenu(null);
                      }}
                    >
                      {Object.keys(filters)
                        .filter((k) => k !== filter)
                        .map((key) => (
                          <MenuItem
                            key={key}
                            onClick={() => {
                              setAnchorMenu(null);
                              setFilter(key);
                            }}
                          >
                            {filters[key]}
                          </MenuItem>
                        ))}
                    </Menu>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {addPlaylistAction !== null && (
              <Grid item xs={6}>
                <CardPlaylistAdd action={addPlaylistAction} />
              </Grid>
            )}
            {playlists
              ?.filter((p) => {
                if (searchValue)
                  return p.translation.gr.title.toLowerCase().includes(searchValue.toLowerCase());
                return p;
              })
              .map((playlist) => {
                return (
                  <Grid key={playlist.id} item xs={6}>
                    <CardPlaylist
                      songs={playlist?.songs?.length || 0}
                      playlistType={playlist.type}
                      createdByUser={playlist.createdByUser}
                      isForRecognition={playlist.isForRecognition}
                      action={() => {
                        setPlaylistInfo({
                          id: playlist.id,
                          image:
                            playlist.image280 ||
                            (playlist?.songs?.length &&
                              retrieveVideoImage(playlist?.songs[0]?.youtube_code)),
                          videos: playlist.songs,
                          playlistType: playlist.type,
                          gender: playlist.gender,
                          createdByUser: playlist.createdByUser,
                          isForRecognition: playlist.isForRecognition,
                          title: playlist.translation?.gr?.title || playlist.title,
                          code: playlist.youtube_playlist_code || null,
                          count: playlist.count || null,
                        });
                        history.push(
                          catId
                            ? `/playlist/${
                                playlist.youtube_playlist_code || playlist.id
                              }?category=${catId}`
                            : `/playlist/${playlist.youtube_playlist_code || playlist.id}?library`,
                        );
                      }}
                      title={playlist.translation?.gr?.title || playlist.title}
                      image={
                        playlist.image280 ||
                        (playlist?.songs?.length &&
                          retrieveVideoImage(playlist?.songs[0]?.youtube_code))
                      }
                    />
                  </Grid>
                );
              })}
          </Grid>
        </InfiniteScroll>
      </Container>
    </Paper>
  );
};

PaperGridPlaylist.propTypes = {
  playlists: PropTypes.instanceOf(Array).isRequired,
  addPlaylistAction: PropTypes.func,
  hideFilters: PropTypes.bool,
  playlistAction: PropTypes.func,
  searchValue: PropTypes.string,
};
PaperGridPlaylist.defaultProps = {
  addPlaylistAction: null,
  hideFilters: false,
  playlistAction: null,
  searchValue: '',
};

export default PaperGridPlaylist;
