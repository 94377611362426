/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect, forwardRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import DialogPlayer from 'dialogs/DialogPlayer';
import DialogRadio from 'dialogs/DialogRadio';
import ReactPlayer from 'react-player/youtube';
import ReactHlsPlayer from 'react-hls-player';
import { isEmpty } from 'lodash';
import {
  clearPlayerPlaylist,
  setPlaylistShuffle,
  setPlaylistRepeat,
  setPlaylistPlay,
  clearPlayerRadio,
  clearPlayerSong,
  updateSongIndex,
  clearShuffleList,
  createShuffleList,
  setCurrentVideo,
} from 'actions/playerActions';
import { fetchRadioWithRelatedStart } from 'actions/radioActions';
import FloatingPlayer from '../partials/FloatingPlayer';

const YoutubePlayer = forwardRef(
  // eslint-disable-next-line react/prop-types
  (
    {
      handleOnBufferEnd,
      handleOnStart,
      isRepeat,
      parent,
      song,
      videos,
      showControls,
    },
    ref,
  ) => {
    return (
      <ReactPlayer
        key={song || videos[0]?.youtube_code}
        ref={ref}
        width="100%"
        height="100%"
        playing
        loop={isRepeat}
        onStart={() => handleOnStart(parent)}
        onBufferEnd={() => handleOnBufferEnd(parent)}
        controls={showControls}
        url={
          song
            ? `https://www.youtube.com/watch?v=${song}`
            : videos.map((v) => `https://www.youtube.com/watch?v=${v.youtube_code}`)
        }
      />
    );
  },
);

const PlayguinPlayer = () => {
  const [dialogRadio, setDialogRadio] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [videoAt, setVideoAt] = useState(0);
  const [song, setSong] = useState({});

  const floatingPlayerRef = useRef();
  const dialogPlayerRef = useRef();
  const radioRef = useRef();

  const { videos, songIndex, id } = useSelector((state) => state.player.playlist, shallowEqual);
  const radio = useSelector((state) => state.player.radio, shallowEqual);
  const recognizedSong = useSelector((state) => state.player.song, shallowEqual);
  const shuffleList = useSelector((state) => state.player.shuffleList, shallowEqual);

  const isRadioPlayer = !isEmpty(radio);
  const isSongPlayer = !isEmpty(recognizedSong);

  const [showFloatingPlayer, setShowFloatingPlayer] = useState(isSongPlayer || isRadioPlayer);
  const [dialogPlayer, setDialogPlayer] = useState(!isSongPlayer && !isRadioPlayer);

  const isShuffle = useSelector((state) => state.player.shuffle, shallowEqual);
  const isRepeat = useSelector((state) => state.player.repeat, shallowEqual);
  const isPlay = useSelector((state) => state.player.play, shallowEqual);

  const [isPlaying, setIsPlaying] = useState(isPlay);

  const videoList = isShuffle ? shuffleList : videos;

  const dispatch = useDispatch();
  const clearSelectedPlaylist = () => dispatch(clearPlayerPlaylist());
  const clearRadio = () => dispatch(clearPlayerRadio());
  const clearSong = () => dispatch(clearPlayerSong());
  const setShuffle = () => dispatch(setPlaylistShuffle());
  const setRepeat = () => dispatch(setPlaylistRepeat());
  const setPlay = () => dispatch(setPlaylistPlay());
  const updateSongIndexStatus = () => dispatch(updateSongIndex());
  const clearShuffleArray = () => dispatch(clearShuffleList());
  const setShuffleList = (payload) => dispatch(createShuffleList(payload));
  const setRadioPlayer = (payload) => dispatch(fetchRadioWithRelatedStart(payload));
  const setCurrVideo = (payload) => dispatch(setCurrentVideo(payload));

  const findShuffledIndex = (value) => {
    return shuffleList.findIndex((x) => x.youtube_code === value);
  };

  const handleDialogOnClose = () => {
    setShowFloatingPlayer(true);
    setSeconds(dialogPlayerRef.current.getInternalPlayer().getCurrentTime());
    setVideoAt(dialogPlayerRef.current.getInternalPlayer().playerInfo.playlistIndex);
    setDialogPlayer(false);
  };

  const handleDialogOnPrev = () => dialogPlayerRef.current.getInternalPlayer().previousVideo();
  const handleDialogOnNext = () => dialogPlayerRef.current.getInternalPlayer().nextVideo();
  // const handleDialogOnPlay = () => {
  //   setIsPlaying(!isPlaying);
  //   if (dialogPlayerRef.current.getInternalPlayer().playerInfo.playerState === 1)
  //     dialogPlayerRef.current.getInternalPlayer().pauseVideo();
  //   else if (dialogPlayerRef.current.getInternalPlayer().playerInfo.playerState === 2)
  //     dialogPlayerRef.current.getInternalPlayer().playVideo();
  //   else if (dialogPlayerRef.current.getInternalPlayer().playerInfo.playerState === -1)
  //     dialogPlayerRef.current.getInternalPlayer().playVideo();
  // };

  const handleDialogRadioOnplay = () => {
    if (isPlaying) {
      radioRef.current.pause();
    } else {
      radioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  // const handleOnEnded = () => {
  //   if (!isRadioPlayer && !isSongPlayer) {
  //     setPlayShuffle(isShuffle);
  //     if (!isShuffle) {
  //       clearShuffleArray();
  //     }
  //   }
  // };

  const handeOnClose = () => {
    if (!isRadioPlayer) {
      clearSelectedPlaylist();
      if (isPlay) setPlay();
      if (isRepeat) setRepeat();
      if (isShuffle) {
        setShuffle();
        clearShuffleArray();
      }
    } else if (isSongPlayer) {
      clearSong();
      if (isPlay) setPlay();
    } else {
      clearRadio();
      if (isPlay) setPlay();
    }
  };

  const handleOnStart = (parent) => {
    if (parent === 'dialogPlayer') {
      dialogPlayerRef.current.getInternalPlayer().playVideoAt(videoAt);
      if (!isPlaying) dialogPlayerRef.current.getInternalPlayer().pauseVideo();
      setTimeout(() => {
        if (
          dialogPlayerRef &&
          dialogPlayerRef.current &&
          dialogPlayerRef.current.getInternalPlayer()
        )
          dialogPlayerRef.current.getInternalPlayer().seekTo(seconds);
      }, 900);
      // }
    } else if (parent === 'floatingPlayer' && !isSongPlayer) {
      floatingPlayerRef.current.getInternalPlayer().playVideoAt(videoAt);
      if (!isPlaying) floatingPlayerRef.current.getInternalPlayer().pauseVideo();
      setTimeout(() => {
        if (
          floatingPlayerRef &&
          floatingPlayerRef.current &&
          floatingPlayerRef.current.getInternalPlayer()
        )
          floatingPlayerRef.current.getInternalPlayer().seekTo(seconds);
      }, 900);
    }
  };

  const handleOnBufferEnd = (parent) => {
    if (parent === 'dialogPlayer') {
      const {
        title: t,
        author,
        video_id: videoId,
      } = dialogPlayerRef.current.getInternalPlayer().playerInfo.videoData;
      setSong({ title: t, author, videoId });
    } else if (parent === 'floatingPlayer') {
      const {
        title: t,
        author,
        video_id: videoId,
      } = floatingPlayerRef.current.getInternalPlayer().playerInfo.videoData;
      setSong({ title: t, author, videoId });
    }
  };

  const shuffleArray = (array) => {
    // eslint-disable-next-line no-plusplus
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      // eslint-disable-next-line no-param-reassign
      [array[i], array[j]] = [array[j], array[i]];
    }
    return [...array];
  };

  const onShuffle = (value) => {
    setShuffle();
    if (!value) {
      const newArray = shuffleArray([...videos]);
      setShuffleList(newArray);
    }
  };

  const setMediaSession = () => {
    if (!('mediaSession' in navigator)) {
      return;
    }
    navigator.mediaSession.metadata = new window.MediaMetadata({
      title: radio.title,
      artist: radio.frequency,
      album: radio.gender.join(', '),
      // artwork: radio.gender,
    });
  }

  const onSongClick = (value) => {
    const findex =
      isShuffle ? findShuffledIndex(videos[value].youtube_code) : value;
    setTimeout(() => {
      if (dialogPlayerRef && dialogPlayerRef.current && dialogPlayerRef.current.getInternalPlayer())
        dialogPlayerRef.current.getInternalPlayer().playVideoAt(findex);
    }, 900);
  };

  useEffect(() => {
    if (isPlaying && isRadioPlayer) {
      radioRef.current.play();
      setMediaSession()
    }
  }, [radio, isPlaying, isRadioPlayer]);

  useEffect(() => {
    if (!isSongPlayer && !isRadioPlayer) setCurrVideo(song.videoId);
  }, [song]);

  useEffect(() => {
    if (id) {
      setSeconds(0);
      setVideoAt(songIndex?.index || 0);
    }
  }, [id]);

  useEffect(() => {
    if (shuffleList) {
      setSeconds(0);
    }
  }, [shuffleList]);

  useEffect(() => {
    if (dialogPlayer) {
      setShowFloatingPlayer(false);
    }
  }, [dialogPlayer]);

  useEffect(() => {
    if (songIndex && !songIndex.played) {
      if (dialogPlayer) {
        setTimeout(() => {
          if (
            dialogPlayerRef &&
            dialogPlayerRef.current &&
            dialogPlayerRef.current.getInternalPlayer() &&
            dialogPlayerRef.current.getInternalPlayer().playVideoAt
          ) {
            dialogPlayerRef.current.getInternalPlayer().playVideoAt(songIndex.index);
            updateSongIndexStatus();
          }
        }, 100);
      } else if (showFloatingPlayer) {
        setTimeout(() => {
          if (
            floatingPlayerRef &&
            floatingPlayerRef.current &&
            floatingPlayerRef.current.getInternalPlayer
          ) {
            floatingPlayerRef.current?.getInternalPlayer()?.playVideoAt(songIndex.index);
            updateSongIndexStatus();
          }
        }, 1500);
      }
    }
  }, [floatingPlayerRef.current, dialogPlayerRef.current, songIndex]);

  return (
    <>
      {isRadioPlayer &&
        radio.stream_link.substring(radio.stream_link.lastIndexOf('/') + 1).split('.')[1] !==
        'm3u8' && <audio id="audio_element" ref={radioRef} src={radio.stream_link} />}
      {isRadioPlayer &&
        radio.stream_link.substring(radio.stream_link.lastIndexOf('/') + 1).split('.')[1] ===
        'm3u8' && <ReactHlsPlayer playerRef={radioRef} url={radio.stream_link} />}
      {showFloatingPlayer && (
        <FloatingPlayer
          radio={radio}
          open={showFloatingPlayer}
          song={song}
          isRepeat={isRepeat}
          isPlaying={isPlaying}
          mainAction={() => {
            if (!isSongPlayer) {
              if (!isRadioPlayer) {
                // console.log(floatingPlayerRef.current.getInternalPlayer());
                setSeconds(floatingPlayerRef.current.getInternalPlayer().getCurrentTime());
                setVideoAt(floatingPlayerRef.current.getInternalPlayer().playerInfo.playlistIndex);
                setDialogPlayer(true);
                setShowFloatingPlayer(false);
              } else {
                setRadioPlayer(radio.id);
                setDialogRadio(true);
              }
            }
          }}
          playAction={() => {
            if (!isRadioPlayer) {
              floatingPlayerRef.current.getInternalPlayer().playVideo();
            } else {
              radioRef.current.play();
            }
            setIsPlaying(!isPlaying);
          }}
          pauseAction={() => {
            if (!isRadioPlayer) {
              floatingPlayerRef.current.getInternalPlayer().pauseVideo();
            } else {
              radioRef.current.pause();
            }
            setIsPlaying(!isPlaying);
          }}
          closeAction={handeOnClose}
        >
          {isRadioPlayer ? null : (
            <YoutubePlayer
              parent="floatingPlayer"
              videos={videoList}
              song={recognizedSong.code}
              handleOnBufferEnd={handleOnBufferEnd}
              handleOnStart={handleOnStart}
              ref={floatingPlayerRef}
            />
          )}
        </FloatingPlayer>
      )}
      {!isRadioPlayer && !isSongPlayer && (
        <DialogPlayer
          onShuffle={onShuffle}
          onSongClick={onSongClick}
          songTitle={song.title}
          isPlaying={isPlaying}
          videos={videos}
          handleDialogOnPrev={handleDialogOnPrev}
          handleDialogOnNext={handleDialogOnNext}
          handleDialogOnClose={handleDialogOnClose}
          setOpen={setDialogPlayer}
          open={dialogPlayer}
        >
          <YoutubePlayer
            parent="dialogPlayer"
            isRepeat={isRepeat}
            showControls
            videos={videoList}
            handleOnBufferEnd={handleOnBufferEnd}
            handleOnStart={handleOnStart}
            ref={dialogPlayerRef}
          />
        </DialogPlayer>
      )}
      {isRadioPlayer && (
        <DialogRadio
          isPlaying={isPlaying}
          handleDialogRadioOnplay={handleDialogRadioOnplay}
          radio={radio}
          open={dialogRadio}
          setOpen={setDialogRadio}
        />
      )}
    </>
  );
};

export default PlayguinPlayer;
