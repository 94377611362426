// @flow
import React from 'react';
import {
  Paper,
  IconButton,
  Container,
  Grid,
  Avatar,
  ButtonBase,
  Typography,
  SvgIcon,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import theme from 'AppTheme';
import { ReactComponent as search } from '../../assets/icons/ic_search.svg';
import { ReactComponent as close } from '../../assets/icons/ic_close.svg';

const useStyles = makeStyles({
  paper: {
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(5),
    marginBottom: -theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    background: `linear-gradient(45deg, ${theme.palette.primary.light} %0, ${theme.palette.primary.main} %50, ${theme.palette.primary.dark} %100)`,
  },
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.common.white,
  },
});

const Header = ({ title, avatar, avatarAction, isSearching, hasSearch, searchAction }) => {
  const styles = useStyles();
  return (
    <Paper elevation={0} square className={styles.paper}>
      <Container>
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <Typography variant="h4">{title}</Typography>
          </Grid>
          {avatar && (
            <Grid item>
              <ButtonBase onClick={() => avatarAction()}>
                <Avatar src={avatar} className={styles.avatar} />
              </ButtonBase>
            </Grid>
          )}
          {hasSearch && (
            <Grid item>
              <IconButton onClick={() => searchAction()}>
                {isSearching ? (
                  <SvgIcon component={close} viewBox="0 0 24 24" />
                ) : (
                  <SvgIcon component={search} viewBox="0 0 24 24" />
                )}
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Container>
    </Paper>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  avatarAction: PropTypes.func,
  isSearching: PropTypes.bool,
  hasSearch: PropTypes.bool,
  searchAction: PropTypes.func,
};

Header.defaultProps = {
  avatar: null,
  avatarAction: () => {},
  searchAction: () => {},
  hasSearch: false,
  isSearching: false,
};

export default Header;
