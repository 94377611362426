import { FavoriteTracksActionTypes } from 'actions/favoriteTracksActions';

const INITIAL_STATE = {
  list: [],
  filter: 'name',
  order: 'asc',
  lastVisible: null,
  limit: 10,
  hasMore: false,
  isFetching: false,
  errorMessage: undefined,
};

const favoriteTracksReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FavoriteTracksActionTypes.FETCH_FAVORITE_TRACKS_START:
      return {
        ...state,
        isFetching: !state.lastVisible,
      };
    case FavoriteTracksActionTypes.FETCH_FAVORITE_TRACKS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        hasMore: action.payload.length === state.limit,
        list: [...state.list, ...action.payload],
      };
    case FavoriteTracksActionTypes.FETCH_FAVORITE_TRACKS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case FavoriteTracksActionTypes.ADD_FAVORITE_TRACK_SUCCESS:
      return {
        ...state,
        list: [...state.list, action.payload],
      };

    case FavoriteTracksActionTypes.ADD_FAVORITE_TRACK_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case FavoriteTracksActionTypes.REMOVE_FAVORITE_TRACK:
      return {
        ...state,
        list: state.list.filter((t) => t.id !== action.payload),
      };
    case FavoriteTracksActionTypes.SET_TRACK_LAST_VISIBLE_ITEM:
      return {
        ...state,
        lastVisible: action.payload,
      };
    case FavoriteTracksActionTypes.SET_ORDER_TRACKS:
      return {
        ...state,
        order: action.payload,
        list: [],
        lastVisible: null,
        hasMore: false,
      };
    case FavoriteTracksActionTypes.SET_FILTER_TRACKS:
      return {
        ...state,
        filter: action.payload,
        list: [],
        lastVisible: null,
        hasMore: false,
      };
    case FavoriteTracksActionTypes.CLEAR_TRACK_LIST:
      return {
        ...state,
        list: [],
        lastVisible: null,
        hasMore: false,
      };
    default:
      return state;
  }
};

export default favoriteTracksReducer;
