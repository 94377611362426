import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { fetchRadioCountriesStart } from 'actions/countriesActions';
import PropTypes from 'prop-types';
import { AppBar, makeStyles, Toolbar } from '@material-ui/core';
import PaperSearch from '../components/papers/PaperSearch';
import PaperListCountries from '../components/papers/PaperListCountries';
import DialogDrawer from '../components/dialogs/DialogDrawer';
import Preloader from '../components/partials/Preloader';
import theme from '../AppTheme';

const ListCountriesWithPreloader = Preloader(PaperListCountries);

const useStyles = makeStyles({
  appBar: {
    backgroundColor: '#081523',
  },
  toolBar: {
    paddingBottom: theme.spacing(3),
    justifyContent: 'space-between',
  },
});

const DialogCountries = ({ radioCountries, open, setOpen }) => {
  const styles = useStyles();
  const [searchValue, setSearchValue] = useState('');

  const isLoading = useSelector((state) => state.countries.isFetching, shallowEqual);

  const dispatch = useDispatch();
  const fetchRadioCountries = () => dispatch(fetchRadioCountriesStart());

  useEffect(() => {
    if (open && !radioCountries.length) fetchRadioCountries();
  }, [open, fetchRadioCountries]);

  const handleSearchValue = useCallback((value) => {
    setSearchValue(value);
  }, []);

  return (
    <DialogDrawer title="Countries" open={open} setOpen={setOpen}>
      <AppBar className={styles.appBar} color="transparent" elevation={0} position="sticky">
        <Toolbar className={styles.toolBar}>
          <PaperSearch keyPressEnabled={false} value={searchValue} onValueChange={handleSearchValue} />
        </Toolbar>
      </AppBar>

      <ListCountriesWithPreloader
        isLoading={isLoading}
        searchValue={searchValue}
        countries={radioCountries}
      />
    </DialogDrawer>
  );
};

DialogCountries.propTypes = {
  radioCountries: PropTypes.instanceOf(Array).isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default DialogCountries;
