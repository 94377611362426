import { RadioActionTypes } from 'actions/radioActions';
import { CountriesActionTypes } from 'actions/countriesActions';
import { GenresActionTypes } from 'actions/genresActions';
import { UserActionTypes } from 'actions/userActions';

const INITIAL_STATE = {
  list: null,
  listByTitle: [],
  favoriteRadios: [],
  limit: 100,
  hasMore: false,
  isFetching: false,
  isFetchingBytitle: false,
  errorMessage: undefined,
  lastVisible: null,
  activeTab: 0,
};

const radioReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RadioActionTypes.FETCH_RADIOS_START:
      return {
        ...state,
        isFetching: !state.lastVisible,
      };
    case RadioActionTypes.FETCH_RADIOS_SUCCESS:
      return {
        ...state,
        hasMore: action.payload.length === state.limit,
        isFetching: false,
        list: !state.list ? action.payload : [...state.list, ...action.payload],
        limit: 10,
      };
    case RadioActionTypes.FETCH_RADIOS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case RadioActionTypes.FILTER_RADIOS_BY_TITLE_START:
      return {
        ...state,
        isFetchingBytitle: true,
      };
    case RadioActionTypes.FILTER_RADIOS_BY_TITLE_SUCCESS:
      return {
        ...state,
        isFetchingBytitle: false,
        listByTitle: [...state.listByTitle, ...action.payload],
      };
    case RadioActionTypes.FILTER_RADIOS_BY_TITLE_FAILURE:
    case RadioActionTypes.FETCH_RADIO_WITH_RELATED_FAILURE:
      return {
        ...state,
        isFetchingBytitle: false,
        errorMessage: action.payload,
      };
    case RadioActionTypes.SET_LAST_VISIBLE_ITEM:
      return {
        ...state,
        lastVisible: action.payload,
      };
    case RadioActionTypes.SET_ACTIVE_TAB_RADIO:
      return {
        ...state,
        activeTab: action.payload,
      };
    case RadioActionTypes.SET_LAST_VISIBLE_BY_TITLE_ITEM:
      return {
        ...state,
        lastVisibleByTitle: action.payload,
      };
    case CountriesActionTypes.SET_RADIO_FILTER_COUNTRY:
      return {
        ...state,
        lastVisible: null,
        limit: 100,
        list: null,
      };
    case GenresActionTypes.SET_RADIO_FILTER_GENRE:
      return {
        ...state,
        lastVisible: null,
        limit: 100,
        list: null,
      };
    case UserActionTypes.REMOVE_FAVORITE_RADIO:
      return {
        ...state,
        favoriteRadios: state.favoriteRadios.filter((r) => r.id !== action.payload),
        activeTab: state.favoriteRadios.length === 1 ? 0 : state.activeTab,
      };
    case UserActionTypes.ADD_FAVORITE_RADIO_SUCCESS_LIST:
      return {
        ...state,
        favoriteRadios: [...state.favoriteRadios, action.payload],
      };
    case RadioActionTypes.FETCH_USER_RADIOS:
      return {
        ...state,
        favoriteRadios: action.payload,
      };
    case RadioActionTypes.CLEAR_LIST_BY_TITLE:
      return {
        ...state,
        listByTitle: [],
        lastVisibleByTitle: null,
      };
    case RadioActionTypes.CLEAR_RADIO_LIST:
      return {
        ...state,
        list: null,
        listByTitle: [],
        favoriteRadios: [],
        limit: 100,
        hasMore: false,
        isFetching: false,
        isFetchingBytitle: false,
        errorMessage: undefined,
        lastVisible: null,
        activeTab: 0,
      };
    default:
      return state;
  }
};

export default radioReducer;
