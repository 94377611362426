import { HeaderActionTypes } from 'actions/headerActions';

const INITIAL_STATE = {
  drawerAccount: false,
  showRadioSearch: false,
  showLibrarySearch: false,
  showRename: false,
  showAddTracks: false,
};

const playlistsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HeaderActionTypes.SET_DRAWER_ACCOUNT:
      return {
        ...state,
        drawerAccount: action.payload,
      };
    case HeaderActionTypes.SET_SHOW_SEARCH_RADIO:
      return {
        ...state,
        showRadioSearch: action.payload,
      };
    case HeaderActionTypes.SET_SHOW_SEARCH_LIBRARY:
      return {
        ...state,
        showLibrarySearch: action.payload,
      };
    case HeaderActionTypes.SET_SHOW_RENAME:
      return {
        ...state,
        showRename: action.payload,
      };
    case HeaderActionTypes.SET_SHOW_ADD_TRACKS:
      return {
        ...state,
        showAddTracks: action.payload,
      };
    default:
      return state;
  }
};

export default playlistsReducer;
