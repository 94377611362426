import React from 'react';
import { useDispatch } from 'react-redux';
import { setGenreActive } from 'actions/genresActions';
import { ListItem, ListItemText, ListItemSecondaryAction, Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';

const ListItemGenre = ({ genre }) => {
  const dispatch = useDispatch();
  const setGenre = (payload) => dispatch(setGenreActive(payload));

  const handleOnchange = () => {
    setGenre(genre.name);
  };
  return (
    <ListItem button divider onClick={handleOnchange}>
      <ListItemText primary={genre.name} />
      <ListItemSecondaryAction>
        <Checkbox edge="end" color={'primary'} onChange={handleOnchange} checked={genre.selected} />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

ListItemGenre.propTypes = {
  genre: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    selected: PropTypes.bool,
  }).isRequired,
};

export default ListItemGenre;
