/* eslint-disable consistent-return */
import axios from 'axios';
import { handleErrorResponse } from './ErrorHandling';
import { auth } from '../firebase/firebase.utils';

class AudioRecognitionService {
  fetchAudioRecognition = async ({ file }) => {
    const token = await auth.currentUser.getIdToken();
    const formData = new FormData();
    formData.append('file', file);
    try {
      const { data } = await axios({
        method: 'post',
        url: `https://us-central1-firebase-284610.cloudfunctions.net/playguinys/recognition/${auth.currentUser.uid}`,
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
        data: formData,
      });
      return data;
    } catch (err) {
      console.log('Error in fetch playlist service:', err);
      handleErrorResponse(err, 'Something went wrong! Playlist does not exists.');
    }
  };

  fetchRadioRecognition = async ({ streamUrl }) => {
    const token = await auth.currentUser.getIdToken();
    const reqData = { streamUrl };
    try {
      const { data } = await axios({
        method: 'post',
        url: `https://us-central1-firebase-284610.cloudfunctions.net/playguinys/radiorecognition/${auth.currentUser.uid}`,
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: reqData,
      });
      return data;
    } catch (err) {
      console.log('Error in fetch playlist service:', err);
      handleErrorResponse(err, 'Something went wrong! Playlist does not exists.');
    }
  };
}

const inst = new AudioRecognitionService();
export default inst;
