import { UserActionTypes } from 'actions/userActions';
import storage from 'redux-persist/lib/storage';

const INITIAL_STATE = {
  currentUser: null,
  radios: [],
  playlists: [],
  error: null,
  isFetching: true,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.CHECK_USER_SESSION_END:
      return {
        ...state,
        isFetching: false,
      };
    case UserActionTypes.SIGN_IN_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { radios, playlists, ...rest } = action.payload;
      return {
        ...state,
        currentUser: rest,
        radios: radios || [],
        playlists: playlists || [],
        isFetching: false,
        error: null,
      };
    case UserActionTypes.SIGN_OUT_SUCCESS: {
      storage.removeItem('persist:root');
      return {
        ...state,
        currentUser: null,
        isFetching: false,
        radios: [],
        playlists: [],
        error: null,
      };
    }
    case UserActionTypes.ADD_FAVORITE_RADIO_SUCCESS:
      return {
        ...state,
        radios: [...action.payload],
      };
    case UserActionTypes.REMOVE_FAVORITE_RADIO:
      return {
        ...state,
        radios: state.radios.filter((r) => r.id !== action.payload),
      };
    case UserActionTypes.ADD_FAVORITE_PLAYLIST_FROM_CATEGORY:
      return {
        ...state,
        playlists: [...state.playlists, action.payload],
      };
    case UserActionTypes.REMOVE_FAVORITE_PLAYLIST_FROM_CATEGORY:
      return {
        ...state,
        playlists: state.playlists.filter((p) => p.id !== action.payload),
      };
    case UserActionTypes.SIGN_IN_FAILURE:
    case UserActionTypes.SIGN_OUT_FAILURE:
    case UserActionTypes.SIGN_UP_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case UserActionTypes.ADD_USER_AVATAR:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          avatar: action.payload,
        },
      };

    default:
      return state;
  }
};

export default userReducer;
