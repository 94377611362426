import { createMuiTheme, fade } from '@material-ui/core';
import LatoLight from 'assets/fonts/Lato-Light.ttf';
import LatoRegular from 'assets/fonts/Lato-Regular.ttf';
import LatoBold from 'assets/fonts/Lato-Bold.ttf';
import LatoItalic from 'assets/fonts/Lato-Italic.ttf';

const latoLight = {
  fontFamily: 'Lato',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `url(${LatoLight}) format('ttf')`,
};
const latoItalic = {
  fontFamily: 'Lato',
  fontDisplay: 'swap',
  fontWeight: 400,
  fontStyle: 'italic',
  src: `url(${LatoItalic}) format('ttf')`,
};
const latoRegular = {
  fontFamily: 'Lato',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url(${LatoRegular}) format('ttf')`,
};
const latoBold = {
  fontFamily: 'Lato',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `url(${LatoBold}) format('ttf')`,
};
const fontFamilies = ['Lato', 'Arial', 'sans-serif'].join(',');
const palette = {
  common: {
    black: '#000',
    white: '#fff',
  },
  background: {
    paper: '#142F42',
    default: '#09192A',
  },
  primary: {
    light: '#EFD65E',
    main: '#FFCE00',
    dark: '#F3BC04',
    contrastText: '#fff',
  },
  secondary: {
    main: '#8431A5',
    contrastText: '#fff',
  },
  error: {
    main: '#E30303',
    contrastText: '#fff',
  },
  success: {
    main: '#009900',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  info: {
    main: '#4086F5',
    contrastText: '#fff',
  },
  warning: {
    light: '#ffb74d',
    main: '#eb9700',
    dark: '#f57c00',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  grey: {
    50: '#fafafa',
    100: '#f4f4f4',
    200: '#eeeeee',
    300: '#ebebeb',
    400: '#cccccc',
    500: '#999999',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#333333',
    A700: '#666666',
  },
  text: {
    primary: '#fff',
    secondary: fade('#fff', 0.8),
    disabled: 'rgba(51, 51, 51, 0.38)',
    hint: 'rgba(51, 51, 51, 0.38)',
  },
};
const theme = createMuiTheme({
  palette,
  shape: {
    borderRadius: 6,
  },
  overrides: {
    MuiCssBaseline: {},
    MuiCard: {},
    MuiCardMedia: {},
    MuiCardContent: {},
    MuiIconButton: {},
    MuiButton: {},
    MuiButtonGroup: {},
    MuiButtonBase: {},
    MuiInputBase: {},
    MuiInputLabel: {},
    MuiOutlinedInput: {},
    MuiPaper: {},
    MuiDialog: {},
    MuiDialogTitle: {},
    MuiDialogContent: {},
    MuiDialogActions: {},
    MuiTab: {},
    MuiTabs: {},
    MuiDivider: {},
    MuiChip: {},
    MuiMenu: {},
    MuiMenuItem: {},
    MuiListItem: {},
    MuiListItemIcon: {},
    MuiListItemText: {},
    MuiListItemAvatar: {},
    MuiSvgIcon: {},
    MuiStepper: {},
    MuiStepIcon: {},
    MuiStepLabel: {},
    MuiStepConnector: {},
    MuiTypography: {},
    MuiCheckbox: {},
    MuiExpansionPanelSummary: {},
    MuiCardActionArea: {},
    MuiPopover: {},
    MuiFormLabel: {},
    MuiFilledInput: {},
    MuiAlert: {},
    MuiTouchRipple: {},
    MuiSwitch: {},
    MuiBackdrop: {},
  },
});

theme.overrides.MuiCssBaseline['@global'] = {
  '@global': {
    '@font-face': [latoLight, latoRegular, latoItalic, latoBold],
  },
  body: {
    fontFamily: fontFamilies,
    backgroundColor: '#142F42',
  },
};
theme.overrides.MuiTypography.h1 = {
  fontFamily: fontFamilies,
};
theme.overrides.MuiTypography.h2 = {
  fontFamily: fontFamilies,
};
theme.overrides.MuiTypography.h3 = {
  fontFamily: fontFamilies,
};
theme.overrides.MuiTypography.h4 = {
  fontFamily: fontFamilies,
};
theme.overrides.MuiTypography.h5 = {
  fontFamily: fontFamilies,
};
theme.overrides.MuiTypography.h6 = {
  fontFamily: fontFamilies,
};
theme.overrides.MuiTypography.body1 = {
  fontFamily: fontFamilies,
};
theme.overrides.MuiTypography.body2 = {
  fontFamily: fontFamilies,
};
theme.overrides.MuiTypography.caption = {
  fontFamily: fontFamilies,
};
theme.overrides.MuiTypography.button = {
  fontFamily: fontFamilies,
};
theme.overrides.MuiButton.iconSizeMedium = {
  '& > *:first-child': {
    fontSize: 24,
  },
};
theme.overrides.MuiButton.root = {
  fontFamily: fontFamilies,
};
theme.overrides.MuiButton.textSizeSmall = {
  fontSize: '0.875rem',
};
theme.overrides.MuiButton.outlinedSizeLarge = {
  // paddingTop: 10,
  // paddingBottom: 10,
};
theme.overrides.MuiTabs.root = {
  width: '100%',
};
theme.overrides.MuiTab.textColorPrimary = {
  color: theme.palette.text.secondary,
};
theme.overrides.MuiListItem.root = {
  '&.Mui-selected': {
    backgroundColor: theme.palette.background.default,
    '&:hover': {
      backgroundColor: theme.palette.background.default
    },
  },
};
theme.overrides.MuiListItem.button = {
  '&.Mui-selected': {
    backgroundColor: theme.palette.background.default,
    '&:hover': {
      backgroundColor: theme.palette.background.default
    },
  },
};
theme.overrides.MuiListItem.divider = {
  borderBottom: '1px solid #374F5F',
  '&:first-child': {
    borderTop: '1px solid #374F5F',
  },
};
theme.overrides.MuiListItemText.root = {
  '& .MuiTypography-displayBlock': {
    whiteSpace: 'normal',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
  },
};
theme.overrides.MuiListItemText.multiline = {
  '& .MuiTypography-displayBlock': {
    // whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
  },
};
theme.overrides.MuiListItemAvatar.root = {
  minWidth: theme.spacing(8),
};
theme.overrides.MuiChip.root = {
  backgroundColor: theme.palette.background.default,
  color: theme.palette.common.white,
};
theme.overrides.MuiChip.deletable = {
  '&:focus': {
    backgroundColor: undefined,
  },
};
theme.overrides.MuiChip.deleteIcon = {
  color: theme.palette.common.white,
  '&:hover': {
    color: theme.palette.primary.main,
  },
};
theme.overrides.MuiCard.root = {};
theme.overrides.MuiMenuItem.root = {
  color: theme.palette.primary.main,
  fontWeight: 700,
  fontSize: '0.875rem',
};
theme.overrides.MuiPopover.paper = {
  backgroundColor: theme.palette.background.default,
};
theme.overrides.MuiBackdrop.root = {
  backgroundColor: fade(theme.palette.common.black, 0.8),
};
theme.overrides.MuiInputBase.root = {
  backgroundColor: theme.palette.common.white,
};

theme.overrides.MuiIconButton.root = {
  color: theme.palette.common.white,
};
theme.overrides.MuiDialogContent.root = {
  "&:first-child": {
    paddingTop: undefined,
  },
};

export default theme;
