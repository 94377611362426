import React from 'react';
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  Typography,
  makeStyles,
  SvgIcon,
} from '@material-ui/core';
import { YouTube } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { ReactComponent as play } from '../../assets/icons/play.svg';
import { ReactComponent as playguin } from '../../assets/icons/recognition.svg';
import { ReactComponent as user } from '../../assets/icons/ic_user.svg';
import recognizedImg from '../../assets/images/recognized_tracks.png';
import theme from '../../AppTheme';

const useStyles = makeStyles({
  card: {
    backgroundColor: 'transparent',
  },
  cardContent: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: 0,
    paddingRight: 0,
  },
  cardMedia: {
    paddingTop: '100%',
    borderRadius: 6,
    position: 'relative',
  },
  icon: {
    position: 'absolute',
    left: '1px',
    top: '1px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '100%',
    padding: theme.spacing(0.5),
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const CardPlaylist = ({
  title,
  songs,
  image,
  action,
  playlistType,
  createdByUser,
  isForRecognition,
}) => {
  const styles = useStyles();

  const findIcon = () => {
    if (playlistType === 'youtube_playlist') return YouTube;
    if (playlistType === 'playguin_playlist') return playguin;
    if (createdByUser) return user;
    return YouTube;
  };
  return (
    <Card elevation={0} square className={styles.card}>
      <CardActionArea onClick={() => action()}>
        <CardMedia image={isForRecognition ? recognizedImg : image} className={styles.cardMedia}>
          {!isForRecognition && (<Box className={styles.icon}>
            <SvgIcon component={findIcon()} viewBox="0 0 24 24" color="inherit" />
          </Box>)}
        </CardMedia>
        <CardContent className={styles.cardContent}>
          <Typography color="textPrimary">{title}</Typography>
          {/* <Typography color="textSecondary">{songs}</Typography> */}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

CardPlaylist.propTypes = {
  title: PropTypes.string.isRequired,
  songs: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  playlistType: PropTypes.string,
  createdByUser: PropTypes.bool,
  isForRecognition: PropTypes.bool,
};

CardPlaylist.defaultProps = {
  playlistType: '',
  createdByUser: false,
  isForRecognition: false,
};

export default CardPlaylist;
