/* eslint-disable no-nested-ternary */
// @flow
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { clearPlaylist, setSelectedPlaylist } from 'actions/playlistsActions';
import { debounce } from 'lodash';
import {
  setPlayerPlaylist,
  setPlaylistShuffle,
  setPlaylistRepeat,
  setPlaylistPlay,
  clearShuffleList,
  createShuffleList,
} from 'actions/playerActions';
import { addFavoriteTrack } from 'actions/favoriteTracksActions';
import {
  addTrackToUserPlaylist,
  renameUserPlaylist,
  removeTrackFromUserPlaylist,
  deleteUserPlaylist,
} from 'actions/favoritePlaylistsActions';
import { setShowRename, setShowAddTracks } from 'actions/headerActions';
import PaperListSongs from 'components/papers/PaperListSongs';
import TracksService from 'services/TrackService';
import { AppBar, Box, Toolbar } from '@material-ui/core';
import PlaylistHeading from '../../components/partials/PlaylistHeading';
import song from '../../assets/images/song.png';
import playlist from '../../assets/images/playlist.png';
import PaperListSongsSwiper from '../../components/papers/PaperListSongsSwiper';
import DialogDrawer from '../../components/dialogs/DialogDrawer';
import PaperSearch from '../../components/papers/PaperSearch';
import PaperListPlaylists from '../../components/papers/PaperListPlaylists';
import PaperPlaylistControl from '../../components/papers/PaperPlaylistControl';
import DialogPlaylistCreate from '../../dialogs/DialogPlaylistCreate';
import DialogPlaylistNew from '../../dialogs/DialogPlaylistNew';

function Playlist() {
  const history = useHistory();
  const { listId } = useParams();
  const [dialogPlaylistAdd, setDialogPlaylistAdd] = useState(false);
  const [dialogPlaylistCreate, setDialogPlaylistCreate] = useState(false);
  const [valueSearchPlaylistSongs, setValueSearchPlaylistSongs] = useState('');
  const [valueSearchPlaylists, setValueSearchPlaylists] = useState('');
  const [playlistName, setPlaylistName] = useState('');
  const [selectedSong, setSelectedSong] = useState(null);
  const [showAddToMyTracks, setShowAddToMyTracks] = useState(false);

  const selectedPlaylist = useSelector((state) => state.playlists.current, shallowEqual);
  const userPlaylists = useSelector((state) => state.userPlaylists.list, shallowEqual);
  const userTracks = useSelector((state) => state.tracks.list, shallowEqual);
  const isShuffle = useSelector((state) => state.player.shuffle, shallowEqual);
  const isRepeat = useSelector((state) => state.player.repeat, shallowEqual);
  const isPlay = useSelector((state) => state.player.play, shallowEqual);
  const playingPlaylist = useSelector((state) => state.player.playlist, shallowEqual);
  const shuffleList = useSelector((state) => state.player.shuffleList, shallowEqual);
  const showRename = useSelector((state) => state.header.showRename, shallowEqual);
  const showAddTracks = useSelector((state) => state.header.showAddTracks, shallowEqual);

  const dispatch = useDispatch();
  const clearSelectedPlaylist = () => dispatch(clearPlaylist());
  const setShuffle = () => dispatch(setPlaylistShuffle());
  const setRepeat = () => dispatch(setPlaylistRepeat());
  const setPlay = () => dispatch(setPlaylistPlay());
  const setYoutubePlayerPlaylist = (payload) => dispatch(setPlayerPlaylist(payload));
  const addFavoriteSong = (payload) => dispatch(addFavoriteTrack(payload));
  const addSongToUserPlaylist = (payload) => dispatch(addTrackToUserPlaylist(payload));
  const removeSongFromUserPlaylist = (payload) => dispatch(removeTrackFromUserPlaylist(payload));
  const setPlaylistInfo = (payload) => dispatch(setSelectedPlaylist(payload));
  const removeUserPlaylist = (payload) => dispatch(deleteUserPlaylist(payload));
  const clearShuffleArray = () => dispatch(clearShuffleList());
  const setShuffleList = (payload) => dispatch(createShuffleList(payload));
  const setRename = (payload) => dispatch(setShowRename(payload));
  const renamePlaylist = (payload) => dispatch(renameUserPlaylist(payload));
  const setShowAddTrack = (payload) => dispatch(setShowAddTracks(payload));
  

  const trackExist =
    selectedSong && !userTracks.find((t) => t.youtube_code === selectedSong.youtube_code);

  const {
    videos = [],
    title,
    image,
    id,
    categoryId,
    playlistType,
    createdByUser,
    isForRecognition,
    gender,
    ...currentPlaylist
  } = selectedPlaylist;

  // const videoList = isShuffle && id === playingPlaylist?.id ? shuffleList : videos;
  const isPlaying = isPlay && id === playingPlaylist?.id;
  const isRepeating = isRepeat && id === playingPlaylist?.id;
  const isShuffling = isShuffle && id === playingPlaylist?.id;

  const isFromLibrary = useLocation().search.includes('library');
  const isFromCategory = useLocation().search.includes('category');
  const catId = new URLSearchParams(useLocation().search).get('category');

  // const shuffleArray = (array) => {
  //   // eslint-disable-next-line no-plusplus
  //   for (let i = array.length - 1; i > 0; i--) {
  //     const j = Math.floor(Math.random() * (i + 1));
  //     // eslint-disable-next-line no-param-reassign
  //     [array[i], array[j]] = [array[j], array[i]];
  //   }
  //   return [...array];
  // };

  // const onShuffle = (value) => {
  //   if (!isPlay) setPlayShuffle(true);
  //   setShuffle();
  //   if (value) {
  //     if (!isPlay) clearShuffleArray();
  //   } else {
  //     const newArray = shuffleArray([...videos]);
  //     setShuffleList(newArray);
  //   }
  // };

  const findShuffledIndex = (value) => {
    return shuffleList.findIndex((x) => x.youtube_code === value);
  };

  const handleOpenDialogCreate = (value) => {
    setDialogPlaylistCreate(value);
  };

  const handleSearchValue = debounce((value) => {
    setValueSearchPlaylistSongs(value);
  }, 1000);

  useEffect(() => {
    if (listId) window.scrollTo(0, 0);
  }, [listId]);

  return (
    !!videos.length && (
      <Box pb={10}>
        <PlaylistHeading
          id={id}
          categoryId={categoryId}
          image={image}
          backAction={() => {
            clearSelectedPlaylist();
            if (isShuffling) setShuffle();
            if (isRepeating) setRepeat();
            history.push(
              isFromLibrary ? '/library' : isFromCategory ? `/featured/category/${catId}` : '/',
            );
          }}
          favoriteAction={(e) => console.log('favorite', e)}
        />
        <PaperPlaylistControl
          title={title}
          gender={gender}
          videoCount={videos.length}
          searchText={valueSearchPlaylistSongs}
          onSearchChange={handleSearchValue}
          playAction={() => {
            if (isShuffle && isPlay) {
              setShuffle();
              clearShuffleArray();
            }
            setYoutubePlayerPlaylist({
              videos,
              id,
              title,
              image,
              createdByUser,
              playlistType,
              ...currentPlaylist,
            });
            if (!isPlay) setPlay();
          }}
          showSearch={false}
          pauseAction={() => setPlay()}
          isPlay={isPlaying}
          isShuffle={isShuffling}
          isRepeat={isRepeating}
        />
        {createdByUser || isForRecognition ? (
          <PaperListSongsSwiper
            // searchValue={valueSearchPlaylistSongs}
            songs={videos}
            onSongClick={(index) => {
              const findex =
                isShuffling
                  ? findShuffledIndex(videos[index].youtube_code)
                  : index;
              setYoutubePlayerPlaylist({
                videos,
                id,
                songIndex: { index: findex, played: false },
                title,
                image,
                playlistType,
                createdByUser,
                ...currentPlaylist,
              });
              if (!isPlay) setPlay();
            }}
            playlistType={playlistType}
            addPlaylistAction={async (item) => {
              const data = await TracksService.checkIfTrackExists({ track: item.youtube_code });
              setShowAddToMyTracks(!data.isExistInUserTracks);
              setDialogPlaylistAdd(true);
              setSelectedSong(item);
            }}
            deletePlaylistAction={(code) => {
              removeSongFromUserPlaylist({
                id: selectedPlaylist.id,
                createdByUser: selectedPlaylist.createdByUser,
                title: selectedPlaylist.title,
                count: selectedPlaylist.count - 1,
                songs: selectedPlaylist.videos.filter((s) => s.youtube_code !== code),
              });
              setPlaylistInfo({
                ...selectedPlaylist,
                count: selectedPlaylist.count - 1,
                videos: selectedPlaylist.videos.filter((s) => s.youtube_code !== code),
              });
              if (isShuffling) setShuffleList(shuffleList.filter((s) => s.youtube_code !== code));
              if (selectedPlaylist.count === 1) {
                clearSelectedPlaylist();
                clearShuffleArray();
                removeUserPlaylist(selectedPlaylist.id);
                history.push('/library');
              }
            }}
          />
        ) : (
          <PaperListSongs
            onSongClick={(index) => {
              const findex =
                isShuffling
                  ? findShuffledIndex(videos[index].youtube_code)
                  : index;
              setYoutubePlayerPlaylist({
                videos,
                id,
                songIndex: { index: findex, played: false },
                title,
                image,
                playlistType,
                ...currentPlaylist,
              });
              if (!isPlay) setPlay();
            }}
            secondaryAction={async (item) => {
              const data = await TracksService.checkIfTrackExists({ track: item.youtube_code });
              setShowAddToMyTracks(!data.isExistInUserTracks);
              setDialogPlaylistAdd(true);
              setSelectedSong(item);
            }}
            songs={videos}
          />
        )}
        <DialogDrawer setOpen={setDialogPlaylistAdd} open={dialogPlaylistAdd} title="Playlists">
          <AppBar position="sticky" color="transparent">
            <Box pb={2}>
              <Toolbar>
                <PaperSearch value={valueSearchPlaylists} onValueChange={setValueSearchPlaylists} />
              </Toolbar>
            </Box>
          </AppBar>
          <PaperListPlaylists
            hasInfiniteScroll
            playlists={userPlaylists.filter(({ createdByUser: isCustom }) => isCustom)}
            selectedSong={selectedSong}
            searchValue={valueSearchPlaylists}
            primaryAction={() => { }}
            createAction={() => {
              // setDialogPlaylistAdd(false);
              setDialogPlaylistCreate(true);
            }}
            showAddToMyTracks={trackExist}
            addToMyTracksAction={
              showAddToMyTracks
                ? () => {
                  // setDialogPlaylistAdd(false);
                  addFavoriteSong(selectedSong);
                }
                : null
            }
            secondaryAction={(currPlaylist) => {
              if (currPlaylist.songs.every((s) => s.youtube_code !== selectedSong?.youtube_code)) {
                addSongToUserPlaylist({
                  ...currPlaylist,
                  count: currPlaylist.count + 1,
                  songs: [...currPlaylist.songs, selectedSong],
                });
                // setDialogPlaylistAdd(false);
              }
            }}
          />
        </DialogDrawer>
        {dialogPlaylistCreate && (
          <DialogPlaylistCreate
            open={dialogPlaylistCreate}
            setOpen={handleOpenDialogCreate}
            createPlaylistAction={(name) => {
              setPlaylistName(name);
              setDialogPlaylistCreate(false);
              setShowAddTrack(true);
            }}
          />
        )}
        {showRename && (
          <DialogPlaylistCreate
            open={showRename}
            setOpen={setRename}
            playlistName={title}
            createPlaylistAction={(name) => {
              renamePlaylist({ name, id });
            }}
          />
        )}
        {showAddTracks && (
          <DialogPlaylistNew
            open={showAddTracks}
            setOpen={setShowAddTrack}
            playlistName={playlistName}
            selectedSong={selectedSong}
          />
        )}
      </Box>
    )
  );
}

export default Playlist;
