import { SearchActionTypes } from 'actions/searchActions';

const CLEAR_ERROR = 'CLEAR_ERRORS';

const INITIAL_STATE = {
  isFetching: false,
  playlists: [],
  videos: [],
  all: [],
  page: 1,
  searchValue: '',
  radioSearchValue: '',
  librarySearchValue: '',
  errorMessage: undefined,
};

const searchReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SearchActionTypes.FETCH_YOUTUBE_START:
      return {
        ...state,
        isFetching: true,
      };
    case SearchActionTypes.FETCH_YOUTUBE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        playlists: action.payload.playlists,
        videos: action.payload.videos,
      };
    case SearchActionTypes.FETCH_YOUTUBE_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case SearchActionTypes.FETCH_YOUTUBE_ALL_START:
      return {
        ...state,
        isFetching: state.page === 1,
      };
    case SearchActionTypes.FETCH_YOUTUBE_ALL_SUCCESS:
      return {
        ...state,
        all: action.payload,
        page: state.page + 1,
        isFetching: false,
      };
    case SearchActionTypes.FETCH_YOUTUBE_ALL_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case SearchActionTypes.SET_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload,
      };
    case SearchActionTypes.SET_RADIO_SEARCH_VALUE:
      return {
        ...state,
        radioSearchValue: action.payload,
      };
    case SearchActionTypes.SET_LIBRARY_SEARCH_VALUE:
      return {
        ...state,
        librarySearchValue: action.payload,
      };
    case SearchActionTypes.CLEAR_YOUTUBE_ALL:
      return {
        ...state,
        all: [],
        page: 1,
      };
    case SearchActionTypes.CLEAR_YOUTUBE_RESULTS:
      return {
        ...state,
        playlists: [],
        all: [],
        videos: [],
        page: 1,
      };
    case SearchActionTypes.TOGGLE_PLAYLIST_EXISTED:
      return {
        ...state,
        playlists: state.playlists.map((p) => {
          if (p.youtube_playlist_code === action.payload.code) return { ...p, existed: !p.existed };
          return p;
        }),
        all: state.all.map((p) => {
          if (p.youtube_playlist_code === action.payload.code) return { ...p, existed: !p.existed };
          return p;
        }),
      };
    case SearchActionTypes.CLEAR_SEARCH_RESULTS:
      return {
        ...state,
        isFetching: false,
        playlists: [],
        videos: [],
        all: [],
        page: 1,
        searchValue: '',
        radioSearchValue: '',
        librarySearchValue: '',
        errorMessage: undefined,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        errorMessage: null,
      };
    default:
      return state;
  }
};

export default searchReducer;
