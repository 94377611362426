// @flow
import React, { useRef } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Drawer, Grid, Fab, Box, Typography, SvgIcon, makeStyles } from '@material-ui/core';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import { ReactComponent as deleteIcon } from '../../assets/icons/ic_delete.svg';
import { ReactComponent as cameraIcon } from '../../assets/icons/ic_camera.svg';
import { ReactComponent as photoIcon } from '../../assets/icons/ic_photo.svg';

import theme from '../../AppTheme';

const useStyles = makeStyles({
  drawerPaper: {
    padding: theme.spacing(3),
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  iconButtonDelete: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
  iconButtonGallery: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  iconButtonCamera: {
    backgroundColor: theme.palette.info.main,
    '&:hover': {
      backgroundColor: theme.palette.info.main,
    },
  },
  iconButtonLogout: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
});

const DrawerAccount = ({
  open,
  setOpen,
  deleteAction,
  galleryAction,
  cameraAction,
  logoutAction,
}) => {
  const styles = useStyles();
  const currentUser = useSelector((state) => state.user.currentUser, shallowEqual);

  const fileRef = useRef()
  const triggerClick = () => {
    fileRef.current.click()
  }

  const onFileChange = event => {
    cameraAction(event.target.files[0])
  };

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={() => setOpen(false)}
      classes={{ paper: styles.drawerPaper }}
    >
      <Box pb={3}>
        <Typography>{currentUser.displayName}</Typography>
        <Typography component="div" variant="body2">{currentUser.email}</Typography>
      </Box>
      <Grid container spacing={4} alignItems="center">
        {/* <Grid item>
          <Fab className={styles.iconButtonDelete} onClick={() => deleteAction()}>
            <SvgIcon component={deleteIcon} viewBox="0 0 24 24" />
          </Fab>
          <Typography component="div" align="center" variant="body2">
            Remove
          </Typography>
        </Grid> */}
        <Grid item>
          <Fab className={styles.iconButtonGallery} onClick={() => triggerClick()}>
            <SvgIcon component={photoIcon} viewBox="0 0 24 24" />
            <input ref={fileRef} style={{ display: 'none' }} id="file_image" type="file" onChange={onFileChange} accept="image/*" />
          </Fab>
          <Typography component="div" align="center" variant="body2">
            Gallery
          </Typography>
        </Grid>
        {/* <Grid item>
          <Fab className={styles.iconButtonCamera} onClick={() => cameraAction()}>
            <SvgIcon component={cameraIcon} viewBox="0 0 24 24" />
          </Fab>
          <Typography component="div" align="center" variant="body2">
            Camera
          </Typography>
        </Grid> */}
        <Grid item>
          <Fab className={styles.iconButtonLogout} onClick={() => logoutAction()}>
            <SvgIcon component={ExitToAppOutlinedIcon} viewBox="0 0 24 24" style={{ color: "white" }} />
          </Fab>
          <Typography component="div" align="center" variant="body2">
            Logout
          </Typography>
        </Grid>
      </Grid>
    </Drawer>
  );
};

DrawerAccount.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  deleteAction: PropTypes.func.isRequired,
  galleryAction: PropTypes.func.isRequired,
  cameraAction: PropTypes.func.isRequired,
  logoutAction: PropTypes.func.isRequired,
};

export default DrawerAccount;
