// @flow
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  Box,
  Button,
  IconButton,
  SvgIcon,
  Container,
  Fade,
  Fab,
  Typography,
  SnackbarContent,
  Snackbar,
  makeStyles,
  Toolbar,
  AppBar,
} from '@material-ui/core';
import classNames from 'classnames';
import { retrieveMaxResVideoImage } from 'utils/images';
import MicRecorder from 'mic-recorder-to-mp3';
import { isEmpty, debounce } from 'lodash';
import { addFavoriteTrack } from 'actions/favoriteTracksActions';
import { setPlayerSong, setPlaylistPlay } from 'actions/playerActions';
import {
  fetchAudioRecognitionStart,
  clearRecognizedTrack,
  setShowSnackbar,
} from 'actions/audioRecognitionActions';
import TracksService from 'services/TrackService';
import { addTrackToUserPlaylist } from 'actions/favoritePlaylistsActions';
import DialogPlaylistCreate from '../../dialogs/DialogPlaylistCreate';
import DialogPlaylistNew from '../../dialogs/DialogPlaylistNew';
import PaperSearch from '../../components/papers/PaperSearch';
import PaperListPlaylists from '../../components/papers/PaperListPlaylists';
import DialogDrawer from '../../components/dialogs/DialogDrawer';
import { ReactComponent as playguin } from '../../assets/icons/playguin.svg';
import { ReactComponent as play } from '../../assets/icons/play.svg';
import { ReactComponent as playlistAdd } from '../../assets/icons/ic_playlist_add.svg';
import { ReactComponent as back } from '../../assets/icons/ic_arrow_back.svg';
import theme from '../../AppTheme';
import trackFinder from '../../assets/images/track_finder.png';

const useStyles = makeStyles({
  pulseRing: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 1,
    '&:before': {
      content: "''",
      position: 'absolute',
      display: 'block',
      width: '250%',
      paddingTop: '250%',
      left: '50%',
      top: '50%',
      borderRadius: '100%',
      backgroundColor: theme.palette.primary.main,
      transform: 'translate(-50%,-50%) scale(1)',
      opacity: 0.3,
      animation: '$pulseRing '
        .concat(3 * theme.transitions.duration.complex, 'ms infinite ')
        .concat(theme.transitions.easing.easeInOut),
    },
  },
  pulsate: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 1,
    '&:before': {
      content: "''",
      position: 'absolute',
      display: 'block',
      width: '100px',
      height: '100px',
      left: '50%',
      top: '50%',
      borderRadius: '100%',
      backgroundColor: theme.palette.primary.main,
      transform: 'translate(-50%,-50%) scale(1)',
      opacity: 0.3,
      animation: '$pulsate '
        .concat(2 * theme.transitions.duration.complex, 'ms infinite ')
        .concat(theme.transitions.easing.easeInOut),
    },
  },
  boxSearch: {
    height: 'calc(100vh - 56px)',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  boxOver: {
    height: 'calc(100vh - 56px)',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 2,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    paddingBottom: theme.spacing(12),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '&:before': {
      content: "''",
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%)',
      zIndex: 1,
    },
  },
  boxOverInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    zIndex: 2,
  },
  buttonLabel: {
    flexDirection: 'column',
    textTransform: 'initial',
  },
  typography: {
    position: 'absolute',
    top: `calc(50% + ${theme.spacing(7)}px)`,
  },
  snackbar: {
    bottom: 70,
  },
  snackbarRadio: {
    bottom: 220,
  },
  snackbarContent: {
    backgroundColor: theme.palette.primary.main,
  },
  '@keyframes pulseRing': {
    '0%': {
      opacity: 0.1,
      transform: 'translate(-50%,-50%) scale(0)',
    },
    '85%': {
      opacity: 0.3,
      transform: 'translate(-50%,-50%)  scale(0.9)',
    },
    '100%': {
      opacity: 0,
      transform: 'translate(-50%,-50%)  scale(1)',
    },
  },
  '@keyframes pulsate': {
    '0%': {
      opacity: 0.1,
      transform: 'translate(-50%,-50%) scale(0)',
    },
    '50%': {
      opacity: 0.3,
      transform: 'translate(-50%,-50%)  scale(1)',
    },
    '100%': {
      opacity: 0.1,
      transform: 'translate(-50%,-50%)  scale(0)',
    },
  },
});

function AudioRecognition() {
  const styles = useStyles();
  const [searching, setSearching] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [dialogPlaylistCreate, setDialogPlaylistCreate] = useState(false);
  const [dialogPlaylistNew, setDialogPlaylistNew] = useState(false);
  const [valueSearchPlaylists, setValueSearchPlaylists] = useState('');
  const [dialogPlaylistAdd, setDialogPlaylistAdd] = useState(false);
  const [playlistName, setPlaylistName] = useState('');
  const [showAddToMyTracks, setShowAddToMyTracks] = useState(false);

  const track = useSelector((state) => state.audioRecognition.track, shallowEqual);
  const isLoading = useSelector((state) => state.audioRecognition.isFetching, shallowEqual);
  const isPlay = useSelector((state) => state.player.play, shallowEqual);
  const radio = useSelector((state) => state.player.radio, shallowEqual);
  const recognizedSong = useSelector((state) => state.player.song, shallowEqual);
  const playlist = useSelector((state) => state.player.playlist, shallowEqual);
  const open = useSelector((state) => state.audioRecognition.open, shallowEqual);
  const userTracks = useSelector((state) => state.tracks.list, shallowEqual);
  const userPlaylists = useSelector((state) => state.userPlaylists.list, shallowEqual);

  const isSongPlayer = !isEmpty(recognizedSong);
  const isPlaylistPlayer = !isEmpty(playlist);
  const isRadioPlayer = !isEmpty(radio);
  const [showResult, setShowResult] = useState(false);

  const trackExist = track && !userTracks.find((t) => t.youtube_code === track.youtube_code);

  const dispatch = useDispatch();
  const startAudioRecognition = (payload) => dispatch(fetchAudioRecognitionStart(payload));
  const addFavoriteSong = (payload) => dispatch(addFavoriteTrack(payload));
  const clearTrack = () => dispatch(clearRecognizedTrack());
  const addSongToPlayer = (payload) => dispatch(setPlayerSong(payload));
  const openPlayer = () => dispatch(setPlaylistPlay());
  const setOpen = (payload) => dispatch(setShowSnackbar(payload));
  const addSongToUserPlaylist = (payload) => dispatch(addTrackToUserPlaylist(payload));

  const Mp3Recorder = new MicRecorder({ bitRate: 128 });

  const handleClose = () => {
    setOpen(false);
  };

  const startRecording = () => {
    if (isBlocked) {
      console.log('Permission Denied');
    } else {
      Mp3Recorder.start()
        .then(() => {
          setIsRecording(true);
        })
        .catch((e) => console.error(e));
    }
  };

  const stopRecording = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        // const recordedBlob = URL.createObjectURL(blob);
        const file = new File(buffer, 'me-at-thevoice.mp3', {
          type: blob.type,
          lastModified: Date.now(),
        });
        // const player = new Audio(URL.createObjectURL(file));
        // player.play();
        startAudioRecognition({ file });
        setIsRecording(false);
        setShowResult(true);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (!isSongPlayer && !isPlaylistPlayer && !isRadioPlayer)
      navigator.mediaDevices.getUserMedia(
        { audio: true },
        () => {
          console.log('Permission Granted');
          setIsBlocked(false);
        },
        () => {
          console.log('Permission Denied');
          setIsBlocked(true);
        },
      );
    return function cleanup() {
      navigator.getUserMedia({ audio: true, video: false },
        (stream) => {
          // can also use getAudioTracks() or getVideoTracks()
          const tracks = stream.getAudioTracks();  // if only one media track
          if (tracks.length) tracks[0].stop();
        },
        (error) => {
          console.log('getUserMedia() error', error);
        });
    };
  }, []);

  const handleOpenDialogCreate = (value) => {
    setDialogPlaylistCreate(value);
  };

  const handleOpenDialogNew = (value) => {
    setDialogPlaylistNew(value);
  };

  const handleSearchValue = debounce((value) => {
    setValueSearchPlaylists(value);
  }, 1000);

  useEffect(() => {
    if (track) {
      setSearching(false);
      setShowResult(true);
    } else if (!isLoading) {
      setSearching(false);
    }
  }, [track, isLoading]);

  return (
    <>
      <Box className={styles.boxSearch}>
        <Button
          color="primary"
          style={{ height: 'calc(100vh - 56px)' }}
          fullWidth
          disableRipple
          classes={{ label: styles.buttonLabel }}
          onClick={() => {
            if (!isLoading && !isSongPlayer && !isPlaylistPlayer) {
              setSearching(!searching);
              if (isRadioPlayer) { startAudioRecognition({ streamUrl: radio.stream_link }); setShowResult(true); }
              else {
                startRecording();
                setTimeout(() => {
                  if (!isRadioPlayer) stopRecording();
                }, 5000);
              }
            }
          }}
        >
          <Box className={classNames({ [styles.pulseRing]: searching })} />
          <Box className={classNames({ [styles.pulsate]: !searching })} />
          <SvgIcon
            component={playguin}
            fontSize="large"
            viewBox="0 0 264 264"
            style={{ width: 180, height: 180, zIndex: 2 }}
          />
          {searching ? (
            <Typography
              className={styles.typography}
              variant="h6"
              align="center"
              color="textPrimary"
            >
              Listening...
            </Typography>
          ) : (
            <Typography
              className={styles.typography}
              variant="h6"
              align="center"
              color="textPrimary"
            >
              Press to scan
            </Typography>
          )}
        </Button>
      </Box>
      {showResult && (<Fade in={showResult && !isLoading} timeout={theme.transitions.duration.complex}>
        <Box
          className={styles.boxOver}
          style={{
            ...(track?.youtube_code
              ? { backgroundImage: `url(${retrieveMaxResVideoImage(track?.youtube_code)})` }
              : { backgroundImage: `url(${trackFinder})` }),
          }}
        >
          <Box className={styles.boxOverInner}>
            <Typography component="strong" variant="h4" align="center" display="block">
              {track?.name}
            </Typography>
            <Typography variant="h5" align="center" display="block">
              {track?.author}
            </Typography>
            <Box display="flex" alignItems="center" pt={3}>
              <IconButton
                className={styles.back}
                color="primary"
                onClick={() => {
                  setShowResult(false);
                  clearTrack();
                }}
              >
                <SvgIcon component={back} viewBox="0 0 24 24" />
              </IconButton>
              <Fab
                className={styles.fab}
                color="primary"
                onClick={() => {
                  if (track?.youtube_code) {
                    addSongToPlayer({ code: track.youtube_code });
                    if (!isPlay) openPlayer();
                  }
                }}
              >
                <SvgIcon component={play} viewBox="0 0 24 24" />
              </Fab>

              <IconButton
                className={styles.add}
                color="primary"
                onClick={async () => {
                  if (track?.youtube_code) {
                    const data = await TracksService.checkIfTrackExists({
                      track: track.youtube_code,
                    });
                    setShowAddToMyTracks(!data.isExistInUserTracks);
                    setDialogPlaylistAdd(true);
                  }
                }
                }

              >
                <SvgIcon component={playlistAdd} viewBox="0 0 24 24" />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Fade>)}
      <DialogDrawer setOpen={setDialogPlaylistAdd} open={dialogPlaylistAdd} title="Playlists">
        <AppBar position="sticky" color="transparent">
          <Box pb={2}>
            <Toolbar>
              <PaperSearch value={valueSearchPlaylists} onValueChange={handleSearchValue} />
            </Toolbar>
          </Box>
        </AppBar>
        <PaperListPlaylists
          hasInfiniteScroll
          playlists={userPlaylists.filter(({ createdByUser: isCustom }) => isCustom)}
          selectedSong={track}
          searchValue={valueSearchPlaylists}
          primaryAction={() => { }}
          createAction={() => {
            // setDialogPlaylistAdd(false);
            setDialogPlaylistCreate(true);
          }}
          showAddToMyTracks={trackExist}
          addToMyTracksAction={
            showAddToMyTracks
              ? () => {
                // setDialogPlaylistAdd(false);
                addFavoriteSong({
                  name: track.name,
                  youtube_code: track.youtube_code,
                  externalIds: track?.externalIds || null,
                });
              }
              : null
          }
          secondaryAction={(currPlaylist) => {
            if (currPlaylist.songs.every((s) => s.youtube_code !== track?.youtube_code)) {
              addSongToUserPlaylist({
                ...currPlaylist,
                count: currPlaylist.count + 1,
                songs: [
                  ...currPlaylist.songs,
                  {
                    name: track.name,
                    youtube_code: track.youtube_code,
                    externalIds: track?.externalIds || null,
                  },
                ],
              });
              // setDialogPlaylistAdd(false);
            }
          }}
        />
      </DialogDrawer>
      {dialogPlaylistCreate && (
        <DialogPlaylistCreate
          open={dialogPlaylistCreate}
          setOpen={handleOpenDialogCreate}
          playlistName={playlistName}
          setPlaylistName={setPlaylistName}
          createPlaylistAction={(name) => {
            setPlaylistName(name);
            setDialogPlaylistCreate(false);
            setDialogPlaylistNew(true);
          }}
        />
      )}
      {dialogPlaylistNew && (
        <DialogPlaylistNew
          open={dialogPlaylistNew}
          setOpen={handleOpenDialogNew}
          playlistName={playlistName}
          selectedSong={track}
        />
      )}
      <Snackbar
        className={isRadioPlayer ? styles.snackbarRadio : styles.snackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        onClose={handleClose}
        key="snackbar1"
      >
        <SnackbarContent
          className={styles.snackbarContent}
          aria-describedby="message-id2"
          message={<Typography align="center">Track not found.</Typography>}
        />
      </Snackbar>
    </>
  );
}

export default AudioRecognition;
