import React, { forwardRef } from 'react';
import {
  Dialog,
  DialogContent,
  Slide,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import theme from '../../AppTheme';

const useStyles = makeStyles({
  dialog: {},
  dialogContainer: {
    alignItems: 'flex-end',
  },
  dialogPaper: {
    // backgroundColor: '#081523',
  },
  appBar: {
    backgroundColor: '#081523',
  },
  toolBar: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    justifyContent: 'space-between',
  },
  dialogContent: {
    padding: 0,
  },
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogDrawerFull = ({ open, setOpen, appBarTop, appBarBottom, children, fullScreen}) => {
  const styles = useStyles();
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => setOpen(false)}
      TransitionComponent={Transition}
      className={styles.dialog}
      classes={{ container: styles.dialogContainer, paperFullScreen: styles.dialogPaper }}
    >
      {appBarTop}
      <DialogContent classes={{ root: styles.dialogContent }}>{children}</DialogContent>
      {appBarBottom}
    </Dialog>
  );
};

DialogDrawerFull.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  appBarTop: PropTypes.node,
  appBarBottom: PropTypes.node,
  fullScreen: PropTypes.bool
};
DialogDrawerFull.defaultProps = {
  appBarTop: null,
  appBarBottom: null,
  fullScreen: true,
};

export default DialogDrawerFull;
