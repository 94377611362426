import React, { useState } from 'react';
import { makeStyles, Box, Container, Typography, Collapse } from '@material-ui/core';
import { debounce } from 'lodash';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  addTrackToUserPlaylist
} from 'actions/favoritePlaylistsActions';
import PropTypes from 'prop-types';
import PaperSearch from '../papers/PaperSearch';
import PaperListSongs from '../papers/PaperListSongs';

const useStyles = makeStyles({
  inputBase: {
    fontSize: 24,
    textAlign: 'center',
  },
});

const PlaylistAddTo = ({ userSongs, addUserSong }) => {
  const styles = useStyles();
  const [searchValue, setSearchValue] = useState('');

  const tracks = useSelector((state) => state.tracks.list, shallowEqual);
  const showAddTracks = useSelector((state) => state.header.showAddTracks, shallowEqual);
  const currPlaylist = useSelector((state) => state.playlists?.current, shallowEqual);

  const dispatch = useDispatch();
  const addSongToUserPlaylist = (payload) => dispatch(addTrackToUserPlaylist(payload));



  const handleSearchValue = debounce((value) => {
    setSearchValue(value);
  }, 1000);
  return (
    <>
      {!showAddTracks && <Container maxWidth={false}>
        <Typography>{`${userSongs.length} tracks added`}</Typography>
      </Container>}
      <Box py={3}>
        <PaperSearch onValueChange={handleSearchValue} value={searchValue} />
      </Box>
      <Collapse in={searchValue !== null} unmountOnExit mountOnEnter>
        <PaperListSongs
          hasInfiniteScroll
          searchValue={searchValue}
          songs={tracks.filter((o1) => showAddTracks ? currPlaylist?.videos?.every((o2) => o1.youtube_code !== o2.youtube_code) : userSongs.every((o2) => o1.youtube_code !== o2.youtube_code))}
          clickAction={(e) => console.log('clickAction', e)}
          secondaryAction={(s) => {
            const { videos, id } = currPlaylist;
            if (showAddTracks) {
              addSongToUserPlaylist({
                id,
                count: currPlaylist.count + 1,
                songs: [...videos, s],
              })
            } else {
              addUserSong(s)
            }
          }}
        />
      </Collapse>
    </>
  );
};

PlaylistAddTo.propTypes = {
  userSongs: PropTypes.instanceOf(Array).isRequired,
  addUserSong: PropTypes.func.isRequired,
};

export default PlaylistAddTo;
