// @flow
import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { AppBar, Toolbar, ButtonBase } from '@material-ui/core';
import theme from '../AppTheme';
import Icon from '../components/atoms/Icon';

const tabs = [
  {
    route: '/featured',
    icon: 'featured',
    label: 'Home',
  },
  {
    route: '/radio',
    icon: 'radio',
    label: 'Radio',
  },
  {
    route: '/library',
    icon: 'library',
    label: 'Library',
  },
  {
    route: '/audio-recognition',
    icon: 'recognition',
    label: 'Audio Recognition',
  },
];

const useStyles = makeStyles({
  appBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: theme.palette.background.default,
  },
  toolBar: {
    top: 'auto',
    bottom: 0,
  },
  button: {
    flexGrow: 1,
    height: theme.spacing(7),
    '&.active': {
      backgroundColor: 'rgba(67,204,255,0.2)',
      '& .twoTone': {
        fill: '#FFCE00',
      },
    },
  },
});

const Navigation = () => {
  const styles = useStyles();
  return (
    <AppBar position="fixed" color="primary" className={styles.appBar}>
      <Toolbar disableGutters>
        {tabs.map((tab, index) => {
          return (
            <ButtonBase
              className={classNames({
                [styles.button]: true,
              })}
              component={NavLink}
              to={tab.route}
              key={tab.route}
            >
              <Icon icon={tab.icon} />
            </ButtonBase>
          );
        })}
      </Toolbar>
    </AppBar>
  );
};

export default Navigation;
