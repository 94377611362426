export const CategoriesActionTypes = {
  FETCH_CATEGORIES_START: 'FETCH_CATEGORIES_START',
  FETCH_CATEGORIES_SUCCESS: 'FETCH_CATEGORIES_SUCCESS',
  FETCH_CATEGORIES_FAILURE: 'FETCH_CATEGORIES_FAILURE',
  FETCH_CATEGORY_BY_ID_START: 'FETCH_CATEGORY_BY_ID_START',
  FETCH_CATEGORY_BY_ID_SUCCESS: 'FETCH_CATEGORY_BY_ID_SUCCESS',
  FETCH_CATEGORY_BY_ID_FAILURE: 'FETCH_CATEGORY_BY_ID_FAILURE',
  CLEAR_FEATURED_CATEGORIES: 'CLEAR_FEATURED_CATEGORIES',
  SET_CATEGORY_LAST_VISIBLE_ITEM: 'SET_CATEGORY_LAST_VISIBLE_ITEM',
  CLEAR_VIEW_ALL_CATEGORIES: 'CLEAR_VIEW_ALL_CATEGORIES',
};

export const setLastVisible = (payload) => ({
  type: CategoriesActionTypes.SET_CATEGORY_LAST_VISIBLE_ITEM,
  payload,
});

export const clearViewAllCategories = () => ({
  type: CategoriesActionTypes.CLEAR_VIEW_ALL_CATEGORIES,
});

export const clearCategories = () => ({
  type: CategoriesActionTypes.CLEAR_FEATURED_CATEGORIES,
});

export const fetchCategoriesStart = () => ({
  type: CategoriesActionTypes.FETCH_CATEGORIES_START,
});

export const fetchCategoriesSuccess = (payload) => ({
  type: CategoriesActionTypes.FETCH_CATEGORIES_SUCCESS,
  payload,
});

export const fetchCategoriesFailure = (errorMessage) => ({
  type: CategoriesActionTypes.FETCH_CATEGORIES_FAILURE,
  payload: errorMessage,
});

export const fetchCategoryByIdStart = (payload) => ({
  type: CategoriesActionTypes.FETCH_CATEGORY_BY_ID_START,
  payload,
});

export const fetchCategoryByIdSuccess = (payload) => ({
  type: CategoriesActionTypes.FETCH_CATEGORY_BY_ID_SUCCESS,
  payload,
});

export const fetchCategoryByIdFailure = (errorMessage) => ({
  type: CategoriesActionTypes.FETCH_CATEGORY_BY_ID_FAILURE,
  payload: errorMessage,
});
