import React, { forwardRef } from 'react';
import {
  Dialog,
  DialogContent,
  SvgIcon,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Slide,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { ReactComponent as close } from '../../assets/icons/ic_close.svg';
import theme from '../../AppTheme';

const useStyles = makeStyles({
  dialog: {},
  dialogContainer: {
    alignItems: 'flex-end',
  },
  dialogPaper: {
    height: '80vh',
    backgroundColor: '#081523',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  appBar: {
    backgroundColor: '#081523',
  },
  toolBar: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    justifyContent: 'space-between',
  },
  dialogContent: {
    padding: 0,
  },
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogDrawer = ({ title, open, setOpen, children }) => {
  const styles = useStyles();
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => setOpen(false)}
      TransitionComponent={Transition}
      className={styles.dialog}
      classes={{ container: styles.dialogContainer, paperFullScreen: styles.dialogPaper }}
    >
      <AppBar className={styles.appBar} color={'transparent'} elevation={0} position={'sticky'}>
        <Toolbar className={styles.toolBar}>
          <Typography color={'primary'} variant="h5" className={styles.title}>
            {title}
          </Typography>
          <IconButton
            edge="start"
            color={'primary'}
            onClick={() => setOpen(false)}
            aria-label="close"
          >
            <SvgIcon component={close} viewBox="0 0 24 24" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent classes={{ root: styles.dialogContent }}>{children}</DialogContent>
    </Dialog>
  );
};

DialogDrawer.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default DialogDrawer;
