const routesHeaderOptions = [
  {
    rvalue: '/featured',
    hasSearch: false,
    hasAvatar: true,
    hasBack: false,
    hasFavoriteAction: false,
    hasMoreAction: false,
  },
  {
    rvalue: '/playlist/:listId',
    hasSearch: false,
    hasAvatar: false,
    hasBack: true,
    hasFavoriteAction: true,
    hasMoreAction: true,
  },
  {
    rvalue: '/featured/category/:catId',
    hasSearch: false,
    hasAvatar: false,
    hasBack: true,
    hasFavoriteAction: false,
    hasMoreAction: false,
  },
  {
    rvalue: '/search/:type',
    hasSearch: false,
    hasAvatar: false,
    hasBack: true,
    hasFavoriteAction: false,
    hasMoreAction: false,
  },
  {
    rvalue: '/radio',
    hasSearch: true,
    hasAvatar: false,
    hasBack: false,
    hasFavoriteAction: false,
    hasMoreAction: false,
  },
  {
    rvalue: '/radio/player',
    hasSearch: true,
    hasAvatar: false,
    hasBack: false,
    hasFavoriteAction: false,
    hasMoreAction: false,
  },
  {
    rvalue: '/library',
    hasSearch: true,
    hasAvatar: false,
    hasBack: false,
    hasFavoriteAction: false,
    hasMoreAction: false,
  },
  {
    rvalue: '/audio-recognition',
    hasSearch: false,
    hasAvatar: false,
    hasBack: false,
    isFavorite: false,
    hasFavoriteAction: false,
    hasMoreAction: false,
  },
];

export default routesHeaderOptions;
