import { GenresActionTypes } from 'actions/genresActions';

const INITIAL_STATE = {
  list: [],
  filters: [],
  isFetching: false,
  errorMessage: undefined,
};

const gerneReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GenresActionTypes.FETCH_RADIO_GENDERS_START:
      return {
        ...state,
        isFetching: true,
      };
    case GenresActionTypes.FETCH_RADIO_GENDERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.payload,
      };
    case GenresActionTypes.FETCH_RADIO_GENDERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case GenresActionTypes.SET_RADIO_FILTER_GENRE:
      return {
        ...state,
        list: state.list.map((g) => {
          if (g.name === action.payload) return { ...g, selected: !g.selected };
          return g;
        }),
        filters: state.filters.includes(action.payload)
          ? state.filters.filter((f) => f !== action.payload)
          : [...state.filters, action.payload],
      };
    case GenresActionTypes.CLEAR_GENRES:
      return {
        ...state,
        list: [],
        filters: [],
        isFetching: false,
        errorMessage: undefined,
      };
    default:
      return state;
  }
};

export default gerneReducer;
