import React from 'react';
import {
  Avatar,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  IconButton,
  SvgIcon,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import theme from '../../AppTheme';
import { ReactComponent as add } from '../../assets/icons/ic_add.svg';

const useStyles = makeStyles({
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    borderRadius: 6,
    borderStyle: 'dashed',
    borderWidth: 2,
    borderColor: theme.palette.primary.main,
    backgroundColor: 'transparent',
    position: 'relative',
  },
  svgIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
});

const ListItemPlaylistCreate = ({ title, createAction }) => {
  const styles = useStyles();
  return (
    <ListItem button divider onClick={() => createAction()}>
      <ListItemAvatar>
        <Avatar className={styles.avatar}>
          <SvgIcon className={styles.svgIcon} component={add} viewBox="0 0 24 24" />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={title} />
    </ListItem>
  );
};

ListItemPlaylistCreate.propTypes = {
  createAction: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default ListItemPlaylistCreate;
