// @flow
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation, matchPath, useHistory } from 'react-router-dom';
import { addFavoritePlaylist, removeFavoritePlaylist } from 'actions/userActions';
import {
  setDrawerAccount,
  setShowSearchLibrary,
  setShowSearchRadio,
  setShowRename,
  setShowAddTracks,
} from 'actions/headerActions';
import { clearPlaylist } from 'actions/playlistsActions';
import { clearShuffleList } from 'actions/playerActions';
import { deleteUserPlaylist } from 'actions/favoritePlaylistsActions';
import {
  AppBar,
  Toolbar,
  ButtonBase,
  SvgIcon,
  IconButton,
  Avatar,
  Grid,
  Menu,
  MenuItem,
} from '@material-ui/core';
import routesHeaderOptions from 'utils/routes';
import theme from '../AppTheme';
import { ReactComponent as back } from '../assets/icons/ic_arrow_back.svg';
import { ReactComponent as close } from '../assets/icons/ic_close.svg';
import { ReactComponent as search } from '../assets/icons/ic_search.svg';
import { ReactComponent as more } from '../assets/icons/more.svg';
// import avatar from '../assets/images/avatar.png';
import { ReactComponent as user } from '../assets/icons/ic_user.svg';
import Icon from '../components/atoms/Icon';

import { ReactComponent as favorite } from '../assets/icons/ic_favorite.svg';
import { ReactComponent as favoriteBorder } from '../assets/icons/ic_favorite_border.svg';

const useStyles = makeStyles({
  appBar: {},
  toolBar: {
    top: 'auto',
    bottom: 0,
  },
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    color: theme.palette.common.white,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.common.white,
  },
});

const AppBarTop = () => {
  const styles = useStyles();

  const [hasSearch, setHasSearch] = useState(false);
  const [hasAvatar, setHasAvatar] = useState(false);
  const [hasBack, setHasBack] = useState(true);
  const [hasFavoriteAction, setHasFavoriteAction] = useState(true);
  const [hasMoreAction, setHasMoreAction] = useState(true);
  const [hasMoreActionMenu, setHasMoreActionMenu] = useState(null);

  const favoritePlaylists = useSelector((state) => state.user.playlists, shallowEqual);
  const currentPlaylist = useSelector((state) => state.playlists.current, shallowEqual);
  const userAvatar = useSelector((state) => state.user.currentUser.avatar, shallowEqual);

  const dispatch = useDispatch();
  const addPlaylist = (payload) => dispatch(addFavoritePlaylist(payload));
  const removePlaylist = (payload) => dispatch(removeFavoritePlaylist(payload));
  const setDrawerAccountOpen = (payload) => dispatch(setDrawerAccount(payload));
  const showSearchLibrary = (payload) => dispatch(setShowSearchLibrary(payload));
  const showSearchRadio = (payload) => dispatch(setShowSearchRadio(payload));
  const removeUserPlaylist = (payload) => dispatch(deleteUserPlaylist(payload));
  const clearShuffleArray = () => dispatch(clearShuffleList());
  const clearSelectedPlaylist = () => dispatch(clearPlaylist());
  const showRename = (payload) => dispatch(setShowRename(payload));
  const setShowAddTrack = (payload) => dispatch(setShowAddTracks(payload));

  const [isSearchingRadio, setIsSearchingRadio] = useState(false);
  const [isSearchingLibrary, setIsSearchingLibrary] = useState(false);

  const isFavorite = !!favoritePlaylists.find((p) => p.id === currentPlaylist.id);

  const history = useHistory();
  const location = useLocation();
  const options = routesHeaderOptions.find((route) =>
    matchPath(location.pathname, {
      path: route.rvalue,
      exact: true,
      strict: false,
    }),
  );

  const initiliazeHeader = () => {
    if (options) {
      setHasFavoriteAction(
        options.hasFavoriteAction &&
        currentPlaylist.playlistType &&
        !currentPlaylist?.createdByUser &&
        !currentPlaylist?.isForRecognition,
      );
      setHasSearch(options.hasSearch);
      setHasAvatar(options.hasAvatar);
      setHasBack(options.hasBack);
      setHasMoreAction(options.hasMoreAction && !currentPlaylist?.isForRecognition && (currentPlaylist?.createdByUser || !currentPlaylist.playlistType));
    }
  };

  const deletePlaylist = () => {
    clearSelectedPlaylist();
    clearShuffleArray();
    removeUserPlaylist(currentPlaylist.id);
    history.push('/library');
  };

  useEffect(() => {
    initiliazeHeader();
  });

  return (
    location.pathname !== '/audio-recognition' && (
      <AppBar position="sticky" color="primary" className={styles.appBar}>
        <Toolbar>
          <Grid container alignItems="center" justify="space-between">
            <Grid item xs={5}>
              <Grid container alignContent="center" justify="flex-start" spacing={1}>
                {hasBack && (
                  <Grid item xs="auto">
                    <IconButton
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      <SvgIcon component={back} viewBox="0 0 24 24" />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Grid container alignContent="center" justify="center">
                <Grid item xs="auto">
                  <Icon icon="recognition" size="large" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid container justify="flex-end">
                {hasAvatar && (
                  <ButtonBase
                    onClick={() => {
                      setDrawerAccountOpen(true);
                    }}
                  >
                    <Avatar src={userAvatar || user} className={styles.avatar} />
                  </ButtonBase>
                )}
                {hasSearch && (
                  <IconButton
                    edge='end'
                    onClick={() => {
                      if (location.pathname === '/library') {
                        showSearchLibrary(!isSearchingLibrary);
                        setIsSearchingLibrary(!isSearchingLibrary);
                      } else if (location.pathname === '/radio') {
                        showSearchRadio(!isSearchingRadio);
                        setIsSearchingRadio(!isSearchingRadio);
                      }
                    }}
                  >
                    {(isSearchingLibrary && location.pathname === '/library') ||
                      (isSearchingRadio && location.pathname === '/radio') ? (
                      <SvgIcon component={close} viewBox="0 0 24 24" />
                    ) : (
                      <SvgIcon component={search} viewBox="0 0 24 24" />
                    )}
                  </IconButton>
                )}
                {hasFavoriteAction && (
                  <IconButton
                    edge='end'
                    onClick={() =>
                      isFavorite
                        ? removePlaylist(currentPlaylist.id)
                        : addPlaylist({
                          categoryId: currentPlaylist.categoryId,
                          id: currentPlaylist.id,
                        })
                    }
                  >
                    {isFavorite ? (
                      <SvgIcon component={favorite} viewBox="0 0 24 24" />
                    ) : (
                      <SvgIcon component={favoriteBorder} viewBox="0 0 24 24" />
                    )}
                  </IconButton>
                )}
                {hasMoreAction && (
                  <>
                    <IconButton
                      edge='end'
                      onClick={(event) => setHasMoreActionMenu(event.currentTarget)}
                    >
                      <SvgIcon component={more} viewBox="0 0 24 24" />
                    </IconButton>
                    <Menu
                      anchorEl={hasMoreActionMenu}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      getContentAnchorEl={null}
                      keepMounted
                      open={Boolean(hasMoreActionMenu)}
                      onClose={() => setHasMoreActionMenu(null)}
                    >
                      {currentPlaylist?.createdByUser && (<MenuItem
                        onClick={() => {
                          setHasMoreActionMenu(null);
                          setShowAddTrack(true);
                        }}
                      >
                        Add Tracks
                      </MenuItem>)}
                      {currentPlaylist?.createdByUser && (<MenuItem
                        onClick={() => {
                          setHasMoreActionMenu(null);
                          showRename(true);
                        }}
                      >
                        Rename
                      </MenuItem>)}
                      <MenuItem
                        onClick={() => {
                          setHasMoreActionMenu(null);
                          deletePlaylist();
                        }}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    )
  );
};

export default AppBarTop;
