import { takeLatest, put, all, call, select } from 'redux-saga/effects';

import {
  UserActionTypes,
  signInSuccess,
  signInFailure,
  signOutSuccess,
  signOutFailure,
  signUpSuccess,
  signUpFailure,
  checkSessionEnd,
} from 'actions/userActions';

import { clearPlaylistList } from 'actions/favoritePlaylistsActions';
import { clearTrackList } from 'actions/favoriteTracksActions';
import { clearCategories } from 'actions/categoriesActions';
import { clearRadio } from 'actions/radioActions';

import {
  auth,
  googleProvider,
  createUserProfileDocument,
  getCurrentUser,
  firestore
} from 'firebase/firebase.utils';

export function* getSnapshotFromUserAuth(userAuth, additionalData) {
  try {
    const userRef = yield call(createUserProfileDocument, userAuth, additionalData);
    const user = yield userRef.get();
    const { avatar, ...userSnapshot } = user.data();
    yield put(signInSuccess({ id: user.id, avatar, ...userSnapshot, }));
  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* signInWithGoogle() {
  try {
    const { user } = yield auth.signInWithPopup(googleProvider);
    yield getSnapshotFromUserAuth(user);
  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* signInWithEmail({ payload: { email, password } }) {
  try {
    const { user } = yield auth.signInWithEmailAndPassword(email, password);
    yield getSnapshotFromUserAuth(user);
  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* isUserAuthenticated() {
  try {
    const userAuth = yield getCurrentUser();
    if (!userAuth) {
      yield put(checkSessionEnd());
      return;
    }
    yield getSnapshotFromUserAuth(userAuth);
  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* signOut() {
  try {
    yield auth.signOut();
    yield put(signOutSuccess());
    yield put(clearCategories());
    yield put(clearPlaylistList());
    yield put(clearTrackList());
    yield put(clearRadio());
  } catch (error) {
    yield put(signOutFailure(error));
  }
}

export function* signUp({ payload: { email, password, displayName } }) {
  try {
    const { user } = yield auth.createUserWithEmailAndPassword(email, password);
    yield put(signUpSuccess({ user, additionalData: { displayName } }));
  } catch (error) {
    yield put(signUpFailure(error));
  }
}

export function* signInAfterSignUp({ payload: { user, additionalData } }) {
  yield getSnapshotFromUserAuth(user, additionalData);
}

export function* onGoogleSignInStart() {
  yield takeLatest(UserActionTypes.GOOGLE_SIGN_IN_START, signInWithGoogle);
}

export function* onEmailSignInStart() {
  yield takeLatest(UserActionTypes.EMAIL_SIGN_IN_START, signInWithEmail);
}

export function* onCheckUserSession() {
  yield takeLatest(UserActionTypes.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* onSignOutStart() {
  yield takeLatest(UserActionTypes.SIGN_OUT_START, signOut);
}

export function* onSignUpStart() {
  yield takeLatest(UserActionTypes.SIGN_UP_START, signUp);
}

export function* onSignUpSuccess() {
  yield takeLatest(UserActionTypes.SIGN_UP_SUCCESS, signInAfterSignUp);
}

export function* onAddAvatar({ payload }) {
  const user = yield select((state) => state.user);
  if (user) {
    try {
      const userRef = firestore.doc(`users/${user.currentUser.id}`);
      yield userRef.update({ avatar: payload });
    } catch (error) {
      console.log(error);
    }
  }
}

export function* onAddAvatarSuccess() {
  yield takeLatest(UserActionTypes.ADD_USER_AVATAR, onAddAvatar);
}

export function* userSagas() {
  yield all([
    call(onGoogleSignInStart),
    call(onEmailSignInStart),
    call(isUserAuthenticated),
    call(onSignOutStart),
    call(onSignUpStart),
    call(onSignUpSuccess),
    call(onAddAvatarSuccess),
  ]);
}
