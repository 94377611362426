import React from 'react';
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemAvatar,
  Avatar,
  makeStyles,
  SvgIcon,
  IconButton,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { addFavoriteRadio, removeFavoriteRadio } from 'actions/userActions';
import PropTypes from 'prop-types';
import Flag from 'react-world-flags';
import theme from '../../AppTheme';
import { ReactComponent as favorite } from '../../assets/icons/ic_favorite.svg';
import { ReactComponent as favoriteBorder } from '../../assets/icons/ic_favorite_border.svg';
import radio from '../../assets/images/playlist.png';

const useStyles = makeStyles({
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    borderRadius: 6,
  },
});

const ListItemWithFavorite = ({ item, primaryAction, selected, secondaryAction }) => {
  const styles = useStyles();

  const favoriteRadios = useSelector((state) => state.user.radios, shallowEqual);

  const dispatch = useDispatch();
  const addRadio = (payload) => dispatch(addFavoriteRadio(payload));
  const removeRadio = (payload) => dispatch(removeFavoriteRadio(payload));
  const isFavorite = !!favoriteRadios.find((r) => r.id === item.id);

  return (
    <ListItem button={Boolean(primaryAction)} divider onClick={() => primaryAction(item)} selected={selected}>
      <ListItemAvatar>
        <Avatar src={item.image48 || radio} className={styles.avatar} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <Typography>
              <Flag code={item.country} height="12" />
              {` ${item.title} ${item.frequency !== 'e-radio' ? item.frequency : ''}`}
            </Typography>
          </>
        }
        secondary={item.gender.join(', ')}
      />
      <ListItemSecondaryAction>
        <IconButton
          color="primary"
          edge="end"
          onClick={() => (isFavorite ? removeRadio(item.id) : addRadio({ ...item, added_at: new Date() }))}
        >
          {isFavorite ? (
            <SvgIcon component={favorite} viewBox="0 0 24 24" />
          ) : (
            <SvgIcon component={favoriteBorder} viewBox="0 0 24 24" />
          )}
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

ListItemWithFavorite.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    country: PropTypes.string,
    frequency: PropTypes.string,
    image48: PropTypes.string,
    isFavorite: PropTypes.bool,
    gender: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  primaryAction: PropTypes.func,
  selected: PropTypes.bool,
  secondaryAction: PropTypes.func.isRequired,
};

ListItemWithFavorite.defaultProps = {
  primaryAction: null,
  selected: false,
};

export default ListItemWithFavorite;
