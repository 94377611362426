import { all, call } from 'redux-saga/effects';

import { userSagas } from './userSaga';
import { radioSagas } from './radioSaga';
import { categoriesSagas } from './categoriesSaga';
import { genresSagas } from './genreSaga';
import { countriesSagas } from './countriesSaga';
import { favoriteCategoryPlaylistSagas } from './favoriteCategoryPlaylistSaga';
import { favoriteRadioSagas } from './favoriteRadioSaga';
import { favoriteTracksSagas } from './favoriteTracksSaga';
import { favoritePlaylistsSagas } from './favoritePlaylistsSaga';
import { audioRecognitionSagas } from './audioRecognitionSaga';
import { youtubeSagas } from './searchSaga';

export default function* rootSaga() {
  yield all([
    call(userSagas),
    call(radioSagas),
    call(categoriesSagas),
    call(youtubeSagas),
    call(genresSagas),
    call(countriesSagas),
    call(favoriteCategoryPlaylistSagas),
    call(favoriteRadioSagas),
    call(favoriteTracksSagas),
    call(favoritePlaylistsSagas),
    call(audioRecognitionSagas),
  ]);
}
