import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Radio,
  ListItemAvatar,
  Avatar,
  makeStyles,
} from '@material-ui/core';
import { setCountryActive } from 'actions/countriesActions';
import Flag from 'react-world-flags';
import PropTypes from 'prop-types';
import theme from '../../AppTheme';

const useStyles = makeStyles({
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(2),
    borderRadius: 0,
    backgroundColor: 'transparent',
  },
  listItemAvatar: {
    minWidth: theme.spacing(5),
  },
});

const ListItemCountry = ({ country }) => {
  const styles = useStyles();

  const countriesFilter = useSelector((state) => state.countries.selected, shallowEqual);

  const dispatch = useDispatch();
  const setCountry = (payload) => dispatch(setCountryActive(payload));

  const handleOnchange = () => {
    setCountry(country);
  };
  return (
    <ListItem button divider onClick={handleOnchange}>
      <ListItemAvatar classes={{ root: styles.listItemAvatar }}>
        <Avatar className={styles.avatar}><Flag code={country.code} /></Avatar>
      </ListItemAvatar>
      <ListItemText primary={country.title} />
      <ListItemSecondaryAction>
        <Radio
          edge="end"
          color="primary"
          onChange={handleOnchange}
          checked={country.code === countriesFilter?.code}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

ListItemCountry.propTypes = {
  country: PropTypes.shape({
    id: PropTypes.string,
    code: PropTypes.string,
    count: PropTypes.number,
    title: PropTypes.string,
    selected: PropTypes.bool,
  }).isRequired,
};

export default ListItemCountry;
