// @flow
import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { debounce } from 'lodash';
import { fetchCategoryByIdStart } from 'actions/categoriesActions';
import Header from '../../components/partials/Header';
import Content from '../../components/partials/Content';
import Preloader from '../../components/partials/Preloader';
import PaperGridPlaylists from '../../components/papers/PaperGridPlaylists';
import PaperSearch from '../../components/papers/PaperSearch';

const GridPlaylistsWithPreloader = Preloader(PaperGridPlaylists);

function Category() {
  const { catId } = useParams();
  const [searchValue, setSearchValue] = useState('');

  const isLoading = useSelector((state) => state.categoryAll.isFetching, shallowEqual);
  const categories = useSelector((state) => state.categories.list, shallowEqual);
  const playlists = useSelector((state) => state.categoryAll.list, shallowEqual);

  const category = categories.find(({ id }) => id === catId);

  const dispatch = useDispatch();
  const fetchCategoryById = (id) => dispatch(fetchCategoryByIdStart(id));

  useEffect(() => {
    if (catId && !playlists) {
      fetchCategoryById(catId);
    }
  }, [catId]);

  const handleSearchValue = debounce((value) => {
    setSearchValue(value);
  }, 1000);

  return (
    <>
      {/* todo mastora change title on AppBarTop */}
      {/* <Header title={category.translation?.gr?.title} /> */}
      <Content withAppBar={<PaperSearch value={searchValue} onValueChange={handleSearchValue} />}>
        <Box py={3}>
          <GridPlaylistsWithPreloader
            isLoading={isLoading}
            playlists={playlists?.filter(({ songs }) => songs?.length) ?? []}
            searchValue={searchValue}
            hideFilters
          />
        </Box>
      </Content>
    </>
  );
}

export default Category;
