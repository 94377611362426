/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemAvatar,
  Avatar,
  SvgIcon,
  IconButton,
  makeStyles,
  CircularProgress,
  Box,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { retrieveVideoImage } from 'utils/images';
import { ReactComponent as checked } from '../../assets/icons/ic_playlist_checked.svg';

import theme from '../../AppTheme';
import Icon from '../atoms/Icon';

const useStyles = makeStyles({
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    borderRadius: 6,
  },
});

const ListItemWithSecondary = ({ item, primaryAction, secondaryAction, secondaryIcon }) => {
  const styles = useStyles();

  const findImage = () => {
    return (
      item.image ||
      retrieveVideoImage(item.firstVideo || item.youtube_code || item?.songs[0]?.youtube_code)
    );
  };

  const currentVideoId = useSelector((state) => state.player.currentVideoId, shallowEqual);
  const isFetching = useSelector((state) => state.userPlaylists.isFetching, shallowEqual);
  const itemToBeAdded = useSelector((state) => state.userPlaylists.itemToBeAdded, shallowEqual);


  return (
    <ListItem
      button={Boolean(primaryAction)}
      divider
      onClick={() => primaryAction(item)}
      selected={currentVideoId && item?.youtube_code && currentVideoId === item?.youtube_code}
    >
      <ListItemAvatar>
        <Avatar src={findImage()} className={styles.avatar} />
      </ListItemAvatar>
      <ListItemText
        primary={item.title || item.name}
        secondary={item.description || item.type === 'video' ? item.author : item.count}
      />
      <ListItemSecondaryAction>
        {item.existed ? (
          <IconButton color="primary" edge="end" onClick={secondaryAction}>
            <SvgIcon component={checked} viewBox="0 0 24 24" />
          </IconButton>
        ) : (
          <IconButton color="primary" edge="end" onClick={secondaryAction}>
            {secondaryIcon ? (
              isFetching && item?.youtube_playlist_code === itemToBeAdded ? <Box key={0} display="flex" alignItems="center" justifyContent="center" p="2">
                <CircularProgress color="primary" size={30} />
              </Box> :
                <Icon icon={secondaryIcon} />
            ) : (
              <SvgIcon component={checked} viewBox="0 0 24 24" />
            )}
          </IconButton>
        )}

      </ListItemSecondaryAction>
    </ListItem>
  );
};

ListItemWithSecondary.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    thumbnail: PropTypes.string,
    videoCount: PropTypes.string,
    videoId: PropTypes.string,
    youtube_code: PropTypes.string,
    type: PropTypes.string,
    author: PropTypes.string,
    existed: PropTypes.bool,
  }).isRequired,
  primaryAction: PropTypes.func,
  secondaryAction: PropTypes.func.isRequired,
  secondaryIcon: PropTypes.oneOf(['addToList', 'addToLibrary']).isRequired,
};

ListItemWithSecondary.defaultProps = {
  primaryAction: null,
};

export default ListItemWithSecondary;
