/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
// @flow
import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { debounce } from 'lodash';
import { Collapse, Box, Toolbar, AppBar } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import {
  setLibrarySearchValue,
  fetchYoutubeStart,
  clearYoutubeResults,
} from 'actions/searchActions';
import { setShowSearchLibrary } from 'actions/headerActions';
import SearchResults from 'components/searchResults/SearchResultsLibrary';
import { addTrackToUserPlaylist, setActiveTabLibrary } from 'actions/favoritePlaylistsActions';
import Header from '../../components/partials/Header';
import Content from '../../components/partials/Content';
import AppTabs from '../../components/partials/AppTabs';
import PaperGridPlaylists from '../../components/papers/PaperGridPlaylists';
import PaperListTracks from '../../components/papers/PaperListTracks';
import PaperSearch from '../../components/papers/PaperSearch';
import PaperListPlaylists from '../../components/papers/PaperListPlaylists';
import playlist from '../../assets/images/playlist.png';
import track from '../../assets/images/track.png';
import DialogPlaylistCreate from '../../dialogs/DialogPlaylistCreate';
import DialogPlaylistNew from '../../dialogs/DialogPlaylistNew';
import Preloader from '../../components/partials/Preloader';
import DialogDrawer from '../../components/dialogs/DialogDrawer';

const tabs = [
  {
    label: 'PLAYLISTS',
  },
  {
    label: 'TRACKS',
  },
];

const GridPlaylistsWithPreloader = Preloader(PaperGridPlaylists);
const ListTracksWithPreloader = Preloader(PaperListTracks);
const SearchResultsWithPreloader = Preloader(SearchResults);

function Library() {
  const history = useHistory();
  const scrollRef = useRef();
  const [playlistName, setPlaylistName] = useState('');
  const [dialogPlaylistCreate, setDialogPlaylistCreate] = useState(false);
  const [dialogPlaylistNew, setDialogPlaylistNew] = useState(false);
  const [selectedSong, setSelectedSong] = useState(null);
  const [valueSearchPlaylists, setValueSearchPlaylists] = useState('');
  const [dialogPlaylistAdd, setDialogPlaylistAdd] = useState(false);

  const isLoadingTracks = useSelector((state) => state.tracks.isFetching, shallowEqual);
  const isLoadingPlaylists = useSelector((state) => state.userPlaylists.isFetching, shallowEqual);
  const isLoadingSearch = useSelector((state) => state.search.isFetching, shallowEqual);
  const activeTab = useSelector((state) => state.userPlaylists.activeTab, shallowEqual);
  const order = useSelector((state) => state.userPlaylists.order);
  const filter = useSelector((state) => state.userPlaylists.filter);
  const searchValue = useSelector((state) => state.search.librarySearchValue, shallowEqual);
  const searchForm = useSelector((state) => state.header.showLibrarySearch, shallowEqual);

  // const [searchForm, setSearchForm] = useState(!!searchValue.length);

  const userPlaylists = useSelector((state) => state.userPlaylists.list, shallowEqual);
  const userPlayguinPlaylists = useSelector(
    (state) => state.userPlaylists.playguinList,
    shallowEqual,
  );

  const RecognitionPlaylist = userPlaylists.find((p) => p.isForRecognition);

  const library = [
    ...userPlayguinPlaylists,
    ...userPlaylists.filter((p) => !p.isForRecognition),
  ].sort((a, b) => {
    if (filter === 'count') return order === 'asc' ? a.count - b.count : b.count - a.count;
    if (filter === 'title') {
      return order === 'asc'
        ? a.title
          ? a.title.localeCompare(b.title || b.translation.gr)
          : a.translation.gr.title.localeCompare(b.title || b.translation.gr.title)
        : b.title
          ? b.title.localeCompare(a.title || a.translation.gr.title)
          : b.translation.gr.title.localeCompare(a.title || a.translation.gr.title);
    }
    if (filter === 'created_at')
      return order === 'asc'
        ? a?.created_at?.seconds - b.created_at?.seconds
        : b?.created_at?.seconds - a.created_at?.seconds;
  });

  if (RecognitionPlaylist) library.unshift(RecognitionPlaylist);

  const dispatch = useDispatch();
  const setSearchValue = (payload) => dispatch(setLibrarySearchValue(payload));
  const searchYoutube = (payload) => dispatch(fetchYoutubeStart(payload));
  const addSongToUserPlaylist = (payload) => dispatch(addTrackToUserPlaylist(payload));
  const clearSearchResults = () => dispatch(clearYoutubeResults());
  const setActiveTab = (payload) => dispatch(setActiveTabLibrary(payload));
  const setSearchForm = (payload) => dispatch(setShowSearchLibrary(payload));

  const handleOpenDialogCreate = (value) => {
    setDialogPlaylistCreate(value);
  };

  const handleOpenDialogNew = (value) => {
    setDialogPlaylistNew(value);
  };

  const handleDialogSearchValue = debounce((value) => {
    setValueSearchPlaylists(value);
  }, 1000);

  const handleSearchValue = debounce((value) => {
    setSearchValue(value);
    if (!value) clearSearchResults();
    if (value && value.length > 1) searchYoutube({ searchText: value });
  }, 1000);

  useEffect(() => {
    if (!dialogPlaylistNew) {
      setPlaylistName('');
    }
  }, [dialogPlaylistNew]);

  return (
    <>
      {/*
      todo mastora na perastoun sto component AppBarTop
      <Header
        title="Library"
        isSearching={searchForm}
        hasSearch
        searchAction={() => {
          clearSearchResults();
          setSearchForm(!searchForm);
          setSearchValue('');
        }}
      />
      */}
      <Content
        propRef={scrollRef}
        withAppBar={
          <AppTabs
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            scrollRef={scrollRef}
          />
        }
        withAppBarTabs
        searchForm={searchForm}
        setSearchValue={handleSearchValue}
        searchValue={searchValue}
        withSearch={
          <SearchResultsWithPreloader
            searchValue={searchValue}
            closeSearch={() => setSearchForm(false)}
            isLoading={isLoadingSearch}
          />
        }
      >
        <Collapse in={activeTab === 0} unmountOnExit addEndListener={() => { }}>
          <Box pb={3}>
            <GridPlaylistsWithPreloader
              isLoading={isLoadingPlaylists}
              playlists={library}
              addPlaylistAction={() => setDialogPlaylistCreate(true)}
              playlistAction={() => history.push('/library/playlist')}
            />
          </Box>
        </Collapse>
        <Collapse in={activeTab === 1} unmountOnExit addEndListener={() => { }}>
          <Box pb={3}>
            <ListTracksWithPreloader
              isLoading={isLoadingTracks}
              favoriteAction={(e) => console.log('favoriteAction', e)}
              addPlaylistAction={(item) => {
                setDialogPlaylistAdd(true);
                setSelectedSong(item);
              }}
            />
          </Box>
        </Collapse>
      </Content>
      <DialogDrawer setOpen={setDialogPlaylistAdd} open={dialogPlaylistAdd} title="Playlists">
        <AppBar position="sticky" color="transparent">
          <Box pb={2}>
            <Toolbar>
              <PaperSearch value={valueSearchPlaylists} onValueChange={handleDialogSearchValue} />
            </Toolbar>
          </Box>
        </AppBar>
        <PaperListPlaylists
          playlists={library.filter(({ createdByUser: isCustom }) => isCustom)}
          selectedSong={selectedSong}
          searchValue={valueSearchPlaylists}
          primaryAction={() => { }}
          createAction={() => {
            // setDialogPlaylistAdd(false);
            setDialogPlaylistCreate(true);
          }}
          addToMyTracksAction={null}
          secondaryAction={(currPlaylist) => {
            if (currPlaylist.songs.every((s) => s.youtube_code !== selectedSong?.youtube_code)) {
              addSongToUserPlaylist({
                ...currPlaylist,
                count: currPlaylist.count + 1,
                songs: [...currPlaylist.songs, selectedSong],
              });
              // setDialogPlaylistAdd(false);
            }
          }}
        />
      </DialogDrawer>
      {dialogPlaylistCreate && (
        <DialogPlaylistCreate
          open={dialogPlaylistCreate}
          setOpen={handleOpenDialogCreate}
          playlistName={playlistName}
          setPlaylistName={setPlaylistName}
          createPlaylistAction={(name) => {
            setPlaylistName(name);
            setDialogPlaylistCreate(false);
            setDialogPlaylistNew(true);
          }}
        />
      )}
      {dialogPlaylistNew && (
        <DialogPlaylistNew
          open={dialogPlaylistNew}
          setOpen={handleOpenDialogNew}
          playlistName={playlistName}
          selectedSong={selectedSong}
        />
      )}
    </>
  );
}

export default Library;
