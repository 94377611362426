/* eslint-disable no-underscore-dangle */
import { CategoriesActionTypes } from 'actions/categoriesActions';

const INITIAL_STATE = {
  list: null,
  isFetching: false,
  lastVisible: null,
  limit: 20,
  hasMore: false,
  errorMessage: undefined,
};

const categoryAllReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CategoriesActionTypes.FETCH_CATEGORY_BY_ID_START:
      return {
        ...state,
        isFetching: !state.lastVisible,
      };
    case CategoriesActionTypes.FETCH_CATEGORY_BY_ID_SUCCESS:
      return {
        ...state,
        isFetching: false,
        hasMore: action.payload.playlists.length === state.limit,
        list: [...(state.list ? state.list : []), ...action.payload.playlists],
      };
    case CategoriesActionTypes.FETCH_CATEGORY_BY_ID_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case CategoriesActionTypes.SET_CATEGORY_LAST_VISIBLE_ITEM:
      return {
        ...state,
        lastVisible: action.payload,
      };
    case CategoriesActionTypes.CLEAR_VIEW_ALL_CATEGORIES:
      return {
        ...state,
        list: null,
        isFetching: false,
        lastVisible: null,
        hasMore: false,
        errorMessage: undefined,
      };
    default:
      return state;
  }
};

export default categoryAllReducer;
