import React from 'react';
import { Paper, Container, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import theme from '../../AppTheme';
import CardBanner from '../cards/CardBanner';

const useStyles = makeStyles({
  paper: {
    backgroundColor: 'transparent',
  },
  container: {
    paddingRight: 0,
  },
  slider: {
    '& .slick-slide': {
      paddingRight: theme.spacing(2),
    },
  },
});

const settings = {
  arrows: false,
  dots: false,
  infinite: false,
  speed: theme.transitions.duration.complex,
  slidesToShow: 1.1,
  slidesToScroll: 1,
  swipeToSlide: true,
};

const PaperSliderBanners = ({ banners }) => {
  const styles = useStyles();
  return (
    <Paper elevation={0} square className={styles.paper}>
      <Container className={styles.container}>
        <Slider {...settings} className={styles.slider}>
          {banners.map((banner) => {
            return (
              <CardBanner
                key={banner.id}
                action={() => console.log('banner')}
                image={banner.image}
              />
            );
          })}
        </Slider>
      </Container>
    </Paper>
  );
};

PaperSliderBanners.propTypes = {
  banners: PropTypes.instanceOf(Array).isRequired,
};

export default PaperSliderBanners;
