// @flow
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Box, Collapse } from '@material-ui/core';
import { debounce } from 'lodash';
import banner from 'assets/images/banner.png';
import Header from 'components/partials/Header';
import avatar from 'assets/images/avatar.png';
import Content from 'components/partials/Content';
import PaperSliderBanners from 'components/papers/PaperSliderBanners';
import PaperSearch from 'components/papers/PaperSearch';
import SearchResults from 'components/searchResults/SearchResults';
import { signOutStart } from 'actions/userActions';
import { fetchCategoriesStart, clearViewAllCategories } from 'actions/categoriesActions';
import { setDrawerAccount } from 'actions/headerActions';
import {
  fetchYoutubeStart,
  setSearchValue as setSearchText,
  clearYoutubeResults,
} from 'actions/searchActions';
import { useHistory } from 'react-router-dom';
import PaperSliderPlaylists from '../../components/papers/PaperSliderPlaylists';
import DrawerAccount from '../../components/drawers/DrawerAccount';
import DialogCamera from '../../dialogs/DialogCamera';
import Preloader from '../../components/partials/Preloader';

const banners = [
  {
    id: 'sfsdffew',
    image: banner,
  },
  {
    id: 'fsdfsd',
    image: banner,
  },
  {
    id: 'ewrv',
    image: banner,
  },
];

const SearchResultsWithPreloader = Preloader(SearchResults);
const PaperSliderPlaylistsWithPreloader = Preloader(PaperSliderPlaylists);

function Featured() {
  const history = useHistory();

  const [dialogCamera, setDialogCamera] = useState(false);

  const searchValue = useSelector((state) => state.search.searchValue, shallowEqual);
  const categories = useSelector((state) => state.categories.list, shallowEqual);
  const isLoadingCategories = useSelector((state) => state.categories.isFetching, shallowEqual);
  const drawerAccount = useSelector((state) => state.header.drawerAccount, shallowEqual);
  const isLoading = useSelector((state) => state.search.isFetching, shallowEqual);

  const dispatch = useDispatch();
  const signOut = () => dispatch(signOutStart());
  const fetchCategories = () => dispatch(fetchCategoriesStart());
  const searchYoutube = (payload) => dispatch(fetchYoutubeStart(payload));
  const setSearchValue = (payload) => dispatch(setSearchText(payload));
  const setDrawerAccountOpen = (payload) => dispatch(setDrawerAccount(payload));
  const clearSearchResults = () => dispatch(clearYoutubeResults());
  const clearViewAll = () => dispatch(clearViewAllCategories());

  const [profileAvatar, setProfileAvatar] = useState(null);

  const handleSearchValue = debounce((value) => {
    setSearchValue(value);
    if (!value) clearSearchResults();
    if (value && value.length > 1) searchYoutube({ searchText: value });
  }, 1000);

  useEffect(() => {
    if (!categories) fetchCategories();
  }, [categories, fetchCategories]);

  return (
    <>
      {/* <Header title="Playguin" avatarAction={() => setDrawerAccount(true)} avatar={avatar} /> */}
      <Content
        withAppBar={
          <PaperSearch
            value={searchValue}
            actionClose={clearSearchResults}
            onValueChange={handleSearchValue}
          />
        }
      >
        {/* section main  */}
        <Collapse in={searchValue === ''} addEndListener={() => { }} unmountOnExit>
          {/* <Box py={3}>
            <PaperSliderBanners banners={banners} />
          </Box> */}
          {categories?.map(({ playlists: lists, id, translation }, index) => (
            <Box py={3} key={`${translation?.gr?.title}-${id}`}>
              <PaperSliderPlaylistsWithPreloader
                isLoading={isLoadingCategories}
                categoryId={id}
                title={translation?.gr?.title}
                playlists={lists.filter(({ songs }) => songs?.length)}
                action={() => {
                  clearViewAll();
                  history.push(`/featured/category/${id}`);
                }}
              />
            </Box>
          ))}
        </Collapse>
        {/* section search  */}
        <Collapse in={searchValue !== ''} addEndListener={() => { }} unmountOnExit>
          <SearchResultsWithPreloader isLoading={isLoading} />
        </Collapse>
      </Content>
      <DrawerAccount
        setOpen={setDrawerAccountOpen}
        open={drawerAccount}
        deleteAction={() => {
          setDrawerAccountOpen(false);
        }}
        cameraAction={(image) => {
          setProfileAvatar(image)
          setDrawerAccountOpen(false);
          setDialogCamera(true);
        }}
        galleryAction={() => {
          setDrawerAccountOpen(false);
        }}
        logoutAction={signOut}
      />
      <DialogCamera profileAvatar={profileAvatar} setOpen={setDialogCamera} open={dialogCamera} />
    </>
  );
}

export default Featured;
