import React from 'react';
import {
  Button,
  Box,
  Typography,
  Paper,
  Container,
  Grid,
  SvgIcon,
  makeStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { setSelectedPlaylist } from 'actions/playlistsActions';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import theme from '../../AppTheme';
import CardPlaylist from '../cards/CardPlaylist';
import { ReactComponent as chevronRight } from '../../assets/icons/ic_chevron_right.svg';

const useStyles = makeStyles({
  paper: {
    backgroundColor: 'transparent',
  },
  container: {
    paddingRight: 0,
  },
  slider: {
    '& .slick-slide': {
      paddingRight: theme.spacing(2),
    },
  },
});

const settings = {
  arrows: false,
  dots: false,
  infinite: false,
  speed: theme.transitions.duration.complex,
  slidesToShow: 2.2,
  slidesToScroll: 1,
  swipeToSlide: true,
};

const PaperSliderPlaylists = ({ title, action, playlists, categoryId }) => {
  const styles = useStyles();
  const history = useHistory();

  const dispatch = useDispatch();
  const setPlaylistInfo = (payload) => dispatch(setSelectedPlaylist(payload));

  return (
    <Paper elevation={0} square className={styles.paper}>
      <Container>
        <Box pb={2}>
          <Grid container spacing={2} alignItems="center" justify="space-between">
            <Grid item>
              <Typography variant="h5">{title}</Typography>
            </Grid>
            <Grid item>
              <Button
                edge="end"
                color="primary"
                endIcon={<SvgIcon component={chevronRight} viewBox="0 0 24 24" />}
                onClick={() => action()}
              >
                View all
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Container className={styles.container}>
        <Slider {...settings} className={styles.slider}>
          {playlists.map(
            ({
              id,
              translation,
              image256,
              image640,
              type,
              gender,
              songs,
              count,
              youtube_playlist_code: listId,
            }) => {
              return (
                <CardPlaylist
                  key={id}
                  playlistType={type}
                  songs={count}
                  action={() => {
                    if (songs?.length) {
                      setPlaylistInfo({
                        categoryId,
                        id,
                        image: image640,
                        videos: songs,
                        gender,
                        playlistType: type,
                        title: translation?.gr?.title,
                        code: listId || null,
                      });
                      history.push(`/playlist/${listId || id}`);
                    }
                  }}
                  title={translation?.gr?.title}
                  image={image256}
                />
              );
            },
          )}
        </Slider>
      </Container>
    </Paper>
  );
};

PaperSliderPlaylists.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  playlists: PropTypes.instanceOf(Array).isRequired,
  categoryId: PropTypes.string.isRequired,
};

export default PaperSliderPlaylists;
