/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import {
  Button,
  Box,
  Container,
  Typography,
  Paper,
  List,
  Grid,
  SvgIcon,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { fetchFavoritePlaylistsStart } from 'actions/favoritePlaylistsActions';

import theme from '../../AppTheme';
import { ReactComponent as chevronRight } from '../../assets/icons/ic_chevron_right.svg';
import ListItemPlaylist from '../listItems/ListItemPlaylist';
import ListItemPlaylistCreate from '../listItems/ListItemPlaylistCreate';
import ListItemWithSecondary from '../listItems/ListItemWithSecondary';

const useStyles = makeStyles({
  paper: {
    backgroundColor: 'transparent',
  },
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    borderRadius: 6,
  },
});

const PaperListPlaylists = ({
  title,
  allAction,
  createAction,
  showAddToMyTracks,
  primaryAction,
  secondaryAction,
  addToMyTracksAction,
  playlists,
  searchValue,
  selectedSong,
  hasInfiniteScroll,
}) => {
  const styles = useStyles();

  const hasMore = useSelector((state) => state.userPlaylists.hasMore, shallowEqual);

  const dispatch = useDispatch();
  const fetchUserPlaylists = () => dispatch(fetchFavoritePlaylistsStart());

  return (
    <Paper elevation={0} square className={styles.paper}>
      {title && allAction && (
        <Container maxWidth={false}>
          <Box pb={2}>
            <Grid container spacing={2} alignItems="center" justify="space-between">
              <Grid item>
                <Typography variant="h5" color="primary">
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  endIcon={<SvgIcon component={chevronRight} viewBox="0 0 24 24" />}
                  onClick={() => allAction()}
                >
                  View all
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      )}
      <Container maxWidth={false} disableGutters>
        <List
          disablePadding
          style={
            hasInfiniteScroll
              ? {
                  height: '500px',
                  overflow: 'auto',
                }
              : undefined
          }
        >
          {showAddToMyTracks && addToMyTracksAction && (
            <ListItemPlaylistCreate title="Add to My Tracks" createAction={addToMyTracksAction} />
          )}
          {createAction && (
            <ListItemPlaylistCreate title="Add to New Playlist" createAction={createAction} />
          )}
          {hasInfiniteScroll ? (
            <InfiniteScroll
              pageStart={0}
              initialLoad={false}
              loadMore={() => {
                fetchUserPlaylists();
              }}
              hasMore={hasMore}
              useWindow={false}
              loader={
                <Box key={0} display="flex" alignItems="center" justifyContent="center" p="2">
                  <CircularProgress color="primary" size={56} />
                </Box>
              }
              threshold={10}
            >
              {playlists
                .filter((p) => {
                  if (searchValue) return p.title.toLowerCase().includes(searchValue.toLowerCase());
                  return p;
                })
                .map((playlist, index) => {
                  return (
                    <ListItemWithSecondary
                      key={`${playlist.youtube_playlist_code}${index}`}
                      item={playlist}
                      primaryAction={() => primaryAction(playlist.youtube_playlist_code)}
                      secondaryAction={() => secondaryAction(playlist)}
                      secondaryIcon={
                        selectedSong &&
                        playlist.songs.some((s) => s.youtube_code === selectedSong?.youtube_code)
                          ? ''
                          : 'addToLibrary'
                      }
                    />
                  );
                })}
            </InfiniteScroll>
          ) : (
            playlists
              .filter((p) => {
                if (searchValue) return p.title.toLowerCase().includes(searchValue.toLowerCase());
                return p;
              })
              .map((playlist, index) => {
                return (
                  <ListItemWithSecondary
                    key={`${playlist.youtube_playlist_code}${index}`}
                    item={playlist}
                    primaryAction={() => primaryAction(playlist.youtube_playlist_code)}
                    secondaryAction={() => secondaryAction(playlist)}
                    secondaryIcon="addToLibrary"
                  />
                );
              })
          )}
        </List>
      </Container>
    </Paper>
  );
};

PaperListPlaylists.propTypes = {
  title: PropTypes.string,
  allAction: PropTypes.func,
  createAction: PropTypes.func,
  addToMyTracksAction: PropTypes.func,
  primaryAction: PropTypes.func,
  secondaryAction: PropTypes.func,
  playlists: PropTypes.instanceOf(Array).isRequired,
  searchValue: PropTypes.string,
  hasInfiniteScroll: PropTypes.bool,
  showAddToMyTracks: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  selectedSong: PropTypes.object,
};

PaperListPlaylists.defaultProps = {
  title: null,
  allAction: null,
  createAction: null,
  primaryAction: null,
  secondaryAction: null,
  addToMyTracksAction: null,
  searchValue: '',
  hasInfiniteScroll: true,
  showAddToMyTracks: true,
  selectedSong: null,
};

export default PaperListPlaylists;
