import React from 'react';
import { Card, CardMedia, CardActionArea, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  card: {
    backgroundColor: 'transparent',
  },
  cardMedia: {
    paddingTop: 'calc(9 / 16 * 100%)',
    borderRadius: 6,
  },
});

const CardBanner = ({ image, action }) => {
  const styles = useStyles();
  return (
    <Card elevation={0} square className={styles.card}>
      <CardActionArea onClick={() => action()}>
        <CardMedia image={image} className={styles.cardMedia} />
      </CardActionArea>
    </Card>
  );
};

CardBanner.propTypes = {
  image: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
};

export default CardBanner;
