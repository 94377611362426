export const AudioRecognitionActionTypes = {
  FETCH_AUDIO_RECOGNITION_START: 'FETCH_AUDIO_RECOGNITION_START',
  FETCH_AUDIO_RECOGNITION_SUCCESS: 'FETCH_AUDIO_RECOGNITION_SUCCESS',
  FETCH_AUDIO_RECOGNITION_FAILURE: 'FETCH_AUDIO_RECOGNITION_FAILURE',
  CLEAR_RECOGNIZED_TRACK: 'CLEAR_RECOGNIZED_TRACK',
  SHOW_SNACKBAR_AUDIO_RECOGNITION: 'SHOW_SNACKBAR_AUDIO_RECOGNITION',
};

export const setShowSnackbar = (payload) => ({
  type: AudioRecognitionActionTypes.SHOW_SNACKBAR_AUDIO_RECOGNITION,
  payload,
});

export const fetchAudioRecognitionStart = (payload) => ({
  type: AudioRecognitionActionTypes.FETCH_AUDIO_RECOGNITION_START,
  payload,
});

export const fetchAudioRecognitionSuccess = (payload) => ({
  type: AudioRecognitionActionTypes.FETCH_AUDIO_RECOGNITION_SUCCESS,
  payload,
});

export const fetchAudioRecognitionFailure = (errorMessage) => ({
  type: AudioRecognitionActionTypes.FETCH_AUDIO_RECOGNITION_FAILURE,
  payload: errorMessage,
});

export const clearRecognizedTrack = () => ({
  type: AudioRecognitionActionTypes.CLEAR_RECOGNIZED_TRACK,
});
