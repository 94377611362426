export const RadioActionTypes = {
  FETCH_RADIOS_START: 'FETCH_RADIOS_START',
  FETCH_RADIOS_SUCCESS: 'FETCH_RADIOS_SUCCESS',
  FETCH_RADIOS_FAILURE: 'FETCH_RADIOS_FAILURE',
  SET_LAST_VISIBLE_ITEM: 'SET_LAST_VISIBLE_ITEM',
  FILTER_RADIOS_BY_TITLE_START: 'FILTER_RADIOS_BY_TITLE_START',
  FILTER_RADIOS_BY_TITLE_SUCCESS: 'FILTER_RADIOS_BY_TITLE_SUCCESS',
  FILTER_RADIOS_BY_TITLE_FAILURE: 'FILTER_RADIOS_BY_TITLE_FAILURE',
  SET_LAST_VISIBLE_BY_TITLE_ITEM: 'SET_LAST_VISIBLE_BY_TITLE_ITEM',
  CLEAR_LIST_BY_TITLE: 'CLEAR_LIST_BY_TITLE',
  FETCH_RADIO_WITH_RELATED_START: 'FETCH_RADIO_WITH_RELATED_START',
  FETCH_RADIO_WITH_RELATED_SUCCESS: 'FETCH_RADIO_WITH_RELATED_SUCCESS',
  FETCH_RADIO_WITH_RELATED_FAILURE: 'FETCH_RADIO_WITH_RELATED_FAILURE',
  FETCH_USER_RADIOS: 'FETCH_USER_RADIOS',
  SET_ACTIVE_TAB_RADIO: 'SET_ACTIVE_TAB_RADIO',
  CLEAR_RADIO_LIST: 'CLEAR_RADIO_LIST',
};

export const clearRadio = () => ({
  type: RadioActionTypes.CLEAR_RADIO_LIST,
});

export const setActiveTabRadio = (payload) => ({
  type: RadioActionTypes.SET_ACTIVE_TAB_RADIO,
  payload,
});

export const fetchUserRadios = (payload) => ({
  type: RadioActionTypes.FETCH_USER_RADIOS,
  payload,
});

export const setLastVisible = (payload) => ({
  type: RadioActionTypes.SET_LAST_VISIBLE_ITEM,
  payload,
});

export const setLastVisibleByTitle = (payload) => ({
  type: RadioActionTypes.SET_LAST_VISIBLE_BY_TITLE_ITEM,
  payload,
});

export const fetchRadiosStart = () => ({
  type: RadioActionTypes.FETCH_RADIOS_START,
});

export const fetchRadiosSuccess = (payload) => ({
  type: RadioActionTypes.FETCH_RADIOS_SUCCESS,
  payload,
});

export const fetchRadiosFailure = (errorMessage) => ({
  type: RadioActionTypes.FETCH_RADIOS_FAILURE,
  payload: errorMessage,
});

export const fetchRadiosByTitleStart = (payload) => ({
  type: RadioActionTypes.FILTER_RADIOS_BY_TITLE_START,
  payload,
});

export const fetchRadiosByTitleSuccess = (payload) => ({
  type: RadioActionTypes.FILTER_RADIOS_BY_TITLE_SUCCESS,
  payload,
});

export const fetchRadiosByTitleFailure = (errorMessage) => ({
  type: RadioActionTypes.FILTER_RADIOS_BY_TITLE_FAILURE,
  payload: errorMessage,
});

export const clearListByTitle = () => ({
  type: RadioActionTypes.CLEAR_LIST_BY_TITLE,
});

export const fetchRadioWithRelatedStart = (payload) => ({
  type: RadioActionTypes.FETCH_RADIO_WITH_RELATED_START,
  payload,
});

export const fetchRadioWithRelatedSuccess = (payload) => ({
  type: RadioActionTypes.FETCH_RADIO_WITH_RELATED_SUCCESS,
  payload,
});

export const fetchRadioWithRelatedFailure = (errorMessage) => ({
  type: RadioActionTypes.FETCH_RADIO_WITH_RELATED_FAILURE,
  payload: errorMessage,
});
