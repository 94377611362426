// @flow
import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import theme from 'AppTheme';
import { ReactComponent as close } from '../../assets/icons/ic_close.svg';

const useStyles = makeStyles({
  appBar: {},
  toolBar: {
    justifyContent: 'space-between',
  },
});

const AppBarDialog = ({ title, closeAction }) => {
  const styles = useStyles();
  return (
    <AppBar elevation={0} color="transparent" position="static" className={styles.appBar}>
      <Toolbar className={styles.toolBar}>
        <Typography variant="h4" color="primary">
          {title}
        </Typography>
        <IconButton onClick={() => closeAction()}>
          <SvgIcon component={close} viewBox="0 0 24 24" />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

AppBarDialog.propTypes = {
  title: PropTypes.string.isRequired,
  closeAction: PropTypes.func.isRequired,
};

export default AppBarDialog;
