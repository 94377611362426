import React from 'react';
import { AppBar, Box, makeStyles, Toolbar } from '@material-ui/core';
import PropTypes from "prop-types";
import theme from '../../AppTheme';

const useStyles = makeStyles({
  appBar: {
    top: theme.spacing(7),
    backgroundColor: theme.palette.background.paper,
  },
  toolBar: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'block',
  },
});

const WithAppBar = ({ children }) => {
  const styles = useStyles();
  return (
    <AppBar className={styles.appBar} color="transparent" elevation={0} position="sticky">
      <Toolbar className={styles.toolBar}>
          {children}
      </Toolbar>
    </AppBar>
  )
};

WithAppBar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element,PropTypes.array,PropTypes.object]).isRequired,
};

export default WithAppBar;