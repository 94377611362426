export const FavoriteTracksActionTypes = {
  FETCH_FAVORITE_TRACKS_START: 'FETCH_FAVORITE_TRACKS_START',
  FETCH_FAVORITE_TRACKS_SUCCESS: 'FETCH_FAVORITE_TRACKS_SUCCESS',
  FETCH_FAVORITE_TRACKS_FAILURE: 'FETCH_FAVORITE_TRACKS_FAILURE',
  ADD_FAVORITE_TRACK: 'ADD_FAVORITE_TRACK',
  ADD_FAVORITE_TRACK_SUCCESS: 'ADD_FAVORITE_TRACK_SUCCESS',
  ADD_FAVORITE_TRACK_FAILURE: 'ADD_FAVORITE_TRACK_FAILURE',
  REMOVE_FAVORITE_TRACK: 'REMOVE_FAVORITE_TRACK',
  SET_TRACK_LAST_VISIBLE_ITEM: 'SET_TRACK_LAST_VISIBLE_ITEM',
  CLEAR_TRACK_LIST: 'CLEAR_TRACK_LIST',
  SET_ORDER_TRACKS: 'SET_ORDER_TRACKS',
  SET_FILTER_TRACKS: 'SET_FILTER_TRACKS',
};

export const setOrderTracks = (payload) => ({
  type: FavoriteTracksActionTypes.SET_ORDER_TRACKS,
  payload,
});

export const setFilterTracks = (payload) => ({
  type: FavoriteTracksActionTypes.SET_FILTER_TRACKS,
  payload,
});

export const addFavoriteTrackSuccess = (payload) => ({
  type: FavoriteTracksActionTypes.ADD_FAVORITE_TRACK_SUCCESS,
  payload,
});
export const addFavoriteTrackFailure = (payload) => ({
  type: FavoriteTracksActionTypes.ADD_FAVORITE_TRACK_FAILURE,
  payload,
});

export const clearTrackList = () => ({
  type: FavoriteTracksActionTypes.CLEAR_TRACK_LIST,
});

export const setLastVisible = (payload) => ({
  type: FavoriteTracksActionTypes.SET_TRACK_LAST_VISIBLE_ITEM,
  payload,
});

export const addFavoriteTrack = (payload) => ({
  type: FavoriteTracksActionTypes.ADD_FAVORITE_TRACK,
  payload,
});

export const removeFavoriteTrack = (payload) => ({
  type: FavoriteTracksActionTypes.REMOVE_FAVORITE_TRACK,
  payload,
});

export const fetchFavoriteTracksStart = () => ({
  type: FavoriteTracksActionTypes.FETCH_FAVORITE_TRACKS_START,
});

export const fetchFavoriteTracksSuccess = (payload) => ({
  type: FavoriteTracksActionTypes.FETCH_FAVORITE_TRACKS_SUCCESS,
  payload,
});

export const fetchFavoriteTracksFailure = (errorMessage) => ({
  type: FavoriteTracksActionTypes.FETCH_FAVORITE_TRACKS_FAILURE,
  payload: errorMessage,
});
