import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { fetchRadioGendersStart } from 'actions/genresActions';
import { AppBar, makeStyles, Toolbar } from '@material-ui/core';
import PaperSearch from '../components/papers/PaperSearch';
import PaperListGenres from '../components/papers/PaperListGenres';
import DialogDrawer from '../components/dialogs/DialogDrawer';
import Preloader from '../components/partials/Preloader';
import theme from '../AppTheme';

const ListGenresWithPreloader = Preloader(PaperListGenres);

const useStyles = makeStyles({
  appBar: {
    backgroundColor: '#081523',
  },
  toolBar: {
    paddingBottom: theme.spacing(3),
    justifyContent: 'space-between',
  },
});

const DialogGenres = ({ radioGenders, open, setOpen }) => {
  const styles = useStyles();
  const [searchValue, setSearchValue] = useState('');

  const isLoading = useSelector((state) => state.genres.isFetching, shallowEqual);

  const dispatch = useDispatch();
  const fetchRadioGenders = () => dispatch(fetchRadioGendersStart());

  useEffect(() => {
    if (open && !radioGenders.length) fetchRadioGenders();
  }, [open, fetchRadioGenders]);

  const handleSearchValue = useCallback((value) => {
    setSearchValue(value);
  }, []);

  return (
    <DialogDrawer title="Genres" open={open} setOpen={setOpen}>
      <AppBar className={styles.appBar} color="transparent" elevation={0} position="sticky">
        <Toolbar className={styles.toolBar}>
          <PaperSearch value={searchValue} keyPressEnabled={false} onValueChange={handleSearchValue} />
        </Toolbar>
      </AppBar>
      <ListGenresWithPreloader
        isLoading={isLoading}
        searchValue={searchValue}
        genres={radioGenders}
      />
    </DialogDrawer>
  );
};

DialogGenres.propTypes = {
  radioGenders: PropTypes.instanceOf(Array).isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default DialogGenres;
