import { all, call, takeLatest, select, put } from 'redux-saga/effects';

import { UserActionTypes, addFavoriteRadioSuccess, addFavoriteRadioSuccessList } from 'actions/userActions';
import { firestore } from 'firebase/firebase.utils';

export function* updateFavoriteRadiosInFirebase({ payload }) {
  const user = yield select((state) => state.user);
  if (user) {
    try {
      const userRef = firestore.doc(`users/${user.currentUser.id}`);
      yield userRef.update({ radios: [...user.radios, { id: payload.id, added_at: payload.added_at }] });
      const snapshot = yield userRef.get();
      yield put(
        addFavoriteRadioSuccess(snapshot.data().radios),
      );
      yield put(
        addFavoriteRadioSuccessList({ ...payload, added_at: snapshot.data().radios.find(({ id }) => id === payload.id).added_at }),
      );

    } catch (error) {
      console.log(error);
    }
  }
}

export function* onFavoriteRadioChange() {
  yield takeLatest(
    UserActionTypes.ADD_FAVORITE_RADIO,
    updateFavoriteRadiosInFirebase,
  );
}

export function* deleteFavoriteRadiosInFirebase({ payload }) {
  const user = yield select((state) => state.user);
  if (user) {
    try {
      const userRef = firestore.doc(`users/${user.currentUser.id}`);
      yield userRef.update({ radios: user.radios });
    } catch (error) {
      console.log(error);
    }
  }
}

export function* onFavoriteRadioDelete() {
  yield takeLatest(
    UserActionTypes.REMOVE_FAVORITE_RADIO,
    deleteFavoriteRadiosInFirebase,
  );
}

export function* favoriteRadioSagas() {
  yield all([call(onFavoriteRadioChange), call(onFavoriteRadioDelete)]);
}
