import React, { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  Container,
  Paper,
  List,
  makeStyles,
  SvgIcon,
  Button,
  Menu,
  MenuItem,
  CircularProgress,
  Box,
  Grid,
  ButtonGroup,
} from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroller';
import PropTypes from 'prop-types';
import {
  fetchFavoriteTracksStart,
  removeFavoriteTrack,
  setFilterTracks,
  setOrderTracks,
} from 'actions/favoriteTracksActions';
import { setPlayerSong, setPlaylistPlay } from 'actions/playerActions';
import theme from '../../AppTheme';
import ListItemSwiper from '../listItems/ListItemSwiper';
import { ReactComponent as sort } from '../../assets/icons/ic_sort.svg';
import { ReactComponent as sortReverse } from '../../assets/icons/sort_reverse.svg';

const useStyles = makeStyles({
  paper: {
    backgroundColor: 'transparent',
  },
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    borderRadius: 6,
  },
});

const PaperListTracks = ({ favoriteAction, addPlaylistAction }) => {
  const styles = useStyles();
  const [anchorMenu, setAnchorMenu] = useState(null);

  const filters = { name: 'ALPHABETICAL', created_at: 'DATE ADDED' };

  const tracks = useSelector((state) => state.tracks.list, shallowEqual);
  const hasMore = useSelector((state) => state.tracks.hasMore, shallowEqual);
  const filter = useSelector((state) => state.tracks.filter, shallowEqual);
  const order = useSelector((state) => state.tracks.order, shallowEqual);
  const isPlay = useSelector((state) => state.player.play, shallowEqual);
  const currSongId = useSelector((state) => state.player?.song?.code, shallowEqual);

  const dispatch = useDispatch();
  const fetchTracks = () => dispatch(fetchFavoriteTracksStart());
  const deleteFavoriteTrack = (payload) => dispatch(removeFavoriteTrack(payload));
  const setPlay = () => dispatch(setPlaylistPlay());
  const addSongToPlayer = (payload) => dispatch(setPlayerSong(payload));
  const setFilter = (payload) => dispatch(setFilterTracks(payload));
  const setOrder = (payload) => dispatch(setOrderTracks(payload));

  const clickAction = (code) => {
    addSongToPlayer({ code });
    if (!isPlay) setPlay();
  };
  return (
    <Paper elevation={0} square className={styles.paper}>
      <Container maxWidth={false}>
        <Grid container justify="flex-end">
          <Grid item>
            <ButtonGroup disableElevation variant="text" color="primary">
              <Button
                variant="text"
                color="primary"
                onClick={(e) => setAnchorMenu(e.currentTarget)}
              >
                {filters[filter]}
              </Button>
              <Button
                color="primary"
                endIcon={
                  <SvgIcon component={order === 'asc' ? sortReverse : sort} viewBox="0 0 24 24" />
                }
                onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
              />
            </ButtonGroup>
          </Grid>
        </Grid>
        <Menu
          elevation={0}
          className={styles.menu}
          anchorEl={anchorMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          getContentAnchorEl={null}
          keepMounted
          open={Boolean(anchorMenu)}
          onClose={() => {
            setAnchorMenu(null);
          }}
        >
          {Object.keys(filters)
            .filter((k) => k !== filter)
            .map((key) => (
              <MenuItem
                key={key}
                onClick={() => {
                  setAnchorMenu(null);
                  setFilter(key);
                }}
              >
                {filters[key]}
              </MenuItem>
            ))}
        </Menu>
      </Container>
      <Container maxWidth={false} disableGutters>
        <List >
          <InfiniteScroll
            pageStart={0}
            initialLoad={false}
            loadMore={() => {
              fetchTracks();
            }}
            loader={
              <Box key={0} display="flex" alignItems="center" justifyContent="center" p="2">
                <CircularProgress color="primary" size={56} />
              </Box>
            }
            hasMore={hasMore}
            threshold={10}
          >
            {tracks.map((track, index) => {
              return (
                <ListItemSwiper
                  key={track.id}
                  song={track}
                  clickAction={() => clickAction(track.youtube_code)}
                  favoriteAction={favoriteAction}
                  addPlaylistAction={() =>
                    addPlaylistAction({
                      name: track.name,
                      youtube_code: track.youtube_code,
                    })
                  }
                  deletePlaylistAction={() => deleteFavoriteTrack(track.id)}
                  selected={track.youtube_code === currSongId}
                />
              );
            })}
          </InfiniteScroll>
        </List>
      </Container>
    </Paper>
  );
};

PaperListTracks.propTypes = {
  favoriteAction: PropTypes.func.isRequired,
  addPlaylistAction: PropTypes.func.isRequired,
};

export default PaperListTracks;
