import React, { useState, useEffect } from 'react';
import { makeStyles, Slide, Box, Container, Grid, InputBase, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import theme from '../../AppTheme';

const useStyles = makeStyles({
  input: {
    fontSize: 24,
    textAlign: 'center',
  },
  inputBase: {
    backgroundColor: 'transparent',
  },
});

const PlaylistCreate = ({ createPlaylistAction, playlistName }) => {
  const styles = useStyles();
  const [name, setName] = useState(playlistName ?? '');
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, theme.transitions.duration.enteringScreen);
  }, [loading]);
  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Slide direction="down" in={loading} timeout={theme.transitions.duration.complex}>
            <Grid item xs={12}>
              <InputBase
                fullWidth
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
                classes={{ input: styles.input, root: styles.inputBase }}
                placeholder="Playlist name"
              />
            </Grid>
          </Slide>
          <Slide direction="up" in={loading} timeout={theme.transitions.duration.complex}>
            <Grid item xs={12}>
              <Button
                size="large"
                color="primary"
                variant="contained"
                fullWidth
                onClick={() => createPlaylistAction(name)}
              >
                {playlistName ? 'Rename' : 'Create'}
              </Button>
            </Grid>
          </Slide>
        </Grid>
      </Container>
    </Box>
  );
};

PlaylistCreate.propTypes = {
  createPlaylistAction: PropTypes.func.isRequired,
  playlistName: PropTypes.string,
};

PlaylistCreate.defaultProps = {
  playlistName: null,
};

export default PlaylistCreate;
