import React from 'react';
import { Container, Paper, List, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import theme from '../../AppTheme';
import ListItemCountry from '../listItems/ListItemCountry';

const useStyles = makeStyles({
  paper: {
    backgroundColor: 'transparent',
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(2),
    borderRadius: 0,
  },
  listItemAvatar: {
    minWidth: theme.spacing(5),
  },
});

const PaperListCountries = ({ searchValue, countries }) => {
  const styles = useStyles();
  return (
    <Paper elevation={0} square className={styles.paper}>
      <Container maxWidth={false} disableGutters>
        <List disablePadding>
          {countries
            ?.filter((c) => {
              if (searchValue) return c.title.toLowerCase().includes(searchValue.toLowerCase());
              return c;
            })
            .map((country) => {
              return <ListItemCountry key={country.id} country={country} />;
            })}
        </List>
      </Container>
    </Paper>
  );
};

PaperListCountries.propTypes = {
  countries: PropTypes.instanceOf(Array).isRequired,
  searchValue: PropTypes.string,
};

PaperListCountries.defaultProps = {
  searchValue: '',
};

export default PaperListCountries;
