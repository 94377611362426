/* eslint-disable no-underscore-dangle */
import { CategoriesActionTypes } from 'actions/categoriesActions';

const INITIAL_STATE = {
  list: null,
  isFetching: false,
  errorMessage: undefined,
};

const categoriesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CategoriesActionTypes.FETCH_CATEGORIES_START:
      return {
        ...state,
        isFetching: true,
      };
    case CategoriesActionTypes.FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.payload,
      };
    case CategoriesActionTypes.FETCH_CATEGORIES_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case CategoriesActionTypes.CLEAR_FEATURED_CATEGORIES:
      return {
        ...state,
        list: null,
        isFetching: false,
        errorMessage: undefined,
      };
    default:
      return state;
  }
};

export default categoriesReducer;
