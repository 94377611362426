// @flow
import React from 'react';
import PropTypes from 'prop-types';
import DialogDrawerFull from '../components/dialogs/DialogDrawerFull';
import PlaylistCreate from '../components/partials/PlaylistCreate';

const DialogPlaylistCreate = ({ open, setOpen, createPlaylistAction, playlistName }) => {
  return (
    <DialogDrawerFull setOpen={setOpen} open={open}>
      <PlaylistCreate createPlaylistAction={createPlaylistAction} playlistName={playlistName} />
    </DialogDrawerFull>
  );
};

DialogPlaylistCreate.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  createPlaylistAction: PropTypes.func.isRequired,
  playlistName: PropTypes.shape,
};

DialogPlaylistCreate.defaultProps = {
  playlistName: null,
};

export default DialogPlaylistCreate;
