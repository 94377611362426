import React from 'react';
import {
  Avatar,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  IconButton,
  SvgIcon,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import theme from '../../AppTheme';
import { ReactComponent as libraryAdd } from '../../assets/icons/ic_library_add.svg';

const useStyles = makeStyles({
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    borderRadius: 6,
  },
});

const ListItemPlaylist = ({ playlist, playlistAddAction, selected }) => {
  const styles = useStyles();
  return (
    <ListItem button divider key={playlist.id} onClick={() => console.log('playlist click')} selected={selected}>
      <ListItemAvatar>
        <Avatar src={playlist.image} className={styles.avatar} />
      </ListItemAvatar>
      <ListItemText
        primary={playlist.title}
        secondary={playlist.songs}
        secondaryTypographyProps={{ variant: 'caption' }}
      />
      <ListItemSecondaryAction>
        <IconButton color={'primary'} edge="end" onClick={() => playlistAddAction(playlist)}>
          <SvgIcon component={libraryAdd} viewBox={'0 0 24 24'} />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

ListItemPlaylist.propTypes = {
  playlist: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    songs: PropTypes.number,
    image: PropTypes.string,
  }).isRequired,
  playlistAddAction: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

ListItemPlaylist.defaultProps = {
  selected: false,
};

export default ListItemPlaylist;
