import React from "react";
import { Container, Button, Grid, Paper, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  button: {
    borderRadius: 30,
  }
});

const PaperFilters = ({ countryAction, genreAction }) => {
  const styles = useStyles();
  return (
    <Paper elevation={0} square>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              fullWidth
              className={styles.button}
              onClick={() => countryAction()}
            >
              COUNTRY
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              fullWidth
              className={styles.button}
              onClick={() => genreAction()}
            >
              GENRE
            </Button>
          </Grid>
      </Grid>
    </Paper>
  );
};

PaperFilters.propTypes = {
  countryAction: PropTypes.func.isRequired,
  genreAction: PropTypes.func.isRequired,
};

export default PaperFilters;