/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Container, Paper, List, makeStyles, CircularProgress, Box } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroller';
import PropTypes from 'prop-types';
import { fetchRadiosStart, fetchRadioWithRelatedSuccess } from 'actions/radioActions';
import ListItemWithFavorite from '../listItems/ListItemWithFavorite';

const useStyles = makeStyles({
  paper: {
    backgroundColor: 'transparent',
  },
});

const PaperListRadios = ({ radios, hasInfiniteScroll, primaryAction, secondaryAction }) => {
  const styles = useStyles();
  const hasMore = useSelector((state) => state.radios.hasMore, shallowEqual);
  const currRadioId = useSelector((state) => state.player?.radio?.id, shallowEqual);
  // const userRadios = useSelector((state) => state.user.radios, shallowEqual);
  // const hasMoreForTitle = useSelector((state) => state.radios.hasMoreForTitle, shallowEqual);
  // const isPlay = useSelector((state) => state.player.play, shallowEqual);

  const dispatch = useDispatch();
  const fetchRadios = () => dispatch(fetchRadiosStart());
  // const fetchRadiosByTitle = (payload) => dispatch(fetchRadiosByTitleStart(payload));
  // const setRadioPlayer = (payload) => dispatch(setPlayerRadio(payload));
  const setRadioPlayer = (payload) => dispatch(fetchRadioWithRelatedSuccess(payload));
  // const openRadioPlayer = () => dispatch(setPlaylistPlay());

  // const sortedRadioArr =
  //   radios?.reduce((acc, element) => {
  //     if (userRadios.includes(element.id)) {
  //       return [element, ...acc];
  //     }
  //     return [...acc, element];
  //   }, []) ?? [];

  return (
    <Paper elevation={0} square className={styles.paper}>
      <Container maxWidth={false} disableGutters>
        <List disablePadding>
          {hasInfiniteScroll ? (
            <InfiniteScroll
              pageStart={0}
              initialLoad={false}
              loadMore={() => {
                fetchRadios();
              }}
              hasMore={hasMore}
              loader={
                <Box key={0} display="flex" alignItems="center" justifyContent="center" p="2">
                  <CircularProgress color="primary" size={56} />
                </Box>
              }
            >
              {radios?.map((radio, index) => {
                return (
                  <ListItemWithFavorite
                    key={`${radio.id}-${index}`}
                    item={radio}
                    primaryAction={() => {
                      if (primaryAction) primaryAction();
                      else {
                        console.log(radio.stream_link);
                        setRadioPlayer(radio);
                        // if (!isPlay) openRadioPlayer();
                      }
                    }}
                    selected={radio.id === currRadioId}
                    secondaryAction={secondaryAction}
                  />
                );
              }) ?? []}
            </InfiniteScroll>
          ) : (
            radios.map((radio, index) => {
              return (
                <ListItemWithFavorite
                  key={`${radio.id}-${index}`}
                  item={radio}
                  primaryAction={() => {
                    if (primaryAction) primaryAction();
                    else {
                      setRadioPlayer(radio);
                      // if (!isPlay) openRadioPlayer();
                    }
                  }}
                  selected={radio.id === currRadioId}
                  secondaryAction={secondaryAction}
                />
              );
            }) ?? []
          )}
        </List>
      </Container>
    </Paper>
  );
};

PaperListRadios.propTypes = {
  radios: PropTypes.instanceOf(Array).isRequired,
  primaryAction: PropTypes.func,
  secondaryAction: PropTypes.func,
  hasInfiniteScroll: PropTypes.bool,
};

PaperListRadios.defaultProps = {
  primaryAction: null,
  secondaryAction: null,
  hasInfiniteScroll: false,
};

export default PaperListRadios;
