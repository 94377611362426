export const FavoritePlaylistsActionTypes = {
  FETCH_FAVORITE_PLAYLISTS_START: 'FETCH_FAVORITE_PLAYLISTS_START',
  FETCH_FAVORITE_PLAYLISTS_SUCCESS: 'FETCH_FAVORITE_PLAYLISTS_SUCCESS',
  FETCH_FAVORITE_PLAYGUIN_PLAYLISTS_SUCCESS: 'FETCH_FAVORITE_PLAYGUIN_PLAYLISTS_SUCCESS',
  FETCH_FAVORITE_PLAYLISTS_FAILURE: 'FETCH_FAVORITE_PLAYLISTS_FAILURE',
  ADD_FAVORITE_PLAYLIST_START: 'ADD_FAVORITE_PLAYLIST_START',
  ADD_FAVORITE_PLAYLIST: 'ADD_FAVORITE_PLAYLIST',
  REMOVE_FAVORITE_PLAYLIST: 'REMOVE_FAVORITE_PLAYLIST',
  ADD_USER_CUSTOM_PLAYLIST: 'ADD_USER_CUSTOM_PLAYLIST',
  ADD_USER_CUSTOM_PLAYLIST_SUCCESS: 'ADD_USER_CUSTOM_PLAYLIST_SUCCESS',
  CLEAR_PLAYLIST_LIST: 'CLEAR_PLAYLIST_LIST',
  ADD_TRACK_TO_USER_PLAYLIST: 'ADD_TRACK_TO_USER_PLAYLIST',
  REMOVE_TRACK_TO_USER_PLAYLIST: 'REMOVE_TRACK_TO_USER_PLAYLIST',
  DELETE_USER_PLAYLIST: 'DELETE_USER_PLAYLIST',
  SET_USER_PLAYLIST_LAST_VISIBLE_ITEM: 'SET_USER_PLAYLIST_LAST_VISIBLE_ITEM',
  SET_ACTIVE_TAB_LIBRARY: 'SET_ACTIVE_TAB_LIBRARY',
  SET_ORDER_PLAYLIST: 'SET_ORDER_BY_PLAYLIST',
  SET_FILTER_PLAYLIST: 'SET_FILTER_PLAYLIST',
  RENAME_USER_PLAYLIST_START: 'RENAME_USER_PLAYLIST_START',
  RENAME_USER_PLAYLIST_SUCCESS: 'RENAME_USER_PLAYLIST_SUCCESS',
  RENAME_USER_PLAYLIST_FAILURE: 'RENAME_USER_PLAYLIST_FAILURE',
};

export const renameUserPlaylistFailure= (payload) => ({
  type: FavoritePlaylistsActionTypes.RENAME_USER_PLAYLIST_FAILURE,
  payload,
});

export const renameUserPlaylistSuccess = (payload) => ({
  type: FavoritePlaylistsActionTypes.RENAME_USER_PLAYLIST_SUCCESS,
  payload,
});

export const renameUserPlaylist = (payload) => ({
  type: FavoritePlaylistsActionTypes.RENAME_USER_PLAYLIST_START,
  payload,
});

export const setOrderPlaylist = (payload) => ({
  type: FavoritePlaylistsActionTypes.SET_ORDER_PLAYLIST,
  payload,
});

export const fetchFavoritePlayguinPlaylistsSuccess = (payload) => ({
  type: FavoritePlaylistsActionTypes.FETCH_FAVORITE_PLAYGUIN_PLAYLISTS_SUCCESS,
  payload,
});

export const setFilterPlaylist = (payload) => ({
  type: FavoritePlaylistsActionTypes.SET_FILTER_PLAYLIST,
  payload,
});

export const setActiveTabLibrary = (payload) => ({
  type: FavoritePlaylistsActionTypes.SET_ACTIVE_TAB_LIBRARY,
  payload,
});

export const addUserCustomPlaylistSuccess = (payload) => ({
  type: FavoritePlaylistsActionTypes.ADD_USER_CUSTOM_PLAYLIST_SUCCESS,
  payload,
});

export const setLastVisible = (payload) => ({
  type: FavoritePlaylistsActionTypes.SET_USER_PLAYLIST_LAST_VISIBLE_ITEM,
  payload,
});

export const deleteUserPlaylist = (payload) => ({
  type: FavoritePlaylistsActionTypes.DELETE_USER_PLAYLIST,
  payload,
});

export const removeTrackFromUserPlaylist = (payload) => ({
  type: FavoritePlaylistsActionTypes.REMOVE_TRACK_TO_USER_PLAYLIST,
  payload,
});

export const addTrackToUserPlaylist = (payload) => ({
  type: FavoritePlaylistsActionTypes.ADD_TRACK_TO_USER_PLAYLIST,
  payload,
});

export const clearPlaylistList = () => ({
  type: FavoritePlaylistsActionTypes.CLEAR_PLAYLIST_LIST,
});

export const addFavoritePlaylistStart = (payload) => ({
  type: FavoritePlaylistsActionTypes.ADD_FAVORITE_PLAYLIST_START,
  payload,
});

export const addUserCustomPlaylist = (payload) => ({
  type: FavoritePlaylistsActionTypes.ADD_USER_CUSTOM_PLAYLIST,
  payload,
});

export const addFavoritePlaylist = (payload) => ({
  type: FavoritePlaylistsActionTypes.ADD_FAVORITE_PLAYLIST,
  payload,
});

export const removeFavoritePlaylist = (payload) => ({
  type: FavoritePlaylistsActionTypes.REMOVE_FAVORITE_PLAYLIST,
  payload,
});

export const fetchFavoritePlaylistsStart = () => ({
  type: FavoritePlaylistsActionTypes.FETCH_FAVORITE_PLAYLISTS_START,
});

export const fetchFavoritePlaylistsSuccess = (payload) => ({
  type: FavoritePlaylistsActionTypes.FETCH_FAVORITE_PLAYLISTS_SUCCESS,
  payload,
});

export const fetchFavoritePlaylistsFailure = (errorMessage) => ({
  type: FavoritePlaylistsActionTypes.FETCH_FAVORITE_PLAYLISTS_FAILURE,
  payload: errorMessage,
});
