import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import PlaylistService from 'services/PlaylistService';
import {
  fetchYoutubeSuccess,
  fetchYoutubeFailure,
  SearchActionTypes,
  fetchYoutubeAllSuccess,
  fetchYoutubeAllFailure,
} from 'actions/searchActions';

export function* fetchYoutubeAsync({ payload }) {
  try {
    const data = yield call(PlaylistService.fetchPlaylists, payload);
    yield put(fetchYoutubeSuccess(data));
  } catch (error) {
    yield put(fetchYoutubeFailure(error));
  }
}

export function* fetchYoutubeStart() {
  yield takeLatest(SearchActionTypes.FETCH_YOUTUBE_START, fetchYoutubeAsync);
}

export function* fetchYoutubeAllAsync({ payload }) {
  try {
    const searchValue = yield select((state) => state.search.searchValue);
    const librarySearchValue = yield select((state) => state.search.librarySearchValue);
    const page = yield select((state) => state.search.page);
    const data = yield call(PlaylistService.fetchYoutubeAll, {
      ...payload,
      page,
      searchValue: searchValue || librarySearchValue,
    });
    yield put(fetchYoutubeAllSuccess(data));
  } catch (error) {
    yield put(fetchYoutubeAllFailure(error));
  }
}

export function* fetchYoutubeAllStart() {
  yield takeLatest(SearchActionTypes.FETCH_YOUTUBE_ALL_START, fetchYoutubeAllAsync);
}

export function* youtubeSagas() {
  yield all([call(fetchYoutubeStart), call(fetchYoutubeAllStart)]);
}
