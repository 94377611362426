import { takeLatest, call, put, all } from 'redux-saga/effects';

import {
  fetchAudioRecognitionSuccess,
  fetchAudioRecognitionFailure,
  AudioRecognitionActionTypes,
} from 'actions/audioRecognitionActions';
import { fetchFavoritePlaylistsStart, clearPlaylistList } from 'actions/favoritePlaylistsActions';
import AudioRecognitionService from 'services/AudioRecognitionService';
// import PlaylistService from 'services/PlaylistService';

export function* fetchAudioRecognitionAsync({ payload }) {
  try {
    const data = yield call(
      payload.streamUrl
        ? AudioRecognitionService.fetchRadioRecognition
        : AudioRecognitionService.fetchAudioRecognition,
      payload,
    );
    if (data.youtube_code) {
      yield put(
        fetchAudioRecognitionSuccess({
          track: data,
        }),
      );
      yield put(clearPlaylistList());
      yield put(fetchFavoritePlaylistsStart());
    } else {
      yield put(fetchAudioRecognitionSuccess({ track: null }));
    }
  } catch (error) {
    yield put(fetchAudioRecognitionFailure(error.message));
  }
}

export function* fetchAudioRecognitionStart() {
  yield takeLatest(
    AudioRecognitionActionTypes.FETCH_AUDIO_RECOGNITION_START,
    fetchAudioRecognitionAsync,
  );
}

export function* audioRecognitionSagas() {
  yield all([call(fetchAudioRecognitionStart)]);
}
