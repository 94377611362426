// @flow
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import {
  AudioRecognition,
  Featured,
  FeaturedPlaylist,
  FeaturedCategory,
  Library,
  // LibraryPlaylist,
  Login,
  Radio,
  Search,
} from '../pages';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <PrivateRoute path="/featured" component={Featured} exact />
      <PrivateRoute path="/playlist/:listId" component={FeaturedPlaylist} exact />
      <PrivateRoute path="/featured/category/:catId" component={FeaturedCategory} exact />
      <PrivateRoute path="/search/:type" component={Search} />
      <PrivateRoute path="/radio" component={Radio} />
      <PrivateRoute path="/radio/player" component={Radio} />
      <PrivateRoute path="/library" component={Library} exact />
      {/* <PrivateRoute path="/library/playlist" component={LibraryPlaylist} exact /> */}
      <PrivateRoute path="/audio-recognition" component={AudioRecognition} />
      {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  );
}
