import { takeLatest, call, put, all } from 'redux-saga/effects';

import { firestore, convertCountriesGernesSnapshotToMap } from 'firebase/firebase.utils';

import {
  fetchRadioGendersSuccess,
  fetchRadioGendersFailure,
  GenresActionTypes,
} from 'actions/genresActions';

export function* fetchRadioGendersAsync() {
  try {
    const collectionRef = firestore.collection('radio_genders').orderBy('name');
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(convertCountriesGernesSnapshotToMap, snapshot);
    yield put(fetchRadioGendersSuccess(collectionsMap));
  } catch (error) {
    yield put(fetchRadioGendersFailure(error.message));
  }
}

export function* fetchRadioGerndersStart() {
  yield takeLatest(GenresActionTypes.FETCH_RADIO_GENDERS_START, fetchRadioGendersAsync);
}

export function* genresSagas() {
  yield all([call(fetchRadioGerndersStart)]);
}
