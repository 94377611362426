export const PlaylistsActionTypes = {
  CLEAR_SELECTED_PLAYLIST: 'CLEAR_SELECTED_PLAYLIST',
  SET_SELECTED_PLAYLIST: 'SET_SELECTED_PLAYLIST',
};

export const clearPlaylist = () => ({
  type: PlaylistsActionTypes.CLEAR_SELECTED_PLAYLIST,
});

export const setSelectedPlaylist = (payload) => ({
  type: PlaylistsActionTypes.SET_SELECTED_PLAYLIST,
  payload,
});


