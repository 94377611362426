import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import {
  firestore,
  convertRadiosSnapshotWithRefToMap,
  convertRadioSnapshotWithRef,
} from 'firebase/firebase.utils';

import {
  fetchRadiosSuccess,
  fetchRadiosFailure,
  fetchRadiosByTitleSuccess,
  fetchRadiosByTitleFailure,
  RadioActionTypes,
  setLastVisible,
  fetchRadioWithRelatedSuccess,
  fetchRadioWithRelatedFailure,
} from 'actions/radioActions';

export function* fetchRadiosAsync() {
  try {
    const lastVisible = yield select((state) => state.radios.lastVisible);
    let lastVisibleRef = null;
    if (lastVisible)
      lastVisibleRef = yield firestore.collection('radios').doc(lastVisible.id).get();
    const fCountry = yield select((state) => state.countries.selected);
    const fGenre = yield select((state) => state.genres.filters);
    let collectionRef = firestore.collection('radios').where('is_stream_up', '==', true);
    if (fGenre.length) collectionRef = collectionRef.where('gender', 'array-contains-any', fGenre);
    if (fCountry) collectionRef = collectionRef.where('country', '==', fCountry.code);
    collectionRef = collectionRef
      .orderBy('title')
      .startAfter(lastVisibleRef || 0)
      .limit(lastVisibleRef ? 10 : 100);
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(convertRadiosSnapshotWithRefToMap, snapshot);
    yield put(fetchRadiosSuccess(collectionsMap));
    if (collectionsMap.length) yield put(setLastVisible(collectionsMap[collectionsMap.length - 1]));
  } catch (error) {
    yield put(fetchRadiosFailure(error.message));
  }
}

export function* fetchRadiosStart() {
  yield takeLatest(RadioActionTypes.FETCH_RADIOS_START, fetchRadiosAsync);
}

export function* filterRadiosByTitleAsync({ payload }) {
  try {
    // const lastVisible = yield select((state) => state.radios.lastVisibleByTitle);
    const collectionRef = firestore
      .collection('radios')
      .where('is_stream_up', '==', true)
      // .orderBy('title_search')
      // .startAt(payload.searchText.toLowerCase())
      // .endAt(`${payload.searchText.toLowerCase()}\uf8ff`);
      // .once('value');
      .where('title_search', '>=', payload.searchText.toLowerCase())
      .where('title_search', '<=', `${payload.searchText.toLowerCase()}\uf8ff`)
      .orderBy('title_search', 'asc');
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(convertRadiosSnapshotWithRefToMap, snapshot);
    yield put(fetchRadiosByTitleSuccess(collectionsMap));
    // yield put(setLastVisibleByTitle(snapshot.docs[snapshot.docs.length - 1]));
  } catch (error) {
    yield put(fetchRadiosByTitleFailure(error.message));
  }
}

export function* filterRadiosByTitleStart() {
  yield takeLatest(RadioActionTypes.FILTER_RADIOS_BY_TITLE_START, filterRadiosByTitleAsync);
}

export function* fetchRadioWithRelatedAsync({ payload }) {
  try {
    const collectionRef = firestore.collection('radios').doc(payload);
    const snapshot = yield collectionRef.get();
    const item = yield call(convertRadioSnapshotWithRef, snapshot);
    yield put(fetchRadioWithRelatedSuccess(item));
  } catch (error) {
    yield put(fetchRadioWithRelatedFailure(error.message));
  }
}

export function* fetchRadioWithRelatedStart() {
  yield takeLatest(RadioActionTypes.FETCH_RADIO_WITH_RELATED_START, fetchRadioWithRelatedAsync);
}

export function* radioSagas() {
  yield all([
    call(fetchRadiosStart),
    call(filterRadiosByTitleStart),
    call(fetchRadioWithRelatedStart),
  ]);
}
