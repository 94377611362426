/* eslint-disable consistent-return */
import axios from 'axios';
import { handleErrorResponse } from './ErrorHandling';
import { auth } from '../firebase/firebase.utils';

class PlaylistService {
  fetchPlaylists = async ({ searchText }) => {
    const token = await auth.currentUser.getIdToken();
    const params = {
      searchText,
    };
    try {
      const { data } = await axios({
        method: 'get',
        url: `https://us-central1-firebase-284610.cloudfunctions.net/playguinys/youtube/${auth.currentUser.uid}`,
        params,
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (err) {
      console.log('Error in fetch playlist service:', err);
      handleErrorResponse(err, 'Something went wrong! Playlist does not exists.');
    }
  };

  fetchPlaylistById = async ({ listId }) => {
    const token = await auth.currentUser.getIdToken();

    try {
      const { data } = await axios({
        method: 'get',
        url: `https://us-central1-firebase-284610.cloudfunctions.net/playguinys/playList/${listId}`,
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (err) {
      console.log('Error in fetch playlist service:', err);
      handleErrorResponse(err, 'Something went wrong! Playlist does not exists.');
    }
  };

  fetchYoutubeAll = async ({ type, searchValue, page }) => {
    const token = await auth.currentUser.getIdToken();
    const params = {
      searchValue,
      pages: page,
    };
    try {
      const { data } = await axios({
        method: 'get',
        url: `https://us-central1-firebase-284610.cloudfunctions.net/playguinys/youtube/${auth.currentUser.uid}/${type}`,
        params,
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (err) {
      console.log('Error in fetch youtube all:', err);
      handleErrorResponse(err, 'Something went wrong! Error in fetch youtube all.');
    }
  };
}

const inst = new PlaylistService();
export default inst;
