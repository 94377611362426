export const SearchActionTypes = {
  FETCH_YOUTUBE_START: 'FETCH_YOUTUBE_START',
  FETCH_YOUTUBE_SUCCESS: 'FETCH_YOUTUBE_SUCCESS',
  FETCH_YOUTUBE_FAILURE: 'FETCH_YOUTUBE_FAILURE',
  FETCH_YOUTUBE_TRACKS_START: 'FETCH_YOUTUBE_TRACKS_START',
  FETCH_YOUTUBE_TRACKS_SUCCESS: 'FETCH_YOUTUBE_TRACKS_SUCCESS',
  FETCH_YOUTUBE_TRACKS_FAILURE: 'FETCH_YOUTUBE_TRACKS_FAILURE',
  FETCH_PLAYLIST_BY_ID_START: 'FETCH_PLAYLIST_BY_ID_START',
  FETCH_PLAYLIST_BY_ID_SUCCESS: 'FETCH_PLAYLIST_BY_ID_SUCCESS',
  FETCH_PLAYLIST_BY_ID_FAILURE: 'FETCH_PLAYLIST_BY_ID_FAILURE',
  SET_PAGE_START_END: 'SET_PAGE_START_END',
  SET_SEARCH_VALUE: 'SET_SEARCH_VALUE',
  SET_RADIO_SEARCH_VALUE: 'SET_RADIO_SEARCH_VALUE',
  SET_LIBRARY_SEARCH_VALUE: 'SET_LIBRARY_SEARCH_VALUE',
  FETCH_YOUTUBE_ALL_START: 'FETCH_YOUTUBE_ALL_START',
  FETCH_YOUTUBE_ALL_SUCCESS: 'FETCH_YOUTUBE_ALL_SUCCESS',
  FETCH_YOUTUBE_ALL_FAILURE: 'FETCH_YOUTUBE_ALL_FAILURE',
  CLEAR_YOUTUBE_ALL: 'CLEAR_YOUTUBE_ALL',
  CLEAR_YOUTUBE_RESULTS: 'CLEAR_YOUTUBE_RESULTS',
  TOGGLE_PLAYLIST_EXISTED: 'TOGGLE_PLAYLIST_EXISTED',
  CLEAR_SEARCH_RESULTS: 'CLEAR_SEARCH_RESULTS',
};

export const clearSearchResults = () => ({
  type: SearchActionTypes.TOGGLE_PLAYLIST_EXISTED,
});

export const tooglePlaylistExisted = (payload) => ({
  type: SearchActionTypes.TOGGLE_PLAYLIST_EXISTED,
  payload,
});

export const clearYoutubeResults = () => ({
  type: SearchActionTypes.CLEAR_YOUTUBE_RESULTS,
});

export const clearYoutubeAll = () => ({
  type: SearchActionTypes.CLEAR_YOUTUBE_ALL,
});

export const setRadioSearchValue = (payload) => ({
  type: SearchActionTypes.SET_RADIO_SEARCH_VALUE,
  payload,
});

export const setLibrarySearchValue = (payload) => ({
  type: SearchActionTypes.SET_LIBRARY_SEARCH_VALUE,
  payload,
});

export const setSearchValue = (payload) => ({
  type: SearchActionTypes.SET_SEARCH_VALUE,
  payload,
});

export const setPageStartEnd = (payload) => ({
  type: SearchActionTypes.SET_PAGE_START_END,
  payload,
});

export const fetchYoutubeAllStart = (payload) => ({
  type: SearchActionTypes.FETCH_YOUTUBE_ALL_START,
  payload,
});

export const fetchYoutubeAllSuccess = (payload) => ({
  type: SearchActionTypes.FETCH_YOUTUBE_ALL_SUCCESS,
  payload,
});

export const fetchYoutubeAllFailure = (errorMessage) => ({
  type: SearchActionTypes.FETCH_YOUTUBE_ALL_FAILURE,
  payload: errorMessage,
});

export const fetchYoutubeStart = (payload) => ({
  type: SearchActionTypes.FETCH_YOUTUBE_START,
  payload,
});

export const fetchYoutubeSuccess = (payload) => ({
  type: SearchActionTypes.FETCH_YOUTUBE_SUCCESS,
  payload,
});

export const fetchYoutubeFailure = (errorMessage) => ({
  type: SearchActionTypes.FETCH_YOUTUBE_FAILURE,
  payload: errorMessage,
});

export const fetchYoutubeTracksStart = (payload) => ({
  type: SearchActionTypes.FETCH_YOUTUBE_TRACKS_START,
  payload,
});

export const fetchYoutubeTracksSuccess = (payload) => ({
  type: SearchActionTypes.FETCH_YOUTUBE_TRACKS_SUCCESS,
  payload,
});

export const fetchYoutubeTracksFailure = (errorMessage) => ({
  type: SearchActionTypes.FETCH_YOUTUBE_TRACKS_FAILURE,
  payload: errorMessage,
});

export const fetchPlaylistByIdStart = (payload) => ({
  type: SearchActionTypes.FETCH_PLAYLIST_BY_ID_START,
  payload,
});

export const fetchPlaylistByIdSuccess = (payload) => ({
  type: SearchActionTypes.FETCH_PLAYLIST_BY_ID_SUCCESS,
  payload,
});

export const fetchPlaylistByIdFailure = (errorMessage) => ({
  type: SearchActionTypes.FETCH_PLAYLIST_BY_ID_FAILURE,
  payload: errorMessage,
});
