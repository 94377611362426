// @flow
import React, { useState } from 'react';
import { Box, Button, Container, Grid, Typography, TextField, SvgIcon } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { googleSignInStart, emailSignInStart } from 'actions/userActions';
import { ReactComponent as playguin } from '../../assets/icons/playguin.svg';
import DialogSignUp from '../../dialogs/DialogSignUp';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [dialogSignUp, setDialogSignUp] = useState(false);

  const currentUser = useSelector((state) => state.user.currentUser, shallowEqual);
  const error = useSelector((state) => state.user.error?.message, shallowEqual);
  const dispatch = useDispatch();
  const googleSignIn = () => dispatch(googleSignInStart());
  const emailSignIn = () => dispatch(emailSignInStart({ email, password }));

  const handleEmailSignIn = async (event) => {
    emailSignIn(email, password);
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.target;

    if (name === 'userEmail') {
      setEmail(value);
    } else if (name === 'userPassword') {
      setPassword(value);
    }
  };

  return currentUser ? (
    <Redirect to="/featured" />
  ) : (
    <Container>
      <Container>
        <Box height="calc(100vh - 56px)" display="flex" alignItems="center" justifyContent="center">
          <Grid container spacing={4} justify="center" direction="column">
            <Grid item xs="auto">
              <Box textAlign="center">
                <SvgIcon
                  component={playguin}
                  fontSize="large"
                  viewBox="0 0 264 264"
                  style={{ width: 180, height: 180 }}
                />
              </Box>
              <Typography align="center" variant="h4">
                Sign In
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <TextField
                variant="filled"
                color="primary"
                value={email}
                type="email"
                name="userEmail"
                label="Email"
                fullWidth
                autoComplete="off"
                error={error}
                onChange={(event) => onChangeHandler(event)}
                inputProps={{
                  autoComplete: 'off',
                  form: {
                    autoComplete: 'off',
                  },
                }}
              />
            </Grid>
            <Grid item xs="auto">
              <TextField
                variant="filled"
                color="primary"
                value={password}
                type="password"
                name="userPassword"
                label="Password"
                fullWidth
                error={error}
                autoComplete="off"
                onChange={(event) => onChangeHandler(event)}
                inputProps={{
                  autoComplete: 'new-password',
                  form: {
                    autoComplete: 'off',
                  },
                }}
              />
            </Grid>
            {error !== null && (
              <Grid item xs="auto">
                <Typography align="center" color="error" variant="body1">
                  { error === "Failed to get document because the client is offline." ? " It looks like you're offline." : error}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={() => setDialogSignUp(true)}
                  >
                    Sign up
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={handleEmailSignIn}
                  >
                    Sign in
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs="auto">
              <Typography align="center" variant="body1">
                or
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button color="primary" size="large" fullWidth onClick={googleSignIn}>
                Sign in with Google
              </Button>
            </Grid>
          </Grid>
        </Box>
        <DialogSignUp setOpen={setDialogSignUp} open={dialogSignUp} />
      </Container>
    </Container>
  );
};
export default SignIn;
