import { all, call, takeLatest, select, put } from 'redux-saga/effects';

import {
  FavoritePlaylistsActionTypes,
  renameUserPlaylistFailure,
  renameUserPlaylistSuccess,
  fetchFavoritePlaylistsSuccess,
  fetchFavoritePlaylistsFailure,
  fetchFavoritePlaylistsStart,
  addUserCustomPlaylistSuccess,
  fetchFavoritePlayguinPlaylistsSuccess,
  addFavoritePlaylist,
  removeFavoritePlaylist,
  setFilterPlaylist,
  setLastVisible,
} from 'actions/favoritePlaylistsActions';
import { tooglePlaylistExisted } from 'actions/searchActions';
import { fetchFavoriteTracksStart } from 'actions/favoriteTracksActions';
import { UserActionTypes } from 'actions/userActions';
import { fetchUserRadios } from 'actions/radioActions';
import { setShowRename } from 'actions/headerActions';

import {
  firestore,
  getUserCategoryPlaylists,
  convertCollectionsSnapshotToMap,
  getFavoriteRadioItems,
} from 'firebase/firebase.utils';
import PlaylistService from 'services/PlaylistService';

export function* updateFavoritePlaylistsInFirebase({ payload }) {
  const currentUser = yield select((state) => state.user.currentUser);
  const { existed, ...rest } = payload;
  if (currentUser) {
    try {
      if (existed) {
        const favoritePlaylistRef = yield firestore
          .collection('user_playlists')
          .where('user', '==', currentUser.id)
          .where('youtube_playlist_code', '==', payload.youtube_playlist_code)
          .limit(1)
          .get();
        const docRefId = favoritePlaylistRef.docs[0].ref.id;
        yield favoritePlaylistRef.docs[0].ref.delete();
        yield put(removeFavoritePlaylist(docRefId));
        yield put(tooglePlaylistExisted({ code: payload.youtube_playlist_code }));
      } else {
        const favoritePlaylistRef = firestore.collection('user_playlists');
        const { songs } = yield call(PlaylistService.fetchPlaylistById, {
          listId: payload.youtube_playlist_code,
        });
        const docRef = yield favoritePlaylistRef.add({
          ...rest,
          isForRecognition: false,
          user: currentUser.id,
          created_at: new Date(),
          createdByUser: false,
          songs,
        });
        yield put(
          addFavoritePlaylist({
            ...payload,
            id: docRef.id,
            songs,
          }),
        );
        yield put(tooglePlaylistExisted({ code: payload.youtube_playlist_code }));
        yield put(setFilterPlaylist("title"));
      }
    } catch (error) {
      console.log(error);
    }
  }
}

export function* onFavoritePlaylistsChange() {
  yield takeLatest(
    FavoritePlaylistsActionTypes.ADD_FAVORITE_PLAYLIST_START,
    updateFavoritePlaylistsInFirebase,
  );
}

export function* onUpdateUserPlaylistInFirebase({ payload }) {
  try {
    const favoritePlaylistRef = firestore.collection('user_playlists').doc(payload.id);
    yield favoritePlaylistRef.set(payload, { merge: true });
  } catch (error) {
    console.log(error);
  }
}

export function* onUpdateUserPlaylist() {
  yield takeLatest(
    [
      FavoritePlaylistsActionTypes.REMOVE_TRACK_TO_USER_PLAYLIST,
      FavoritePlaylistsActionTypes.ADD_TRACK_TO_USER_PLAYLIST,
    ],
    onUpdateUserPlaylistInFirebase,
  );
}

export function* onDeleteUserPlaylistInFirebase({ payload }) {
  try {
    const favoritePlaylistRef = firestore.collection('user_playlists').doc(payload);
    yield favoritePlaylistRef.delete();
  } catch (error) {
    console.log(error);
  }
}

export function* onDeleteUserPlaylist() {
  yield takeLatest(
    FavoritePlaylistsActionTypes.DELETE_USER_PLAYLIST,
    onDeleteUserPlaylistInFirebase,
  );
}

export function* addCustomUserPlaylistInFirebase({ payload }) {
  const currentUser = yield select((state) => state.user.currentUser);
  if (currentUser) {
    try {
      const favoritePlaylistRef = firestore.collection('user_playlists');
      const data = yield favoritePlaylistRef.add({
        ...payload,
        isForRecognition: false,
        created_at: new Date(),
        user: currentUser.id,
      });
      yield put(
        addUserCustomPlaylistSuccess({
          ...payload,
          id: data.id,
        }),
      );
      yield put(setFilterPlaylist("title"));
    } catch (error) {
      console.log(error);
    }
  }
}

export function* onAddCustomUserPlaylist() {
  yield takeLatest(
    FavoritePlaylistsActionTypes.ADD_USER_CUSTOM_PLAYLIST,
    addCustomUserPlaylistInFirebase,
  );
}

export function* getUserPlayguinPlaylistsAsync() {
  const playlists = yield select((state) => state.user.playlists);
  const userRadios = yield select((state) => state.user.radios);
  const existingRadios = yield select((state) => state.radios.favoriteRadios);
  const userPlaylists = yield select((state) => state.userPlaylists.list);
  const playguinList = yield select((state) => state.userPlaylists.playguinList);
  const userTracks = yield select((state) => state.tracks.list);
  if (playlists.length && !playguinList?.length) {
    try {
      const results = yield call(getUserCategoryPlaylists, playlists);
      if (results.length) {
        yield put(fetchFavoritePlayguinPlaylistsSuccess(results));
      }
    } catch (error) {
      console.log(error);
    }
  }
  if (userRadios.length && !existingRadios.length) {
    try {
      const results = yield call(getFavoriteRadioItems, userRadios);
      yield put(fetchUserRadios(results));
    } catch (error) {
      console.log(error);
    }
  }
  if (!userTracks.length) yield put(fetchFavoriteTracksStart());
  if (!userPlaylists.length) yield put(fetchFavoritePlaylistsStart());
}

export function* getUserPlayguinPlaylists() {
  yield takeLatest(UserActionTypes.SIGN_IN_SUCCESS, getUserPlayguinPlaylistsAsync);
}

export function* getUserPlaylistsAsync() {
  try {
    const currentUser = yield select((state) => state.user.currentUser);
    const lastVisible = yield select((state) => state.userPlaylists.lastVisible);
    const order = yield select((state) => state.userPlaylists.order);
    const filter = yield select((state) => state.userPlaylists.filter);
    let lastVisibleRef = null;
    if (lastVisible)
      lastVisibleRef = yield firestore.collection('user_playlists').doc(lastVisible.id).get();
    let collectionRef = firestore
      .collection('user_playlists')
      .where('user', '==', currentUser.id)
      .orderBy(filter, order);
    if (lastVisible || order === 'asc') {
      collectionRef = collectionRef.startAfter(lastVisibleRef || 0);
    }
    collectionRef = collectionRef.limit(10);
    const snapshot = yield collectionRef.get();
    const collectionsMap = yield call(convertCollectionsSnapshotToMap, snapshot);
    yield put(fetchFavoritePlaylistsSuccess(collectionsMap));
    if (collectionsMap.length) yield put(setLastVisible(collectionsMap[collectionsMap.length - 1]));
  } catch (error) {
    console.log(error);
    yield put(fetchFavoritePlaylistsFailure(error.message));
  }
}

export function* getUserPlaylists() {
  yield takeLatest(
    [
      FavoritePlaylistsActionTypes.FETCH_FAVORITE_PLAYLISTS_START,
      FavoritePlaylistsActionTypes.SET_ORDER_PLAYLIST,
      FavoritePlaylistsActionTypes.SET_FILTER_PLAYLIST,
    ],
    getUserPlaylistsAsync,
  );
}

export function* renameUserPlaylistsAsync({ payload }) {
  try {
    const favoritePlaylistRef = firestore.collection('user_playlists').doc(payload.id);
    yield favoritePlaylistRef.set({ title: payload.name }, { merge: true });
    yield put(renameUserPlaylistSuccess(payload));
    yield put(setShowRename(false));
  } catch (error) {
    console.log(error);
    yield put(renameUserPlaylistFailure(error.message));
  }
}

export function* renameUserPlaylists() {
  yield takeLatest(
    FavoritePlaylistsActionTypes.RENAME_USER_PLAYLIST_START,
    renameUserPlaylistsAsync,
  );
}

export function* favoritePlaylistsSagas() {
  yield all([
    call(onFavoritePlaylistsChange),
    call(getUserPlayguinPlaylists),
    call(getUserPlaylists),
    call(onAddCustomUserPlaylist),
    call(onUpdateUserPlaylist),
    call(onDeleteUserPlaylist),
    call(renameUserPlaylists),
  ]);
}
