/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Paper, Container, Chip, SvgIcon, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import theme from '../../AppTheme';
import { ReactComponent as close } from '../../assets/icons/ic_close.svg';

const useStyles = makeStyles({
  paper: {
    backgroundColor: 'transparent',
  },
  slider: {
    marginLeft: theme.spacing(-2),
    '& .slick-list': {
      paddingLeft: theme.spacing(2),
    },
    '& .slick-slide': {
      paddingRight: theme.spacing(1),
    },
  },
});

const settings = {
  arrows: false,
  dots: false,
  infinite: false,
  speed: theme.transitions.duration.complex,
  slidesToScroll: 1,
  variableWidth: true,
  swipeToSlide: true,
};

const PaperSliderChips = ({ chips, chipDeleteAction }) => {
  const styles = useStyles();
  return (
    <Paper elevation={0} square className={styles.paper}>
        <Slider {...settings} className={styles.slider}>
          {chips.map((chip, index) => {
            return (
              <React.Fragment key={`${chip.title}-${index}`}>
                <Chip
                  deleteIcon={<SvgIcon component={close} viewBox="0 0 24 25" />}
                  label={chip.title}
                  onDelete={() => chipDeleteAction(chip)}
                  color="default"
                  clickable={false}
                />
              </React.Fragment>
            );
          })}
        </Slider>
    </Paper>
  );
};

PaperSliderChips.propTypes = {
  chips: PropTypes.instanceOf(Array).isRequired,
  chipDeleteAction: PropTypes.func.isRequired,
};

export default PaperSliderChips;
