/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
// @flow
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { debounce } from 'lodash';
import { Collapse, Box, Button, Grid, Menu, MenuItem, SvgIcon, Container, ButtonGroup } from '@material-ui/core';
import {
  fetchRadiosStart,
  fetchRadiosByTitleStart,
  clearListByTitle,
  setActiveTabRadio,
} from 'actions/radioActions';
import { setCountryActive } from 'actions/countriesActions';
import { setGenreActive } from 'actions/genresActions';
import { setRadioSearchValue } from 'actions/searchActions';
import Header from '../../components/partials/Header';
import AppTabs from '../../components/partials/AppTabs';
import Content from '../../components/partials/Content';
import PaperListRadios from '../../components/papers/PaperListRadios';
import radio from '../../assets/images/playlist.png';
import PaperSliderChips from '../../components/papers/PaperSliderChips';
import DialogRadio from '../../dialogs/DialogRadio';
import PaperFilters from '../../components/papers/PaperFilters';
import Preloader from '../../components/partials/Preloader';
import DialogCountries from '../../dialogs/DialogCountries';
import DialogGenres from '../../dialogs/DialogGenres';

import { ReactComponent as sort } from '../../assets/icons/ic_sort.svg';
import { ReactComponent as sortReverse } from '../../assets/icons/sort_reverse.svg';

const ListRadiosWithPreloader = Preloader(PaperListRadios);

const tabs = [
  {
    label: 'ALL RADIOS',
  },
  {
    label: 'FAVORITE RADIOS',
  },
];

function Radio() {
  const scrollRef = useRef();
  const [dialogCountry, setDialogCountry] = useState(false);
  const [dialogGenre, setDialogGenre] = useState(false);
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [filter, setFilter] = useState('name');
  const [order, setOrder] = useState('asc');

  const radios = useSelector((state) => state.radios.list, shallowEqual);
  const radiosByTitle = useSelector((state) => state.radios.listByTitle, shallowEqual);
  const radioGenders = useSelector((state) => state.genres.list, shallowEqual);
  const radioCountries = useSelector((state) => state.countries.list, shallowEqual);
  const genresFilters = useSelector((state) => state.genres.filters, shallowEqual);
  const countriesFilter = useSelector((state) => state.countries.selected, shallowEqual);
  const isLoading = useSelector((state) => state.radios.isFetching, shallowEqual);
  const isLoadingByTitle = useSelector((state) => state.radios.isFetchingBytitle, shallowEqual);
  const searchValue = useSelector((state) => state.search.radioSearchValue, shallowEqual);
  const favoriteRadios = useSelector((state) => state.radios.favoriteRadios, shallowEqual);
  const activeTab = useSelector((state) => state.radios.activeTab, shallowEqual);
  const searchForm = useSelector((state) => state.header.showRadioSearch, shallowEqual);

  // const [searchForm, setSearchForm] = useState(!!searchValue.length);

  const dispatch = useDispatch();
  const fetchRadios = () => dispatch(fetchRadiosStart());
  const setCountry = (payload) => dispatch(setCountryActive(payload));
  const setGenre = (payload) => dispatch(setGenreActive(payload));
  const fetchRadiosByTitle = (payload) => dispatch(fetchRadiosByTitleStart(payload));
  const setSearchValue = (payload) => dispatch(setRadioSearchValue(payload));
  const clearSearchList = () => dispatch(clearListByTitle());
  const setActiveTab = (payload) => dispatch(setActiveTabRadio(payload));

  const filters = { name: 'ALPHABETICAL', created_at: 'DATE ADDED' };

  const handleSetActiveTab = useCallback((value) => {
    setActiveTab(value);
  }, []);

  useEffect(() => {
    if (!radios && activeTab === 0) fetchRadios();
  }, [fetchRadios]);

  const handleSearchValue = debounce((value) => {
    setSearchValue(value);
    if (!value) clearSearchList();
    if (value && value.length > 1) fetchRadiosByTitle({ searchText: value });
  }, 1000);

  const chips = [...genresFilters.map((g) => ({ title: g, type: 'gerne' }))];
  if (countriesFilter) chips.push({ title: countriesFilter.title, type: 'country' });

  const handleDeleteChip = ({ title, type, id }) => {
    if (type === 'country') setCountry(null);
    else if (type === 'gerne') setGenre(title);
  };

  return (
    <>
      {/*
      todo mastora na perastoun sto component AppBarTop
      <Header
        title="Radio"
        isSearching={searchForm}
        hasSearch
        searchAction={() => {
          setSearchValue('');
          clearSearchList();
          setSearchForm(!searchForm);
        }}
      />
      */}
      <Content
        propRef={scrollRef}
        withAppBarTabs
        withAppBar={
          <>
            {!!favoriteRadios?.length && (
              <Box mt={-2} mb={2} ml={-2} mr={-2}>
                <AppTabs
                  tabs={tabs}
                  activeTab={activeTab}
                  setActiveTab={handleSetActiveTab}
                  scrollRef={scrollRef}
                />
              </Box>
            )}
            {activeTab === 0 && (
              <Box>
                <PaperFilters
                  genreAction={() => setDialogGenre(true)}
                  countryAction={() => setDialogCountry(true)}
                />
              </Box>
            )}
            {activeTab === 0 && (
              <Collapse in={chips.length > 0} addEndListener={() => {}}>
                <Box pt={2}>
                  <PaperSliderChips chips={chips} chipDeleteAction={handleDeleteChip} />
                </Box>
              </Collapse>
            )}
            {activeTab === 1 && (
              <Container maxWidth={false}>
                <Grid container justify="flex-end">
                  <Grid item>
                    <ButtonGroup disableElevation variant="text" color="primary">
                      <Button
                        variant="text"
                        color="primary"
                        onClick={(e) => setAnchorMenu(e.currentTarget)}
                      >
                        {filters[filter]}
                      </Button>
                      <Button
                        color="primary"
                        endIcon={
                          <SvgIcon
                            component={order === 'asc' ? sortReverse : sort}
                            viewBox="0 0 24 24"
                          />
                        }
                        onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
                      />
                    </ButtonGroup>
                  </Grid>
                </Grid>
                <Menu
                  elevation={0}
                  anchorEl={anchorMenu}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  getContentAnchorEl={null}
                  keepMounted
                  open={Boolean(anchorMenu)}
                  onClose={() => {
                    setAnchorMenu(null);
                  }}
                >
                  {Object.keys(filters)
                    .filter((k) => k !== filter)
                    .map((key) => (
                      <MenuItem
                        key={key}
                        onClick={() => {
                          setAnchorMenu(null);
                          setFilter(key);
                        }}
                      >
                        {filters[key]}
                      </MenuItem>
                    ))}
                </Menu>
              </Container>
            )}
          </>
        }
        actionClose={() => clearSearchList()}
        searchForm={searchForm}
        setSearchValue={handleSearchValue}
        searchValue={searchValue}
        withSearch={
          <Box>
            <ListRadiosWithPreloader
              isLoading={isLoadingByTitle}
              radios={radiosByTitle}
              secondaryAction={(e) => console.log('secondary', e)}
            />
          </Box>
        }
      >
        <Box pb={7}>
          {activeTab === 0 ? (
            <ListRadiosWithPreloader
              hasInfiniteScroll
              isLoading={isLoading}
              radios={radios}
              secondaryAction={(e) => console.log('secondary', e)}
            />
          ) : (
            <PaperListRadios
              radios={favoriteRadios.sort((a, b) => {
                if (filter === 'name') {
                  return order === 'asc'
                    ? a.title.localeCompare(b.title)
                    : b.title.localeCompare(a.title);
                }
                if (filter === 'created_at')
                  return order === 'asc'
                    ? a.added_at.seconds - b.added_at.seconds
                    : b.added_at.seconds - a.added_at.seconds;
              })}
              secondaryAction={(e) => console.log('secondary', e)}
            />
          )}
        </Box>
      </Content>
      <DialogCountries
        radioCountries={radioCountries}
        setOpen={setDialogCountry}
        open={dialogCountry}
      />
      <DialogGenres radioGenders={radioGenders} setOpen={setDialogGenre} open={dialogGenre} />
      {/* <DialogRadio open={dialogRadio} setOpen={setDialogRadio} /> */}
    </>
  );
}

export default Radio;
