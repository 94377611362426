export const PlayerActionTypes = {
  SET_PLAYER_PLAYLIST: 'SET_PLAYER_PLAYLIST',
  CLEAR_PLAYER_PLAYLIST: 'CLEAR_PLAYER_PLAYLIST',
  SET_PLAYLIST_IS_REPEAT: 'SET_PLAYLIST_IS_REPEAT',
  SET_PLAYLIST_IS_SHUFFLE: 'SET_PLAYLIST_IS_SHUFFLE',
  SET_PLAYLIST_IS_PLAY: 'SET_PLAYLIST_IS_PLAY',
  SET_PLAYER_RADIO: 'SET_PLAYER_RADIO',
  CLEAR_PLAYER_RADIO: 'CLEAR_PLAYER_RADIO',
  SET_PLAYER_SONG: 'SET_PLAYER_SONG',
  CLEAR_PLAYER_SONG: 'CLEAR_PLAYER_SONG',
  UPDATE_SONG_INDEX: 'UPDATE_SONG_INDEX',
  CLEAR_SHUFFLE_LIST: 'CLEAR_SHUFFLE_LIST',
  CREATE_SHUFFLE_LIST: 'CREATE_SHUFFLE_LIST',
  SET_PLAY_SHUFFLE_LIST: 'SET_PLAY_SHUFFLE_LIST',
  SET_CURRENT_VIDEO: 'SET_CURRENT_VIDEO',
};

export const setCurrentVideo = (payload) => ({
  type: PlayerActionTypes.SET_CURRENT_VIDEO,
  payload
})

export const setPlayShuffleList = (payload) => ({
  type: PlayerActionTypes.SET_PLAY_SHUFFLE_LIST,
  payload
});

export const clearShuffleList = () => ({
  type: PlayerActionTypes.CLEAR_SHUFFLE_LIST,
});

export const createShuffleList = (payload) => ({
  type: PlayerActionTypes.CREATE_SHUFFLE_LIST,
  payload,
});

export const updateSongIndex = () => ({
  type: PlayerActionTypes.UPDATE_SONG_INDEX,
});

export const clearPlayerPlaylist = () => ({
  type: PlayerActionTypes.CLEAR_PLAYER_PLAYLIST,
});

export const setPlayerPlaylist = (payload) => ({
  type: PlayerActionTypes.SET_PLAYER_PLAYLIST,
  payload,
});

export const setPlaylistShuffle = () => ({
  type: PlayerActionTypes.SET_PLAYLIST_IS_SHUFFLE,
});

export const setPlaylistRepeat = () => ({
  type: PlayerActionTypes.SET_PLAYLIST_IS_REPEAT,
});

export const setPlaylistPlay = () => ({
  type: PlayerActionTypes.SET_PLAYLIST_IS_PLAY,
});

export const clearPlayerRadio = () => ({
  type: PlayerActionTypes.CLEAR_PLAYER_RADIO,
});

export const setPlayerRadio = (payload) => ({
  type: PlayerActionTypes.SET_PLAYER_RADIO,
  payload,
});

export const clearPlayerSong = () => ({
  type: PlayerActionTypes.CLEAR_PLAYER_SONG,
});

export const setPlayerSong = (payload) => ({
  type: PlayerActionTypes.SET_PLAYER_SONG,
  payload,
});
