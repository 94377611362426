import React, { useState, useEffect, useRef } from 'react';
import {
  makeStyles,
  Paper,
  Box,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import theme from '../../AppTheme';


const useStyles = makeStyles({
  paper: {
    paddingTop: '56%',
    position: 'relative',
    overflow: 'hidden',
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  boxImage: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.standard,
    }),
  },
});

const PlaylistHeading = ({ image }) => {
  const styles = useStyles();
  const paperRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(1);
  const handleScroll = () => {
    const position = window.pageYOffset;
    const paperWidth = paperRef.current.offsetWidth;
    setScrollPosition(position / paperWidth + 1);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      {/*
      todo mastora move the logic to AppBarTop
      <AppBar color="primary" elevation={0} position="relative">
        <Toolbar className={styles.toolbar}>
          <IconButton onClick={() => backAction()}>
            <SvgIcon component={back} viewBox="0 0 24 24" />
          </IconButton>
          {playlistAction && (
            <IconButton onClick={() => playlistAction()}>
              <SvgIcon component={add} viewBox="0 0 24 24" />
            </IconButton>
          )}
          {favoriteAction && (
            <IconButton
              onClick={() => (isFavorite ? removePlaylist(id) : addPlaylist({ categoryId, id }))}
            >
              {isFavorite ? (
                <SvgIcon component={favorite} viewBox="0 0 24 24" />
              ) : (
                <SvgIcon component={favoriteBorder} viewBox="0 0 24 24" />
              )}
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      */}
      <Paper ref={paperRef} className={styles.paper} square>
        <Box
          className={styles.boxImage}
          style={{ backgroundImage: `url(${image})`, transform: `scale(${scrollPosition})` }}
        />
      </Paper>
    </>
  );
};

PlaylistHeading.propTypes = {
  image: PropTypes.string.isRequired,
};

export default PlaylistHeading;
