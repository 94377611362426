// @flow
import React, { useState } from 'react';
import { AppBar, Toolbar, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { addUserCustomPlaylist } from 'actions/favoritePlaylistsActions';
import DialogDrawerFull from '../components/dialogs/DialogDrawerFull';
import PlaylistAddTo from '../components/partials/PlaylistAddTo';
import AppBarDialog from '../components/partials/AppBarDialog';


const DialogPlaylistNew = ({ open, setOpen, playlistName, selectedSong }) => {
  const [userSongs, setUserSongs] = useState(selectedSong ? [selectedSong] : []);

  const showAddTracks = useSelector((state) => state.header.showAddTracks, shallowEqual);

  const dispatch = useDispatch();
  const addCustomPlaylist = (payload) => dispatch(addUserCustomPlaylist(payload));

  const addUserSong = (song) => {
    setUserSongs([...userSongs, song]);
  };

  return (
    <DialogDrawerFull
      fullScreen={!showAddTracks}
      setOpen={setOpen}
      open={open}
      appBarTop={
        <AppBarDialog
          title={playlistName}
          closeAction={() => {
            if (!showAddTracks) setUserSongs([]);
            setOpen(false);
          }}
        />
      }
      appBarBottom={
        !showAddTracks ? <AppBar elevation={0} color="transparent" position="static">
          <Toolbar>
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              disabled={!userSongs.length}
              onClick={() => {
                addCustomPlaylist({
                  songs: userSongs.map(({ name, youtube_code: code }) => ({
                    name,
                    youtube_code: code,
                  })),
                  count: userSongs.length,
                  title: playlistName,
                  createdByUser: true,
                });
                setOpen(false);
              }}
            >
              Save
            </Button>
          </Toolbar>
        </AppBar> : null
      }
    >
      <PlaylistAddTo userSongs={userSongs} addUserSong={addUserSong} />
    </DialogDrawerFull>
  );
};

DialogPlaylistNew.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  playlistName: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedSong: PropTypes.object,
};

DialogPlaylistNew.defaultProps = {
  selectedSong: null,
};

export default DialogPlaylistNew;
