import React from 'react';
import { Paper, makeStyles, Box, Collapse } from '@material-ui/core';
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import theme from '../../AppTheme';
import WithAppBar from './WithAppBar';
import PaperSearch from '../papers/PaperSearch';

const useStyles = makeStyles({
  paper: {
    paddingTop: theme.spacing(9),
    paddingBottom: theme.spacing(7),
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  paperWithAppBar: {
    paddingTop: theme.spacing(0),
  },
  paperWithAppTabs: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
});

const Content = ({
  propRef,
  withAppBar,
  actionClose,
  withAppBarTabs,
  withSearch,
  searchForm,
  searchValue,
  setSearchValue,
  children,
}) => {
  const styles = useStyles();
  const location = useLocation();

  return (
    <Paper
      ref={propRef}
      elevation={0}
      square
      className={classNames({
        [styles.paper]: true,
        [styles.paperWithAppBar]: Boolean(withAppBar),
        [styles.paperWithAppBarTabs]: withAppBarTabs,
      })}
    >
      {withAppBar && (
        <WithAppBar>
          <Collapse in={!searchForm} unmountOnExit mountOnEnter addEndListener={() => { }}>
            {withAppBar}
          </Collapse>
          <Collapse in={searchForm} unmountOnExit mountOnEnter addEndListener={() => { }}>
            <PaperSearch
              showBackspaceIcon={location.pathname.includes("radio") || location.pathname.includes("library")}
              value={searchValue}
              onValueChange={setSearchValue}
              actionClose={actionClose}
              withAppBarTabs={withAppBarTabs}
            />
          </Collapse>
        </WithAppBar>
      )}
      <Collapse in={!searchForm} unmountOnExit mountOnEnter addEndListener={() => { }}>
        {children}
      </Collapse>
      <Collapse in={searchForm && searchValue} unmountOnExit mountOnEnter addEndListener={() => { }}>
        {withSearch}
      </Collapse>
    </Paper>
  );
};

Content.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.object]).isRequired,
  withAppBar: PropTypes.element,
  withAppBarTabs: PropTypes.bool,
  withSearch: PropTypes.element,
  // eslint-disable-next-line react/forbid-prop-types
  propRef: PropTypes.object,
  searchForm: PropTypes.bool,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  actionClose: PropTypes.func,
};

Content.defaultProps = {
  propRef: null,
  withAppBar: null,
  withAppBarTabs: false,
  withSearch: null,
  searchForm: false,
  searchValue: null,
  setSearchValue: null,
  actionClose: null,
};

export default Content;
