/* eslint-disable consistent-return */
import axios from 'axios';
import { handleErrorResponse } from './ErrorHandling';
import { auth } from '../firebase/firebase.utils';

class TracksService {
  checkIfTrackExists = async ({ track }) => {
    const token = await auth.currentUser.getIdToken();
    try {
      const { data } = await axios({
        method: 'get',
        url: `https://us-central1-firebase-284610.cloudfunctions.net/playguinys/track/${auth.currentUser.uid}/${track}`,
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (err) {
      console.log('Error in fetch playlist service:', err);
      handleErrorResponse(err, 'Something went wrong! Playlist does not exists.');
    }
  };

  fetchTrackLyrics = async ({ track }) => {
    const token = await auth.currentUser.getIdToken();
    try {
      const { data } = await axios({
        method: 'get',
        url: `https://us-central1-firebase-284610.cloudfunctions.net/playguinys/getmusic/${track}`,
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (err) {
      console.log('Error in fetch playlist service:', err);
      handleErrorResponse(err, 'Something went wrong! Playlist does not exists.');
    }
  };


}

const inst = new TracksService();
export default inst;
