import React, { forwardRef } from 'react';
import {
  AppBar,
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  CardActions,
  Typography,
  IconButton,
  SvgIcon,
  Fade,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { ReactComponent as pause } from '../../assets/icons/pause.svg';
import { ReactComponent as play } from '../../assets/icons/play.svg';
import { ReactComponent as close } from '../../assets/icons/ic_close.svg';
import playlistImage from '../../assets/images/playlist.png';
import theme from '../../AppTheme';

const useStyles = makeStyles({
  appBar: {
    bottom: theme.spacing(8),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    top: 'auto',
  },
  card: {
    borderColor: '#374F5F',
    backgroundColor: '#16273C',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  cardContent: {
    borderColor: '#374F5F',
    padding: theme.spacing(1),
    maxHeight: theme.spacing(8),
    flexGrow: 1,
    flexShrink: 1,
    overflow: 'hidden',
  },
  cardMedia: {
    width: theme.spacing(14),
    height: theme.spacing(8),
    flexGrow: 0,
    flexShrink: 0,
  },
  cardActions: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: theme.spacing(14),
  },
  cardActionArea: {
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    overflow: 'hidden',
  },
});

const FloatingPlayer = ({
  open,
  song,
  mainAction,
  playAction,
  pauseAction,
  closeAction,
  isPlaying,
  radio,
  children,
}) => {
  const styles = useStyles();
  const isRadioPlayer = !isEmpty(radio);
  return (
    <Fade in={open}>
      <AppBar elevation={0} color="transparent" position="fixed" className={styles.appBar}>
        <Card variant="outlined" elevation={0} square className={styles.card}>
          <CardActionArea onClick={() => mainAction()} className={styles.cardActionArea}>
            <CardMedia
              className={styles.cardMedia}
              {...(isRadioPlayer ? { image: radio.image640 || playlistImage } : {})}
            >
              {children}
            </CardMedia>
            <CardContent className={styles.cardContent}>
              <Typography variant="body1" noWrap>
                {isRadioPlayer ? radio.title : song.title}
              </Typography>
              <Typography variant="body2" noWrap color="textSecondary">
                {isRadioPlayer ? radio.frequency : song.author}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions disableSpacing className={styles.cardActions}>
            <IconButton
              onClick={() => {
                if (isPlaying) pauseAction();
                else playAction();
              }}
            >
              <SvgIcon component={isPlaying ? pause : play} viewBox="0 0 24 24" />
            </IconButton>
            <IconButton onClick={() => closeAction()}>
              <SvgIcon component={close} viewBox="0 0 24 24" />
            </IconButton>
          </CardActions>
        </Card>
      </AppBar>
    </Fade>
  );
};

FloatingPlayer.propTypes = {
  open: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  song: PropTypes.object,
  isPlaying: PropTypes.bool,
  mainAction: PropTypes.func.isRequired,
  playAction: PropTypes.func.isRequired,
  pauseAction: PropTypes.func.isRequired,
  closeAction: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  radio: PropTypes.object,
};

FloatingPlayer.defaultProps = {
  song: null,
  isPlaying: false,
  radio: {},
};

export default FloatingPlayer;
