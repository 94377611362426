/* eslint-disable consistent-return */
import axios from 'axios';
import { handleErrorResponse } from './ErrorHandling';
import { auth } from '../firebase/firebase.utils';

class CountryService {
  fetchCountries = async ({ country }) => {
    const token = await auth.currentUser.getIdToken();
    try {
      const { data } = await axios({
        method: 'get',
        url: `https://us-central1-firebase-284610.cloudfunctions.net/playguinys/countries/${country}`,
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (err) {
      console.log('Error in fetch playlist service:', err);
      handleErrorResponse(err, 'Something went wrong! Playlist does not exists.');
    }
  };
}

const inst = new CountryService();
export default inst;
