/* eslint-disable consistent-return */
/* eslint-disable no-use-before-define */
/* eslint-disable no-return-await */
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import playlist from '../assets/images/playlist.png';

const config = {
  apiKey: 'AIzaSyD2jGwFSQxqf2lyvOGnkndcdX5lkvNnoGA',
  authDomain: 'app.playguin.com',
  databaseURL: 'https://fir-284610.firebaseio.com',
  projectId: 'firebase-284610',
  storageBucket: 'plaguin_playlist_logo',
  messagingSenderId: '684023299860',
  appId: '1:684023299860:web:bccb24a2e4d594e48d0c01',
  measurementId: 'G-PX9LZ5DRR7',
};

firebase.initializeApp(config);

export const createUserProfileDocument = async (userAuth, additionalData) => {
  if (!userAuth) return;

  const userRef = firestore.doc(`users/${userAuth.uid}`);

  const snapShot = await userRef.get();

  if (!snapShot.exists) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();
    try {
      await userRef.set({
        displayName,
        email,
        createdAt,
        ...additionalData,
      });
    } catch (error) {
      console.log('error creating user', error.message);
    }
  }
  return userRef;
};

const getReference = async (documentReference) => {
  const res = await documentReference.get();
  const data = res.data();

  if (data && documentReference.id) {
    data.uid = documentReference.id;
  }

  return data;
};

const getRadioReference = async (documentReference) => {
  const res = await documentReference.get();
  const data = res.data();

  if (data && documentReference.id) {
    data.uid = documentReference.id;
  }

  const { image } = data;
  const image48 = (await getImageUrl('gs://plaguin_radio_logo', image, '48')) || '';
  const image112 = (await getImageUrl('gs://plaguin_radio_logo', image, '112', '64')) || '';
  const image640 = (await getImageUrl('gs://plaguin_radio_logo', image, '640')) || '';

  return {
    id: data.uid,
    stream_link: data.stream_link,
    frequency: data.frequency,
    title: data.title,
    country: data.country,
    gender: data.gender,
    createdAt: data.created_at,
    image48,
    image112,
    image640,
  };
};

export const getImageUrl = async (bucket, name, dim1, dim2) => {
  try {
    if (!name) return '';
    const n = name.split('.');
    const imgUrl = await storage
      .refFromURL(bucket)
      .child(dim2 ? `${n[0]}_${dim1}x${dim2}.${n[1]}` : `${n[0]}_${dim1}x${dim1}.${n[1] || 'jpg'}`)
      .getDownloadURL();
    return imgUrl;
  } catch (error) {
    console.log(error);
  }
};

export const getUserCategoryPlaylists = async (playlistItems) => {
  try {
    const itemRefs = playlistItems.map((item) => {
      return firestore.collection(`categories/${item.categoryId}/playlists`).doc(item.id).get();
    });
    const items = await Promise.all(itemRefs);
    return await Promise.all(
      items
        .filter((doc) => doc.exists)
        .map(async (doc) => {
          const obj = doc.data();
          const { image } = obj;
          const image256 = (await getImageUrl('gs://plaguin_playlist_logo', image, '256')) || '';
          const image280 = (await getImageUrl('gs://plaguin_playlist_logo', image, '280')) || '';
          const image640 = (await getImageUrl('gs://plaguin_playlist_logo', image, '640')) || '';
          return {
            id: doc.id,
            image256,
            image280,
            image640,
            songs: obj.songs,
            translation: obj.translation,
            type: obj.type,
            gender: obj.gender,
            youtube_playlist_code: obj.youtube_playlist_code,
            count: obj.count,
            created_at: obj.created_at,
          };
        }),
    );
  } catch (error) {
    console.log(error);
  }
};

export const convertTracksSnapshotToMap = (collections) => {
  const transformedCollection = collections.docs.map((doc) => {
    const obj = doc.data();
    return {
      id: doc.id,
      name: obj.name,
      youtube_code: obj.youtube_code,
    };
  });
  return transformedCollection;
};

export const convertCollectionsSnapshotToMap = (collections) => {
  const transformedCollection = collections.docs.map((doc) => {
    const obj = doc.data();
    return {
      id: doc.id,
      ...obj,
    };
  });
  return transformedCollection;
};

export const convertCountriesGernesSnapshotToMap = (collections) => {
  const transformedCollection = collections.docs.map((doc) => {
    const obj = doc.data();
    return {
      id: doc.id,
      ...obj,
      ...(!obj.code ? { selected: false } : {}),
    };
  });
  return transformedCollection;
};

export const convertRadioSnapshotWithRef = async (item) => {
  // console.log(item);
  const obj = item.data();
  const { image } = obj;
  const image48 = (await getImageUrl('gs://plaguin_radio_logo', image, '48')) || '';
  const image112 = (await getImageUrl('gs://plaguin_radio_logo', image, '112', '64')) || '';
  const image640 = (await getImageUrl('gs://plaguin_radio_logo', image, '640')) || '';
  const related = await Promise.all(obj.related.map(async (r) => await getRadioReference(r)));
  return {
    id: item.id,
    stream_link: obj.stream_link,
    frequency: obj.frequency,
    title: obj.title,
    country: obj.country,
    gender: obj.gender,
    createdAt: obj.created_at,
    related,
    image48,
    image112,
    image640,
  };
};

export const convertRadiosSnapshotWithRefToMap = async (collections) => {
  const transformedCollection = await Promise.all(
    collections.docs.map(async (doc) => {
      const obj = doc.data();
      const { image } = obj;
      const image48 = (await getImageUrl('gs://plaguin_radio_logo', image, '48')) || '';
      const image112 = (await getImageUrl('gs://plaguin_radio_logo', image, '112', '64')) || '';
      const image640 = (await getImageUrl('gs://plaguin_radio_logo', image, '640')) || '';
      // const related = await Promise.all(obj.related.map(async (r) => await getRadioReference(r)));
      return {
        id: doc.id,
        stream_link: obj.stream_link,
        frequency: obj.frequency,
        title: obj.title,
        country: obj.country,
        gender: obj.gender,
        createdAt: obj.created_at,
        // related,
        image48,
        image112,
        image640,
      };
    }),
  );
  return transformedCollection;
};

const getPlaylistBycategory = async (id) => {
  try {
    const subCollectionDocs = await firestore
      .collection('categories')
      .doc(id)
      .collection('playlists')
      .where('active', '==', 1)
      .orderBy('order', 'asc')
      .limit(5)
      .get();
    return await Promise.all(
      subCollectionDocs.docs.map(async (doc) => {
        const obj = doc.data();
        const { image } = obj;
        const image256 = (await getImageUrl('gs://plaguin_playlist_logo', image, '256')) || '';
        const image280 = (await getImageUrl('gs://plaguin_playlist_logo', image, '280')) || '';
        const image640 = (await getImageUrl('gs://plaguin_playlist_logo', image, '640')) || '';
        return {
          id: doc.id,
          image256,
          image280,
          image640,
          songs: obj.songs,
          gender: obj.gender,
          translation: obj.translation,
          type: obj.type,
          youtube_playlist_code: obj.youtube_playlist_code,
          count: obj.count,
        };
      }),
    );
  } catch (error) {
    console.log(error);
  }
};

export const convertCategoriesSnapshotWithRefToMap = async (categories) => {
  const transformedCollection = await Promise.all(
    categories.docs.map(async (doc) => {
      const obj = doc.data();
      const playlists = await getPlaylistBycategory(doc.id);
      return {
        id: doc.id,
        playlists,
        translation: obj.translation,
      };
    }),
  );
  return transformedCollection;
};

export const getFavoriteRadioItems = async (itemIds) => {
  try {
    const itemRefs = itemIds.map((item) => {
      return firestore.collection('radios').doc(item.id).get();
    });
    const docs = await Promise.all(itemRefs);
    const items = await Promise.all(
      docs
        .filter((doc) => doc.exists)
        .map(async (doc) => {
          const obj = doc.data();
          const { image } = obj;
          const image48 = (await getImageUrl('gs://plaguin_radio_logo', image, '48')) || '';
          const image112 = (await getImageUrl('gs://plaguin_radio_logo', image, '112', '64')) || '';
          const image640 = (await getImageUrl('gs://plaguin_radio_logo', image, '640')) || '';
          // const related = await Promise.all(obj.related.map(async (r) => await getRadioReference(r)));
          return {
            id: doc.id,
            stream_link: obj.stream_link,
            frequency: obj.frequency,
            title: obj.title,
            country: obj.country,
            gender: obj.gender,
            added_at: itemIds.find(({ id }) => id === doc.id)?.added_at,
            // related,
            createdAt: obj.created_at,
            image48,
            image112,
            image640,
          };
        }),
    );
    return items;
  } catch (error) {
    console.log(error);
  }
  return [];
};

export const convertCategorySnapshotWithRefToMap = async (playlists) => {
  try {
    return await Promise.all(
      playlists.docs.map(async (doc) => {
        const obj = doc.data();
        const { image } = obj;
        const image256 = (await getImageUrl('gs://plaguin_playlist_logo', image, '256')) || '';
        const image280 = (await getImageUrl('gs://plaguin_playlist_logo', image, '280')) || '';
        const image640 = (await getImageUrl('gs://plaguin_playlist_logo', image, '640')) || '';
        return {
          id: doc.id,
          image256,
          image280,
          image640,
          songs: obj.songs,
          translation: obj.translation,
          type: obj.type,
          gender: obj.gender,
          youtube_playlist_code: obj.youtube_playlist_code,
          count: obj.count,
        };
      }),
    );
  } catch (error) {
    console.log(error);
  }
};

export const addCollectionAndDocuments = async (collectionKey, objectsToAdd) => {
  const collectionRef = firestore.collection(collectionKey);

  const batch = firestore.batch();
  objectsToAdd.forEach((obj) => {
    const newDocRef = collectionRef.doc();
    batch.set(newDocRef, obj);
  });

  return await batch.commit();
};

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((userAuth) => {
      unsubscribe();
      resolve(userAuth);
    }, reject);
  });
};

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();

export const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: 'select_account' });
export const signInWithGoogle = () => auth.signInWithPopup(googleProvider);

export default firebase;
