import { FavoritePlaylistsActionTypes } from 'actions/favoritePlaylistsActions';
import { UserActionTypes } from 'actions/userActions';

const INITIAL_STATE = {
  list: [],
  playguinList: [],
  filter: 'title',
  order: 'asc',
  isFetching: false,
  itemToBeAdded: null,
  errorMessage: undefined,
  lastVisible: null,
  limit: 10,
  hasMore: false,
  activeTab: 0,
};

const favoritePlaylistsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FavoritePlaylistsActionTypes.FETCH_FAVORITE_PLAYLISTS_START:
      return {
        ...state,
        isFetching: !state.lastVisible,
      };
    case FavoritePlaylistsActionTypes.FETCH_FAVORITE_PLAYLISTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        hasMore: action.payload.length === state.limit,
        list: [...state.list, ...action.payload],
      };
    case FavoritePlaylistsActionTypes.FETCH_FAVORITE_PLAYGUIN_PLAYLISTS_SUCCESS:
      return {
        ...state,
        playguinList: [...state.playguinList, ...action.payload],
      };
    case FavoritePlaylistsActionTypes.FETCH_FAVORITE_PLAYLISTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case FavoritePlaylistsActionTypes.ADD_FAVORITE_PLAYLIST_START:
      return {
        ...state,
        isFetching: !action.payload.existed,
        itemToBeAdded: action.payload.youtube_playlist_code
      };
    case FavoritePlaylistsActionTypes.ADD_FAVORITE_PLAYLIST:
    case FavoritePlaylistsActionTypes.ADD_USER_CUSTOM_PLAYLIST_SUCCESS:
      return {
        ...state,
        list: [...state.list, action.payload],
        isFetching: false,
        itemToBeAdded: null,
      };
    case UserActionTypes.REMOVE_FAVORITE_PLAYLIST_FROM_CATEGORY:
      return {
        ...state,
        playguinList: state.playguinList.filter((t) => t.id !== action.payload),
      };
    case FavoritePlaylistsActionTypes.REMOVE_FAVORITE_PLAYLIST:
      return {
        ...state,
        list: state.list.filter((t) => t.id !== action.payload),
      };
    case FavoritePlaylistsActionTypes.ADD_TRACK_TO_USER_PLAYLIST:
    case FavoritePlaylistsActionTypes.REMOVE_TRACK_TO_USER_PLAYLIST:
      return {
        ...state,
        list: state.list.map((existingPlaylist) =>
          existingPlaylist.id === action.payload.id ? { ...existingPlaylist, ...action.payload } : existingPlaylist,
        ),
      };
    case FavoritePlaylistsActionTypes.DELETE_USER_PLAYLIST:
      return {
        ...state,
        list: state.list.filter(({ id }) => id !== action.payload),
      };
    case FavoritePlaylistsActionTypes.SET_USER_PLAYLIST_LAST_VISIBLE_ITEM:
      return {
        ...state,
        lastVisible: action.payload,
      };
    case FavoritePlaylistsActionTypes.SET_ACTIVE_TAB_LIBRARY:
      return {
        ...state,
        activeTab: action.payload,
      };
    case FavoritePlaylistsActionTypes.SET_ORDER_PLAYLIST:
      return {
        ...state,
        order: action.payload,
        list: [],
        lastVisible: null,
        hasMore: false,
      };
    case FavoritePlaylistsActionTypes.SET_FILTER_PLAYLIST:
      return {
        ...state,
        filter: action.payload,
        list: [],
        lastVisible: null,
        hasMore: false,
      };
    case FavoritePlaylistsActionTypes.RENAME_USER_PLAYLIST_SUCCESS:
      return {
        ...state,
        list: state.list.map((existingPlaylist) =>
          existingPlaylist.id === action.payload.id
            ? { ...existingPlaylist, title: action.payload.name }
            : existingPlaylist,
        ),
      };
    case FavoritePlaylistsActionTypes.RENAME_USER_PLAYLIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case FavoritePlaylistsActionTypes.CLEAR_PLAYLIST_LIST:
      return {
        ...state,
        list: [],
        playguinList: [],
        itemToBeAdded: null,
        lastVisible: null,
        hasMore: false,
      };
    default:
      return state;
  }
};

export default favoritePlaylistsReducer;
