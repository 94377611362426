/* eslint-disable no-debugger */
import { all, call, takeLatest, select, put } from 'redux-saga/effects';

import { UserActionTypes } from 'actions/userActions';
import { fetchFavoritePlayguinPlaylistsSuccess } from 'actions/favoritePlaylistsActions';
import { firestore, getUserCategoryPlaylists } from 'firebase/firebase.utils';

export function* updateFavoritePlaylistsInFirebase({ payload }) {
  const user = yield select((state) => state.user);
  const userPlaylistsInRedux = yield select((state) => state.userPlaylists.playguinList);
  if (user) {
    try {
      const userRef = firestore.doc(`users/${user.currentUser.id}`);
      yield userRef.update({ playlists: user.playlists });
      if (user.playlists.length) {
        try {
          if (typeof payload === 'object') {
            const diff = user.playlists.filter(
              ({ id: id1 }) => !userPlaylistsInRedux.some(({ id: id2 }) => id2 === id1),
            );
            const results = yield call(getUserCategoryPlaylists, diff);
            yield put(fetchFavoritePlayguinPlaylistsSuccess(results));
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
}

export function* onFavoritePlaylistChange() {
  yield takeLatest(
    [
      UserActionTypes.ADD_FAVORITE_PLAYLIST_FROM_CATEGORY,
      UserActionTypes.REMOVE_FAVORITE_PLAYLIST_FROM_CATEGORY,
    ],
    updateFavoritePlaylistsInFirebase,
  );
}

export function* favoriteCategoryPlaylistSagas() {
  yield all([call(onFavoritePlaylistChange)]);
}
