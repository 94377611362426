import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';

import Routes from 'routes';
import Navigation from 'routes/Navigation';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { checkUserSession } from 'actions/userActions';
import PlayguinPlayer from 'components/player/PlayguinPlayer';
import WithSpinner from 'components/with-spinner/with-spinner';
import withClearCache from './ClearCache';
import AppBarTop from './routes/AppBarTop';

const MainApp = () => {
  const currentUser = useSelector((state) => state.user.currentUser, shallowEqual);
  const isPlay = useSelector((state) => state.player.play, shallowEqual);
  const isFetchingUser = useSelector((state) => state.user.isFetching, shallowEqual);

  const dispatch = useDispatch();
  const checkUserSessionFirebase = () => dispatch(checkUserSession());

  useEffect(() => {
    if (!currentUser) checkUserSessionFirebase();
  }, [checkUserSessionFirebase]);

  const AppContainer = WithSpinner(() => (
    <Box pb={7}>
      {currentUser && <AppBarTop />}
      <Routes />
      {currentUser && isPlay && <PlayguinPlayer />}
      {currentUser && <Navigation />}
    </Box>
  ));
  return <AppContainer isLoading={isFetchingUser} />;
};

const ClearCacheComponent = withClearCache(MainApp);

// eslint-disable-next-line react/prop-types
function App() {
  return <ClearCacheComponent />;
}

export default App;
