import React from 'react';
import {
  Paper,
  Box,
  makeStyles,
  Typography, Fab, SvgIcon, Container,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import classNames from 'classnames';
import waveAnimationData from 'assets/animations/wave.json';
import { ReactComponent as play } from '../../assets/icons/play.svg';
import { ReactComponent as pause } from '../../assets/icons/ic_track_pause.svg';
import theme from '../../AppTheme';

const useStyles = makeStyles({
  paper: {
    position: 'relative',
    backgroundColor: 'transparent',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(3),
    zIndex: 1,
  },
  fab: {
    position: 'absolute',
    left: '50%',
    top: 0,
    transform: 'translate(-50%,-50%)',
  },
});

const PaperRadioControl = ({ isPlaying, radio, pauseAction, playAction  }) => {
  const styles = useStyles();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: waveAnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <Paper elevation={0} square className={classNames({ [styles.paper]: true })}>
      <Fab
        className={styles.fab}
        color="primary"
        onClick={() => {
          if (isPlaying) {
            pauseAction();
          } else {
            playAction();
          }
        }}
      >
        <SvgIcon component={isPlaying ? pause : play} viewBox="0 0 24 24" />
      </Fab>
      <Container>
        <Typography style={{ fontWeight: 'bold' }} align="center" variant="h4">
          {`${radio.title} ${radio.frequency !== 'e-radio' ? radio.frequency : ''}`}
        </Typography>
        <Typography  align="center" variant="body2" color="textSecondary">
          {radio.gender.join(', ')}
        </Typography>
        <Box p={2} position="relative">
          {isPlaying && <Lottie options={defaultOptions} width={theme.spacing(28)} height={theme.spacing(7)} />}
        </Box>
      </Container>
    </Paper>
  );
};

PaperRadioControl.propTypes = {
  isPlaying: PropTypes.bool.isRequired,
  playAction: PropTypes.func.isRequired,
  pauseAction: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  radio: PropTypes.object.isRequired,
};

export default PaperRadioControl;
